import Box from '@mui/material/Box';
import { Divider, Hidden, Typography } from '@mui/material';
import BgRedButton from '../../../shared-components/BgRedButton';

const data = [
  {
    srcTitle: 'highlights-1',
    description: `Sdn Bhd Company has the right to carry out its perpetual succession until the company is dissolved by its shareholders, directors, or company secretary.`,
  },
  {
    srcTitle: 'highlights-2',
    description: `All of these parties have the right to sue any other person or company. Similarly, any other person or company may sue a Sdn Bhd company.`,
  },
  {
    srcTitle: 'highlights-3',
    description: `When you open business in Malaysia, remember Sdn Bhd is limited by shares. As a result, if a Sdn Bhd company declares a total loss, the shareholders and company secretary are not liable for the company shares.`,
  },
  {
    srcTitle: 'highlights-4',
    description: `Sdn Bhd Company is independent in signing agreements with other businesses and conduct legitimate business operations.`,
  },
];

const formData = [
  `Here is a step-by-step guideline to <i>open Sdn Bhd company in Malaysia</i>.`,
  `Step 1: Visit the online portal of MyCoID and register an account.`,
  `Step 2: After you activate your account, you'll receive an email with important information for validating your account at the nearest SSM counter.`,
  `Step 3: After SSM validates your account, go to the MyCoID portal and begin registering your Sdn Bhd company.`,
  `Step 4: Proceed to the Direct Incorporation Application to complete the Name Search procedure. To begin this procedure, you must provide the three proposed company names.`,
  `Step 5: A Super Form will then appear on your computer screen. You can use this form to clarify the significance of the suggested company name.`,
  `If the proposed name contains controlled words, state names, or trademarks, you must complete this form. In this case, you must also submit an authorisation letter from the relevant authority or owner.`,
  `Step 6: Now, you must select a business code. This is a code that contains both your company's business address and its registered address.`,
  `Step 7: The system will list you as a shareholder or director of the company up to this point. If you prefer, you can enter these details manually in the next step.`,
  `Step 8: Apply after thoroughly reviewing the information you have provided. You will be directed to the 'Transaction page' from here.`,
  `Step 9: To complete the registration process, you must pay a fee of RM1,000. Remember to keep the transaction receipt.`,
  `Step 10: Once completed, the 'Notice of Registration' will be emailed to you. It would serve as registration proof. In addition, you can obtain a Company Certificate by paying the required fee at the SSM counter.`,
  `In order to register a Sdn Bhd company in Malaysia, you must appoint a company secretary. A company secretary is in charge of a variety of important tasks. Please keep in mind that you must appoint a company secretary within 30 days after registering a company with SSM. Although it is not required to file a constitution for your Sdn Bhd company, you can do so via the MyCoID portal.`,
  `We hope this article gave you enough insights on how to open business in Malaysia. CKP also offers company secretarial services. Should you need assistance, please contact us.`,
];

function BlogContent() {
  const renderImageData = (props, index) => {
    return (
      <img
        key={index}
        className="w-[12rem] md:w-[24rem] mt-10 md:mt-0 mx-auto"
        src={`assets/images/pages/blog/${props.srcTitle}.png`}
        alt={props.srcTitle}
      />
    );
  };

  const renderDescriptionData = (props, index) => {
    return (
      <ul
        key={index}
        className="!text-[8px] md:!text-[14px] !font-400 !leading-[10px] md:!leading-[20px]"
        style={{ listStyleType: 'disc', paddingLeft: '20px' }}
      >
        <li>{props.description}</li>
      </ul>
    );
  };
  return (
    <>
      <Box className="grid grid-cols-12 py-16 md:py-32 w-full bg-[#F4064F]">
        <div className="col-start-2 col-span-6">
          <div className="flex flex-col">
            <Typography className="!font-customBlack text-white !text-[25px] md:!text-[50px] lg:!text-[100px] !font-900 !tracking-tight !leading-[24px] md:!leading-[45px] lg:!leading-[90px]">
              How To Open
            </Typography>
            <Typography className="!font-customBlack text-white !text-[25px] md:!text-[50px] lg:!text-[100px] !font-[900] !tracking-tight !leading-[24px] md:!leading-[45px] lg:!leading-[90px]">
              Sdn Bhd
            </Typography>
            <Typography className="!font-customBlack text-white !text-[25px] md:!text-[50px] lg:!text-[100px] !font-[900] !tracking-tight !leading-[24px] md:!leading-[45px] lg:!leading-[90px]">
              Company
            </Typography>
          </div>
        </div>
        <div className="col-start-7 col-span-6 col-end-12">
          <div className="flex flex-col items-end justify-end">
            <div className="flex flex-col mt-72 space-y-6 md:space-y-12">
              <img
                className="w-28 md:w-60 self-center"
                src="assets/images/pages/blog/plus.png"
                alt="logo"
              />
              <Typography
                color="primary"
                className="!font-customBlack whitespace-nowrap !text-[25px] md:!text-[50px] lg:!text-[100px] !font-[900] !tracking-tight !leading-[24px] md:!leading-[45px] lg:!leading-[90px]"
              >
                in Malaysia?
              </Typography>
            </div>
          </div>
        </div>
      </Box>

      <Box className="grid grid-cols-12 mt-[2rem] md:mt-[7rem] py-[3rem] md:py-[6rem]">
        <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8">
          <div className="flex flex-col md:flex-row items-center justify-center space-x-30 md:space-x-60">
            <div className="flex flex-col md:-mt-[12rem]">
              <Typography
                color="primary"
                className="!font-customBlack !text-[30px] md:!text-[68px] !font-900 !leading-none"
              >
                What Is a
              </Typography>
              <Typography className="!font-customBlack text-[#F5054F] !text-[30px] md:!text-[60px] !font-900 !mt-5 md:!mt-10 !leading-none ">
                Sdn Bhd
              </Typography>
              <Typography className="!font-customBlack text-[#F5054F] !text-[30px] md:!text-[60px] !font-900 !leading-none ">
                Company?
              </Typography>
            </div>
            <img
              className="w-[12rem] md:w-[24rem] mt-10 md:mt-0"
              src="assets/images/pages/blog/company.png"
              alt="company"
            />
          </div>
          <div className="flex flex-row items-center justify-center md:-mt-80">
            <Typography
              color="primary"
              className="!text-[12px] md:!text-[25px] !font-400 !leading-[14px] md:!leading-[28px]"
            >
              In Malaysia, to <b>open Sdn Bhd company in Malaysia </b>
              is similar to registering any other business entity. However, there may be some
              differences in the registration requirements. This article will teach you. So, without
              further ado, let's get into the meat of this article. “Sdn Bhd” is an abbreviation for
              Sendirian Berhad, a Malaysian term for a Private Limited company. This type of company
              is very popular among Malaysian business people. Under the Companies Act 2016, a Sdn
              Bhd Company cannot be registered unless appointed by one director and one shareholder.
              As a result, you can easily register a Sdn Bhd company in Malaysia without the
              assistance of a business partner.
            </Typography>
          </div>

          <Box className="flex flex-col mt-[2rem] md:mt-[7rem] p-10 md:p-40 text-white bg-[#F4064F] rounded-[30px]">
            <Typography className="!text-[15px] md:!text-[30px] !font-700 !leading-[15px] md:!leading-[25px] uppercase">
              Sdn Bhd Company Highlights
            </Typography>
            <div className="grid grid-cols-2 md:grid-cols-4 mt-16 md:mt-32 gap-x-20 md:gap-x-40 gap-y-10 md:gap-y-20">
              <Hidden mdDown>
                {data.map((item, index) => {
                  return renderImageData(item, index);
                })}
                {data.map((item, index) => {
                  return renderDescriptionData(item, index);
                })}
              </Hidden>

              <Hidden mdUp>
                {Array.from({ length: 2 }, (_, index) => renderImageData(data[index], index))}
                {Array.from({ length: 2 }, (_, index) => renderDescriptionData(data[index], index))}
                {Array.from({ length: 2 }, (_, index) => renderImageData(data[index + 2], index))}
                {Array.from({ length: 2 }, (_, index) =>
                  renderDescriptionData(data[index + 2], index)
                )}
              </Hidden>
            </div>
          </Box>

          <div className="flex flex-col mt-48 md:mt-96">
            <Typography
              color="primary"
              className="!text-[15px] md:!text-[30px] !font-700 !leading-[18px] md:!leading-[35px] uppercase"
            >
              How to Form a Malaysian Sdn Bhd Company
            </Typography>
            <Divider className="!mt-4 !mb-16 md:!mb-32 py-1 !rounded-[20px] !bg-[#F5054F] w-7/12" />
            {formData.map((item, index) => {
              return (
                <ul
                  key={index}
                  className="!text-[10px] md:!text-[18px] !font-400 !leading-[12px] md:!leading-[25px] text-[#1C2687]"
                  style={{ listStyleType: 'disc', paddingLeft: '20px' }}
                >
                  <li dangerouslySetInnerHTML={{ __html: item }} />
                </ul>
              );
            })}
          </div>
          <div className="flex flex-row justify-end mt-36 md:mt-72">
            <BgRedButton destination="/contact-us" title="Contact us" />
          </div>
        </div>
      </Box>
    </>
  );
}

export default BlogContent;
