import { Box, Typography } from '@mui/material';
import PricingCard from './PricingCard';
import pricingData from '../dataStore';

function FullAccountServicePackage() {
  return (
    <div className="grid grid-cols-12 py-32 md:py-96 items-center " style={{ color: '#1C2687' }}>
      <Box className="col-start-2 md:col-start-2 col-span-10 md:col-span-4 mb-24 md:mb-0 mx-auto">
        <img
          className="rounded-full w-[12rem] md:w-full"
          src="assets/images/pages/pricing/calculator.png"
          alt="calculator"
        />
      </Box>
      <div className="col-start-2 md:col-start-6 col-span-10 md:col-span-6 ml-0 md:ml-24">
        <div className="flex flex-col">
          <Typography className="!font-customBlack !text-[23px] md:!text-[50px] !font-900 !leading-[24px] md:!leading-[48px]">
            Full Accounts Service Packages
          </Typography>
          <Typography className="!font-customBlack !text-[16px] md:!text-[35px] !font-900 !mt-2 md:!mt-4 !leading-[20px] md:!leading-[40px] text-[#F5054F]">
            Strategies for growth
          </Typography>
          <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            You're an SME in Malaysia that is bogged down by paperwork, keeping track of invoices,
            and payments. Manually entering in figures and sifting through a mountain of paperwork
            when something doesn't add up. The company is ready to grow, but it's getting difficult
            and expensive to even keep up with the volume you have now.
          </Typography>
          <div className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            <h1 className="inline">
              With these packages from CKP, specializing in <i>outsource accounting in Malaysia</i>,
              you get a subscription with Xero
            </h1>
            . We'll help you set everything up and give you training on how to get the most out of
            the software. You'll also have a personal accountant overseeing the whole process.
            They'll read the numbers and use them to develop strategies that can simplify your
            business processes or create a foundation for exponential growth.
          </div>
        </div>
      </div>

      <div className="col-start-3 md:col-start-2 col-span-8 md:col-span-10 mt-[6rem] md:mt-[15rem]">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 md:gap-20">
          {pricingData?.full_account_package?.map((item, index) => {
            return <PricingCard key={index} props={item} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default FullAccountServicePackage;
