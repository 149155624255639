import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import postContactUs from '../../services/email';
import ContactUs from './components/ContactUs';
import QuickLinks from './components/QuickLinks';

function Footer(props) {
  const [email, setEmail] = useState('');

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const onSubmit = async () => {
    try {
      await postContactUs({ email }, 'Footer Mailbox');
      setEmail('');
      return enqueueSnackbar('Email Sent', { variant: 'success' });
    } catch (err) {
      console.error(err);
      return enqueueSnackbar('Something Went Wrong', { variant: 'error' });
    }
  };

  return (
    <div id="footer" className="flex flex-col md:flex-row pt-92 text-white">
      <Hidden />
      <div className="flex flex-col bg-[#F4064F] pb-20 -mt-40 pt-[3rem] md:pt-[10rem] rounded-tr-3xl pl-32 xl:pl-92 pr-20 lg:pr-[5rem] md:w-4/12">
        <div className="flex flex-col w-full xl:!w-8/12">
          <div className="flex flex-row items-center">
            <img className="mb-10 md:mb-0 -mr-48" src="assets/images/logo/layer-1.png" alt="logo" />
            <div>
              <Typography className="!font-customBlack !font-900 !text-[16px] md:!text-[22px] !leading-[14px] md:!leading-[25px]">
                Chia, Ka & Partners PLT (CKP)
              </Typography>
            </div>
          </div>
          <Typography className="!font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px] !mt-20 md:!mt-40 ">
            Chia, Ka & Partners PLT (CKP) is a specialised accounting firm in Kuala Lumpur. We
            specialise in meeting the basic to complex accounting and accounting-related
            requirements of businesses. Our scope of services ranges from Xero cloud accounting,
            bookkeeping, payment administration, contract staff services, and payroll support.
          </Typography>
        </div>
        <div className="flex flex-row mt-14 md:mt-28 items-center">
          <IconButton
            href="https://www.facebook.com/ckpartnersplt/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookOutlinedIcon className="text-white !text-xl md:!text-4xl " />
          </IconButton>
          <IconButton
            href=" https://www.linkedin.com/company/ckpartnersmy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-16 md:w-28" src="assets/images/logo/linkedIn-icon.png" alt="logo" />
          </IconButton>
          <IconButton href="https://wa.me/60129853980" target="_blank" rel="noopener noreferrer">
            <WhatsAppIcon className="text-white !text-xl md:!text-4xl " />
          </IconButton>
          <IconButton
            href="https://www.instagram.com/ckp.buildbold/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon className="text-white !text-xl md:!text-4xl " />
          </IconButton>
        </div>
      </div>
      <div className="flex flex-row bg-[#2C3584] w-full md:w-8/12 py-[4rem] md:py-[8rem]">
        <div className="grid grid-cols-12">
          <div className="col-start-2 col-span-4">
            <QuickLinks />
          </div>
          <div className="col-start-6 col-span-4">
            <ContactUs />
          </div>
          {/* Mobile Version */}
          <Hidden mdUp>
            <div className="col-start-2 col-span-9 sm:col-span-5 sm:col-start-6">
              <div className="flex flex-row items-end !mt-14 md:mt-28">
                <TextField
                  fullWidth
                  className="bg-white"
                  id="email"
                  placeholder="Enter your email"
                  size="small"
                  value={email}
                  onChange={handleChange}
                />
                <Box className="bg-[#F4064F] py-6 px-8" onClick={onSubmit}>
                  <img
                    className="hover:cursor-pointer"
                    src="assets/images/logo/send-icon.png"
                    alt="logo"
                  />
                </Box>
              </div>
            </div>
          </Hidden>
          {/* Mobile Version */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
