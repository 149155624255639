import { Box, Typography } from '@mui/material';

const CardData = [
  {
    srcTitle: 'cost-saving-solution',
    title: 'Cost-Saving Solution',
    description: `When you engage our accounting services Kuala Lumpur, you save money not only on salary but also on overhead, management, hiring, and training. The recruitment fee is the first hidden cost of hiring a full-time employee, followed by absences, CPF and other employee incentives, office space, and equipment costs. In addition, you can put your trust in Chia, Ka & Partners for expert financial advice. Are you keen on engaging our services? Then without further ado, contact us today to know more about our services.`,
  },
  {
    srcTitle: 'accuracy-and-efficiency',
    title: 'Accuracy and Efficiency',
    description: `You likely already have an accounts clerk who keeps track of daily sales, collections, costs, and expenses in an Excel spreadsheet. However, even the most meticulous, efficient, and professional bookkeeper will make errors, with or without the assistance of accounting software. In addition, the requirements and level of competency required in a bookkeeper will vary as the complexity of the bookkeeping process increases, based on the size and nature of your business and the volume of daily transactions. In CKP, we provide professional outsourced bookkeeping services in Malaysia as your second gatekeeper. We will assist in balancing your books, tallying your ledger and basic financial reports, and ensuring your records are in order before the error becomes a real, costly issue. Our accounting and bookkeeping services clients span from small startups to large corporations.`,
  },
  {
    srcTitle: 'cost-reductions',
    title: 'Cost Reductions',
    description: `You'll be shocked at how much money and time you can save and how much easier your life will be if you outsource your accounting and bookkeeping services. If you find yourself spending so much time sifting over receipts, bills, invoices, inventory records, and other miscellaneous data, our bookkeeping services Malaysia can help. While it is not inconceivable for management to learn correct bookkeeping from the ground up, outsourcing doesn't only save you time and money but also provides you with a committed, fully-trained team of licensed accountants to review your company's finances - all for less than your monthly expenses if you were to do it yourself.`,
  },
];

function popUps() {
  const renderCard = (props, index) => {
    return (
      <div key={index} className="flex flex-col w-full mt-36 md:mt-72">
        <div className="flex flex-row items-center">
          <img
            className="w-24 md:w-48 mr-10 md:mr-20"
            src={`assets/images/pages/team/${props.srcTitle}.png`}
            alt={props.srcTitle}
          />
          <Typography className="!text-[20px] md:!text-[50px] !font-900 !leading-[20px] md:!leading-[40px]">
            {props.title}
          </Typography>
        </div>
        <Typography className="!text-[12px] md:!text-[25px] !font-400 !leading-[14px] md:!leading-[28px] !mt-10 md:!mt-20">
          {props.description}
        </Typography>
      </div>
    );
  };
  return (
    <Box className="flex flex-col relative pb-36 md:pb-72" style={{ color: '#1C2687' }}>
      <div className="flex flex-col">
        <img src="assets/images/pages/team/what-you-expect.png" alt="what-you-expect" />
      </div>
      <div className="absolute inset-0 sm:top-[8rem] md:top-[12rem] p-10 md:px-32">
        <Typography className="!text-[20px] md:!text-[40px] !font-900 !leading-[20px] md:!leading-[40px] sm:w-5/12">
          What you can expect from the team
        </Typography>
        <Typography className="!text-[12px] md:!text-[25px] !mt-10 md:!mt-20 !font-400 !leading-[14px] md:!leading-[25px] sm:w-7/12">
          Behind every up-to-date transaction are qualified accountants that strive to give the best
          accounting services Malaysia. The team is armed with qualified certifications, membership
          associations, and culture of continuously upgrading their skills. Relieve your busy mind
          of every detail, task, and process by allowing our support staff to smoothen your business
          operations.
        </Typography>
      </div>

      <div className="grid grid-cols-12 py-10 md:py-48 items-center" style={{ color: '#1C2687' }}>
        <div className="col-start-2 col-span-10 mb-12 md:mb-36">
          <Typography className="!text-[20px] md:!text-[50px] !font-900 !leading-[28px] md:!leading-[40px]">
            Professional Accounting Services.
          </Typography>
        </div>
        <div className="col-start-2 md:col-start-2 col-span-10 md:col-span-5 mr-10 md:mr-20">
          <Typography className="!text-[12px] md:!text-[25px] !font-400 !leading-[14px] md:!leading-[28px]">
            Our strength comes from focusing on small to medium-sized businesses and specialising in
            their compliance needs. We offer our clients a hassle-free one-stop service that
            includes timely delivery of outstanding quality at an affordable price. The firm's
            partners, managers, support staff, and professional advisors have extensive training,
            technical experience, and experience providing professional accounting assistance to
            businesses and individuals. As a one-stop service provider, we are closely affiliated
            with many local panels of professionals in legal, financial planning, and valuation. As
            a result, our clients who engage CKP's accounting services in Kuala Lumpur can expect a
            prompt and high-quality services from us. At CKP, we offer comprehensive corporate
            services such as company incorporation, corporate secretarial, accounting, and payroll
            for start-ups and small to medium-sized businesses.
          </Typography>
        </div>
        <Box className="col-start-2 md:col-start-7 col-span-10 md:col-span-5 mt-10 md:mt-0 !mx-auto w-6/12 md:w-full">
          <img
            src="assets/images/pages/team/professional-accounting-services.png"
            alt="professional-accounting-services"
          />
        </Box>
        <div className="col-start-2 col-span-10">
          {CardData.map((item, index) => {
            return renderCard(item, index);
          })}
        </div>
      </div>
    </Box>
  );
}

export default popUps;
