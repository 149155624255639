import emailjs from '@emailjs/browser';

const postContactUs = (data, from) => {
  return emailjs.send(
    process.env.REACT_APP_EMAILJS_SERVICE_ID,
    process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
    {
      formName: data.name,
      formEmail: data.email,
      formJobTitle: data.jobTitle,
      formCompanyName: data.companyName,
      formAppointmentDate: data.appointmentDate,
      formMessage: data.message,
      formFrom: from,
      formSentFrom: window.location.href,
    }
  );
};

export default postContactUs;
