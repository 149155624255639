import { Box, Hidden, Typography } from '@mui/material';

const data = [
  {
    srcTitle: 'improved-workflows',
    title: 'Improved Workflows',
    description: `Your employees will spend less time doing manual tasks like preparing payment vouchers, manual authorisation forms, and manual bank reconciliation.`,
  },
  {
    srcTitle: 'reduce-manual-tasks',
    title: 'Reduce Manual Tasks',
    description: `The Xero accounting software Malaysia is designed to help a Xero user reduce manual tasks and workload while improving overall business performance through a robust accounting system.`,
  },
  {
    srcTitle: 'automation',
    title: 'Automation',
    description: `With one click of a button, you may now conduct business analysis and see and generate reports. Moreover, you can digitise many accounting processes, putting financial data and real-time insights at your fingertips.`,
  },
];

const lastData = [
  {
    title: 'Maintain an Up-to-Date Record',
    description: `Quickly categorise your bank transactions as they come into Xero each day to track cash flow and have everything ready for tax season.`,
  },
  {
    title: 'Get Paid More Quickly',
    description: `Minimise the time it takes to get paid by using online invoices and letting Xero chase down late payments with automated reminders.`,
  },
  {
    title: 'Neglecting Business Health',
    description: `Monitor the money coming in and going out on a daily basis, no matter where you are, with simple charts that display up-to-date figures.`,
  },
];

function WhatWeDo() {
  const renderData = (props, index) => {
    return (
      <div key={index} className="flex flex-col items-center">
        <Hidden mdDown>
          <img
            src={`assets/images/pages/ai-accounting/${props.srcTitle}.png`}
            alt={props.srcTitle}
          />
        </Hidden>
        <Hidden mdUp>
          <img
            className="w-24"
            src={`assets/images/pages/ai-accounting/${props.srcTitle}.png`}
            alt={props.srcTitle}
          />
        </Hidden>
        <Typography className="!text-[12px] md:!text-[25px] !font-700 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          {props.title}
        </Typography>
        <Typography className="!text-[12px] md:!text-[25px] !font-400 !mt-5 md:!mt-10 !leading-[14px] md:!leading-[28px] text-white">
          {props.description}
        </Typography>
      </div>
    );
  };

  const renderLastData = (props, index) => {
    return (
      <div
        key={index}
        className={`flex flex-col pl-20 ${index === 1 || index === 2 ? 'md:border-l-2' : ''}`}
      >
        <Typography className="!text-[16px] md:!text-[35px] !font-700 !mt-5 md:!mt-10 !leading-[18px] md:!leading-[37px]">
          {props.title}
        </Typography>
        <Typography className="!text-[12px] md:!text-[25px] !font-400 !mt-5 md:!mt-10 !leading-[14px] md:!leading-[28px] text-white">
          {props.description}
        </Typography>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-12 py-72 bg-[#F5054F]" style={{ color: '#1C2687' }}>
      <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8 text-center">
        <Typography className="!text-[29px] md:!text-[58px] !font-700 !leading-[14px] md:!leading-[28px]">
          What We Do?
        </Typography>
        <Typography className="!text-[12px] md:!text-[25px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          We are a certified Xero accounting Malaysia's gold partner, providing full Xero accounting
          software services to your business:
        </Typography>

        <Box className="px-10 md:px-20 py-5 md:py-10 rounded-[20px] border-1 md:border-2 mt-20 text-start w-10/12 mx-auto">
          <ul
            className="!text-[12px] md:!text-[25px] !font-400 !leading-[13px] md:!leading-[26px] text-white space-y-10 "
            style={{ listStyleType: 'disc', paddingLeft: '20px' }}
          >
            <li>Assist you in integrating Xero into your business landscape</li>
            <li>Live tutorial videos</li>
            <li>Continuous Technical Support</li>
          </ul>
        </Box>
      </div>
      <div className="col-start-2 col-span-10 text-center">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-20 md:gap-40 mt-16 md:mt-36">
          {data.map((item, index) => {
            return renderData(item, index);
          })}
        </div>
        <Box className="py-10 md:py-20 rounded-[20px] mt-16 md:mt-36 bg-white w-10/12 mx-auto">
          <Typography className="!text-[16px] md:!text-[35px] !font-700 !leading-[20px] md:!leading-[28px]">
            Advantages Of Xero Cloud Accounting
          </Typography>
        </Box>
      </div>
      <div className="col-start-2 md:col-start-2 col-span-10 md:col-span-6">
        <Typography className="!text-[12px] md:!text-[25px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          Our clients can benefit from currency conversion when using Xero accounting software
          Malaysia. We understand that you may occasionally receive income from foreign and other
          countries, and it takes a significant amount of effort to convert and ensure that your
          business accounting is maintained. However, with Xero cloud accounting software,
        </Typography>
        <Typography className="!text-[12px] md:!text-[25px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          you will not have to go through such hassles and difficulties. Because the software is
          user-friendly and convenient, you can see how we do online accounting in Malaysia. Our
          Malaysian-certified accounting consultant will guide and advise you.
        </Typography>
      </div>
      <div className="col-start-8 sm:col-start-8 col-span-5 ml-10 md:ml-20 mt-10 md:my-auto">
        <img
          className="w-8/12"
          src="assets/images/pages/ai-accounting/xero-gold-partner-badge.png"
          alt="xero-gold-partner-badge"
        />
      </div>
      <div className="col-start-2 col-span-10 text-center mt-10">
        <Box className="mt-30 md:mt-60 border-1 md:border-2 rounded-[20px] pb-10 md:pb-20 px-5 md:px-10">
          <div className="flex flex-row w-full items-center justify-center">
            <Typography className="!text-[16px] md:!text-[35px] !font-700 !leading-[14px] md:!leading-[28px] -translate-y-2/4 px-10 md:px-20 bg-[#F5054F]">
              Make Life Easier With Xero
            </Typography>
          </div>
          <Typography className="!text-[12px] md:!text-[25px] !font-400 !leading-[14px] md:!leading-[28px] text-white">
            The Xero cloud accounting system is accessible from anywhere with an internet
            connection, making it ideal for business owners, finance personnel, or employees who
            need to access Xero. In addition, because Xero is a subscription-based software, you can
            easily add and remove users. The Xero packages include an unlimited number of users,
            allowing the system to scale with your business without additional costs.
          </Typography>
          <Typography className="!text-[12px] md:!text-[25px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px] text-white">
            Furthermore, Xero cloud accounting software eliminates the need for an IT infrastructure
            or data centres to support and keep the system running or to work remotely, saving you
            time, money, and resources.
          </Typography>
        </Box>
      </div>
      <div className="col-start-2 md:col-start-2 col-span-10 md:col-span-11">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-x-10 md:gap-x-20 mt-16 md:mt-36">
          {lastData.map((item, index) => {
            return renderLastData(item, index);
          })}
          <div className="h-[5rem] md:h-[10rem] md:-mt-32">
            <img src="assets/images/pages/ai-accounting/isolation-mode.png" alt="isolation-mode" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatWeDo;
