import { Box, Typography } from '@mui/material';
import PricingCard from './PricingCard';
import pricingData from '../dataStore';

function CkpSolutions() {
  return (
    <div className="grid grid-cols-12 py-32 md:py-96" style={{ color: '#1C2687' }}>
      <div className="col-start-2 col-span-7 mb-24 md:mb-48 mr-12 md:mr-24">
        <div className="flex flex-col">
          <Typography className="!font-customBlack !text-[23px] md:!text-[50px] !font-900 !leading-[24px] md:!leading-[48px]">
            CKP Solutions
          </Typography>
          <Typography className="!font-customBlack !text-[16px] md:!text-[35px] !font-900 !mt-2 md:!mt-4 !leading-[20px] md:!leading-[40px] text-[#F5054F]">
            Packages for every business
          </Typography>
          <div className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            <h1 className="inline">
              <b>Outsource accounting in Malaysia</b> is crucial for business growth
            </h1>
            , but it can be a time-consuming hassle. Our Cloud-based AI accounting packages free up
            your time so you can focus on your actual business. With real-time access to data on any
            device, our cost-effective solutions give you the confidence to
            <span className="!font-700">&nbsp;build bold.</span>
          </div>
        </div>
        <div className="flex flex-col mt-[5rem] md:mt-[10rem]">
          <Typography className="!font-customBlack !text-[23px] md:!text-[50px] !font-900 !leading-[24px] md:!leading-[48px]">
            Solo Entreprenuer & Micro SME Packages
          </Typography>
          <Typography className="!font-customBlack !text-[16px] md:!text-[35px] !font-900 !mt-2 md:!mt-4 !leading-[20px] md:!leading-[40px] text-[#F5054F]">
            Everything you need to get started
          </Typography>
          <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            You're a small company with limited resources, but you want to make sure everything is
            compliant and built on a solid accounting base, so you are ready to grow in the future.
          </Typography>
          <div className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            <h1 className="inline">
              With these packages from our outsourced accounting company, you get a subscription
              with Xero
            </h1>
            . We'll help you set everything up, give you training on how to get the most out of the
            software, and will be on hand to assist if you ever need it.
          </div>
        </div>
      </div>
      <Box className="col-start-9 col-span-4 md:ml-auto -mr-[20%] xl:-mr-[12%] md:-mt-28">
        <img src="assets/images/pages/pricing/ckp-solutions.png" alt="ckp-solutions" />
      </Box>
      <div className="col-start-3 md:col-start-2 col-span-8 md:col-span-10 mt-16 md:mt-36">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 md:gap-20">
          {pricingData?.sme_package?.map((item, index) => {
            return <PricingCard key={index} props={item} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default CkpSolutions;
