import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const benefitsData = [
  {
    srcTitle: 'benefits-1',
    title: 'Save time',
    text: 'Click a few buttons and your accounting is done.',
  },
  {
    srcTitle: 'benefits-2',
    title: 'Reduce Costs',
    text: 'Time is money and using less means paying less.',
  },
  {
    srcTitle: 'benefits-3',
    title: 'Accurate & Efficient',
    text: 'Unbiased data and money management',
  },
  {
    srcTitle: 'benefits-4',
    title: 'Gain insight',
    text: 'Make informed decisions and improve financial performance',
  },
  {
    srcTitle: 'benefits-5',
    title: 'Access Anywhere',
    text: 'Real-time data, anytime on any device',
  },
];

function Benefits() {
  const renderBenefitsData = (props, index) => {
    return (
      <div key={index} className="flex flex-col items-center">
        <Box className="bg-[#1C2687] rounded-full w-48 h-48 md:w-96 md:h-96 p-[0.9rem] md:p-[1.8rem] justify-center">
          <img
            src={`assets/images/pages/ai-accounting/${props.srcTitle}.png`}
            alt={props.srcTitle}
          />
        </Box>
        <Typography className="!font-customBlack !text-[14px] md:!text-[28px] !font-900 !leading-[22px] md:!leading-[44px] !mt-20 md:!mt-40">
          {props.title}
        </Typography>
        <Typography className="!text-[10px] md:!text-[23px] !font-400 !leading-[14px] md:!leading-[28px]">
          {props.text}
        </Typography>
      </div>
    );
  };
  return (
    <div className="grid grid-cols-12 py-16 md:py-32 text-center" style={{ color: '#1C2687' }}>
      <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8">
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-700">
          Benefits
        </Typography>

        <div className="grid grid-cols-3 md:grid-cols-5 gap-y-20 gap-x-20 mt-20 md:mt-40">
          {benefitsData.map((item, index) => {
            return renderBenefitsData(item, index);
          })}
        </div>
      </div>
    </div>
  );
}

export default Benefits;
