import { Box, Typography } from '@mui/material';
import BgRedButton from '../../../shared-components/BgRedButton';

function LetGetStarted() {
  return (
    <div className="grid grid-cols-12 pb-24 md:pb-48">
      <div className="col-start-5 col-span-4 mt-[8rem] md:mt-[30rem]">
        <Box className="rounded-[20px] bg-[#F5054F] py-2 md:py-4" />
      </div>
      <div
        className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8 text-center mt-36 md:mt-72"
        style={{ color: '#1C2687' }}
      >
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-900 !leading-[28px] md:!leading-[55px]">
          Forget what you know about accounting
        </Typography>
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-900 !leading-[28px] md:!leading-[55px]">
          There is a better way
        </Typography>
        <div className="mt-36 md:mt-72">
          <BgRedButton title="Let's get started" destination="/contact-us" />
        </div>
        <Typography className="!font-customBlack !text-[25px] md:!text-[50px] !font-900 !mt-[7rem] md:!mt-[15rem] !leading-[24px] md:!leading-[48px] ">
          Drop us a line to get a quote.
        </Typography>
      </div>
    </div>
  );
}

export default LetGetStarted;
