import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useState } from 'react';
import FounderCard from './FounderCard';

function TheFounders() {
  const [open, setOpen] = useState(false);
  const [popUpState, setPopUpState] = useState(0);

  const handleOpen = (state) => {
    setOpen(true);
    setPopUpState(state);
  };
  const handleClose = () => setOpen(false);

  return (
    <div className="grid grid-cols-12 py-[6rem] md:py-[12rem]" style={{ color: '#1C2687' }}>
      <div className="col-start-2 md:col-start-2 col-span-10 md:col-span-5 ">
        <img className="w-full" src="assets/images/pages/team/the-founder.png" alt="the-founder" />
      </div>
      <div className="col-start-2 md:col-start-7 col-span-10 md:col-span-5 lg:col-span-4 xl:col-span-3">
        <div className="flex flex-col justify-center mt-10 md:mt-20 ml-30 md:ml-60">
          <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !leading-[20px] md:!leading-[40px]">
            The Founders
          </Typography>
          <Typography
            className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !leading-[20px] md:!leading-[40px] !mt-2"
            style={{ color: '#F5054F' }}
          >
            We wanted to make accounting fun
          </Typography>
          <div className="mt-20 md:mt-40">
            <Button
              className="!font-customBlack !normal-case !px-10 md:!px-20 !py-3 md:!py-6 !text-[12px] md:!text-[24px] !leading-[28px] !font-700 !border-1 md:!border-2 !rounded-[8px]"
              variant="outlined"
              onClick={handleOpen}
              style={{
                color: '#F5054F',
                borderColor: '#F5054F',
              }}
            >
              Find out more
            </Button>
          </div>
        </div>
      </div>
      <div className="col-start-2 md:col-start-2 col-span-5 md:col-span-3 mt-40 md:mt-80">
        <Box className="rounded-[20px] bg-[#F5054F] py-2 md:py-4" />
      </div>
      <div className="col-start-3 col-span-8">
        <FounderCard />
      </div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent
          className="flex flex-col !px-20 md:!px-40 !py-10 md:!py-20"
          style={{ color: '#1C2687' }}
        >
          <Typography className="!text-[12px] md:!text-[22px] !font-400 !leading-[14px] md:!leading-[28px]">
            With more than 20 years of combined experience, Jeremy Chia and Edward Ka have always
            believed in empowering business owners to make informed decisions. They strongly believe
            in adding value by providing financial insights and instilling confidence to grow one's
            business. This is one of the core reasons for the establishment of their accounting
            services Malaysia.
          </Typography>
          <Typography className="!text-[12px] md:!text-[22px] !font-400 !leading-[14px] md:!leading-[28px] !mt-20 md:!mt-40">
            Beneath the layers of financial knowledge are personalised touch, sincerity, and a
            passion for helping small businesses grow. Their conviction is that every business
            deserves a good accountant who believes in them.
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TheFounders;
