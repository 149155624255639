import { Box, Typography } from '@mui/material';

const redCircle = {
  //   width: '394PX', // Adjust the size of the circle as needed
  //   height: '394PX', // Width and height should be equal to create a circle
  borderRadius: '50%',
  border: '3px solid #F5054F',
  boxShadow: '5px 8px 4px 0px #F5054F',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const blueCircle = {
  //   width: '394PX', // Adjust the size of the circle as needed
  //   height: '394PX', // Width and height should be equal to create a circle
  borderRadius: '50%',
  border: '3px solid #1C2687',
  boxShadow: '5px 8px 4px 0px #2C3584',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

function OtherCharges() {
  return (
    <div className="flex flex-col items-center py-32 md:py-96">
      <div className="flex flex-col md:flex-row w-full justify-center md:justify-between items-center">
        <img
          className="w-28 md:w-56 hidden md:block"
          src="assets/images/pages/pricing/eclipse-1.png"
          alt="eclipse-1"
        />
        <div className="flex flex-col items-center justify-center text-center">
          <Typography className="!font-customBlack !text-[15px] md:!text-[30px] !font-900 !leading-[16px] md:!leading-[33px] !mb-32">
            OTHER CHARGES
          </Typography>
          <div className="flex flex-col md:flex-row w-full justify-center items-center">
            <Box
              style={redCircle}
              className="px-10 md:px-20 mr-20 md:mr-60 w-[197px] md:w-[394px] h-[197px] md:h-[394px]"
            >
              <Typography className="!text-[10px] md:!text-[20px] !font-900 !leading-[16px] md:!leading-[33px] text-[#2C3584] uppercase">
                From E Preparation & Submission
              </Typography>

              <Typography className="!text-[6px] md:!text-[16px] !font-700  !leading-[14px] md:!leading-[28px]">
                Dormant - RM300
              </Typography>
              <Typography className="!text-[6px] md:!text-[16px] !font-700  !leading-[14px] md:!leading-[28px]">
                1 - 5 Staffs - RM400
              </Typography>
              <Typography className="!text-[6px] md:!text-[16px] !font-700  !leading-[14px] md:!leading-[28px]">
                6 - 10 Staffs - RM500
              </Typography>
              <Typography className="!text-[6px] md:!text-[16px] !font-700  !leading-[14px] md:!leading-[28px]">
                11 - 20 Staffs - RM600
              </Typography>
              <Typography className="!text-[6px] md:!text-[16px] !font-700  !leading-[14px] md:!leading-[28px]">
                21- 30 Staffs - RM700
              </Typography>
            </Box>
            <Box
              style={blueCircle}
              className="px-10 md:px-20 w-[197px] md:w-[394px] h-[197px] md:h-[394px] mt-10 md:mt-0"
            >
              <Typography className="!text-[10px] md:!text-[20px] !font-900 !leading-[16px] md:!leading-[33px] text-[#2C3584] uppercase">
                From EA Preparation
              </Typography>

              <Typography className="!text-[6px] md:!text-[16px] !font-700  !leading-[14px] md:!leading-[28px] !mt-10 md:!mt-20">
                First 1-3 Staffs
              </Typography>
              <Typography className="!text-[6px] md:!text-[16px] !font-700  !leading-[14px] md:!leading-[28px]">
                (For Non-Existing Client - RM200)
              </Typography>
              <Typography className="!text-[6px] md:!text-[16px] !font-700  !leading-[14px] md:!leading-[28px] !mt-10 md:!mt-20">
                FOC 3 Staffs Onward
              </Typography>
              <Typography className="!text-[6px] md:!text-[16px] !font-700  !leading-[14px] md:!leading-[28px]">
                (For Non-Existing Client - RM50) - FOC
              </Typography>
            </Box>
          </div>
          <Box
            style={redCircle}
            className="px-10 md:px-20 w-[197px] md:w-[360px] h-[197px] md:h-[360px] mt-10 md:mt-0"
          >
            <Typography className="!text-[10px] md:!text-[20px] !font-900 !leading-[13px] md:!leading-[33px] text-[#2C3584] uppercase">
              Registration of EPF
            </Typography>
            <Typography className="!text-[6px] md:!text-[16px] !font-700  !leading-[14px] md:!leading-[28px] !mt-8 md:!mt-20">
              - RM250
            </Typography>
            <Typography className="!text-[10px] md:!text-[20px] !font-900 !leading-[13px] md:!leading-[33px] text-[#2C3584] uppercase !mt-10 md:!mt-20">
              Registration of EIS and Perkeso
            </Typography>
            <Typography className="!text-[6px] md:!text-[16px] !font-700  !leading-[14px] md:!leading-[28px] !mt-8 md:!mt-20">
              -RM300
            </Typography>
          </Box>
        </div>
        <img
          className="self-end mb-[3rem] w-[7rem] md:w-[14rem]"
          src="assets/images/pages/pricing/eclipse-3.png"
          alt="eclipse-3"
        />
      </div>
    </div>
  );
}

export default OtherCharges;
