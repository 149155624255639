import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet-async';
import GetInTouch from '../../shared-components/GetInTouch';
import CkpSolutions from './components/CkpSolutions';
import FullAccountServicePackage from './components/FullAccountServicePackage';
import PayrollPackage from './components/PayrollPackage';
import OtherCharges from './components/OtherCharges';
import APManagementPackage from './components/APManagementPackage';
import LetGetStarted from './components/LetGetStarted';
import SdnBhdIncorporation from './components/SdnBhdIncorporation';
import CompanySecretarial from './components/CompanySecretarial';

function Pricing() {
  return (
    <Box className="flex flex-col">
      <img
        className="w-full"
        src="assets/images/pages/pricing/pricing-header.png"
        alt="pricing-header"
      />
      <CkpSolutions />
      <FullAccountServicePackage />
      <PayrollPackage />
      <OtherCharges />
      <APManagementPackage />
      <SdnBhdIncorporation />
      <CompanySecretarial />
      <LetGetStarted />
      <GetInTouch />
      <Helmet>
        <title>Outsource Accounting Malaysia | Chia, Ka & Partners</title>
        <meta
          name="description"
          content="We are a company with outsource accounting in Malaysia. We help businesses automate their financial processes with our cloud automation apps. Find out more!"
        />
      </Helmet>
    </Box>
  );
}

export default Pricing;
