import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link, useLocation } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import MyLink from '@mui/material/Link';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    variant="scrollable"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 900,
  fontSize: '1.25rem',
  marginRight: theme.spacing(2),
  borderRadius: '8px',
  color: '#fff',
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#1C2687',
  },
}));

function NavbarContent() {
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  // Open the dropdown menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget.parentElement);
  };

  // Close the dropdown menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // update active tab
    const lastItem = location?.pathname?.split('/')?.filter(Boolean).pop();
    switch (lastItem) {
      case 'ai-accounting':
        setValue(1);
        break;
      case 'services':
      case 'accounting-and-bookkeeping':
      case 'company-incorporation':
      case 'secretarial-services':
      case 'business-advisory':
      case 'company-valuation':
        setValue(2);
        break;
      case 'team':
        setValue(3);
        break;
      case 'pricing':
        setValue(4);
        break;
      case 'client':
        setValue(5);
        break;
      case 'blog':
        setValue(6);
        break;
      case 'blog/content':
        setValue(6);
        break;
      case 'contact-us':
        setValue(-1);
        break;
      default:
        setValue(0);
    }

    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Box className="flex flex-row items-center w-full">
      <MyLink component={Link} to="/home">
        <img className="xl:-mt-48 xl:mr-12 p-0" src="assets/images/logo/ckp-logo.png" alt="logo" />
      </MyLink>
      <StyledTabs
        value={value === -1 ? false : value} // disable the active tabs when it is in contact page
        onChange={handleChange}
        aria-label="navbar content"
      >
        <StyledTab className="!font-customBlack" component={Link} label="Home" to="/home" />
        <StyledTab
          className="!font-customBlack"
          component={Link}
          label="Ai Accounting"
          to="/ai-accounting"
        />
        <StyledTab
          className="!font-customBlack !min-h-0"
          component={Link}
          label="Services"
          to="/services"
          icon={<KeyboardArrowDownOutlinedIcon onClick={handleMenuOpen} />}
          iconPosition="end"
        />
        <StyledTab className="!font-customBlack" component={Link} label="Team" to="/team" />
        <StyledTab className="!font-customBlack" component={Link} label="Pricing" to="/pricing" />
        <StyledTab className="!font-customBlack" component={Link} label="Client" to="/client" />
        <StyledTab className="!font-customBlack" component={Link} label="Blog" to="/blog" />
      </StyledTabs>

      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleMenuClose}>
        <MenuItem
          className="!font-customBlack !py-10 hover:!text-white hover:!bg-[#1C2687]"
          component={Link}
          to="/services/accounting-and-bookkeeping"
          onClick={handleMenuClose}
        >
          Accounting and Bookkeeping
        </MenuItem>
        <MenuItem
          className="!font-customBlack !py-10 hover:!text-white hover:!bg-[#1C2687]"
          component={Link}
          to="/services/company-incorporation"
          onClick={handleMenuClose}
        >
          Company Incorporation Services
        </MenuItem>
        <MenuItem
          className="!font-customBlack !py-10 hover:!text-white hover:!bg-[#1C2687]"
          component={Link}
          to="/services/secretarial-services"
          onClick={handleMenuClose}
        >
          Secretarial Services
        </MenuItem>
        <MenuItem
          className="!font-customBlack !py-10 hover:!text-white hover:!bg-[#1C2687]"
          component={Link}
          to="/services/business-advisory"
          onClick={handleMenuClose}
        >
          Business Advisory Services
        </MenuItem>
        <MenuItem
          className="!font-customBlack !py-10 hover:!text-white hover:!bg-[#1C2687]"
          component={Link}
          to="/services/company-valuation"
          onClick={handleMenuClose}
        >
          Company Valuation Service
        </MenuItem>
      </Menu>

      <Button
        component={Link}
        className="!font-customBlack !text-[20px] !normal-case !font-[900] !px-12 !leading-normal !ml-auto !border-white"
        to="/contact-us"
        variant={location.pathname.indexOf('contact-us') > -1 ? 'contained' : 'outlined'}
        color="primary"
        size="small"
      >
        Contact
      </Button>
    </Box>
  );
}

export default NavbarContent;
