import { Typography, Box, Hidden } from '@mui/material';
import WhiteOutlinedButton from '../../../shared-components/WhiteOutlinedButton';

const textData = [
  {
    text: 'CKP is best described as a firm that takes the time to help its clients. As a fairlynew member of the business community, their assistance in all accounting matters have really allowed me to push forward with confidence. There is a personal touch which I appreciate and their staff are friendly and communicative. As a person who looks into the details, they are patient with my numerous questions and requests. For that personal touch, go with Edward and CKP.',
  },
  {
    text: `Engaging CKP was one of the best business decision we have ever made. The management team and staffs are professional and quick in addressing our issues. The cloud-based system accounting system and application make daily book keeping activity a breeze. With CKP's excellent service and accounting system, we are able to extract meaningful reports based on our needs on timely basis.`,
  },
  {
    text: 'Very grateful to have CKP as my outsource account. Besides giving you a clear monthly report, they are also very sharp and knowledgeable to provide you the right business advices based on your data.',
  },
];

const profileData = [
  {
    name: 'A. Pillay',
    title: 'Founder, Aerial Ascent Sdn Bhd',
  },
  { name: 'Dave Chat and Mary Chang', title: 'Founders, Feel Home Malaysia Sdn Bhd' },
  { name: 'Richard Mah', title: 'Founder, One Catalyst Interior Sdn Bhd' },
];

function Reviews() {
  const renderTestimonialTitle = (index) => {
    return (
      <Typography
        key={index}
        className="!font-customBlack !text-[12px] md:!text-[25px] !font-900 !leading-[17px] md:!leading-[34px]"
        style={{ color: '#1C2687' }}
      >
        Testimonial {index + 1}
      </Typography>
    );
  };

  const renderTestimonialData = (props, index) => {
    return (
      <Box key={index} className="border-1 md:border-2 rounded-lg p-20">
        <Typography className="!text-[10px] md:!text-[20px] !font-400 !leading-[11px] md:!leading-[22px]">
          {props.text}
        </Typography>
      </Box>
    );
  };

  const renderProfileData = (props, index) => {
    return (
      <div key={index} className="flex flex-row items-center text-center !justify-center">
        <img className="w-24 md:w-48" src="assets/images/pages/landing/profile.png" alt="logo" />
        <div className="flex flex-col ml-10">
          <Typography className="!text-[10px] md:!text-[20px] !font-700 !leading-[11px] md:!leading-[22px] uppercase">
            {props.name}
          </Typography>
          <Typography className="!text-[10px] md:!text-[20px] !font-400 !leading-[11px] md:!leading-[22px]">
            {props.title}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <div className="grid grid-cols-12 py-36 md:py-72 bg-[#F4064F] text-white text-center">
      <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8">
        <Typography className="!text-[29px] md:!text-[58px] !font-900 !font-customBlack">
          Reviews
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          We love seeing businesses grow and are proud to belong to a community of businesses where
          we have helped in a small way toward achieving their entrepreneurial dreams.
        </Typography>

        {/* Desktop Version */}
        <Hidden mdDown>
          <div className="grid grid-cols-3 justify-center mt-24 md:mt-48 gap-x-20 md:gap-x-40 gap-y-10 md:gap-y-20 mb-48">
            {Array.from({ length: 3 }, (_, index) => renderTestimonialTitle(index))}

            {textData.map((item, index) => {
              return renderTestimonialData(item, index);
            })}
            {profileData.map((item, index) => {
              return renderProfileData(item, index);
            })}
          </div>
        </Hidden>
        {/* Desktop Version */}

        {/* Mobile Version */}
        <Hidden mdUp>
          {Array.from({ length: 3 }, (_, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center mt-32 space-y-10 mb-24"
            >
              {renderTestimonialTitle(index)}
              {renderTestimonialData(textData[index])}
              {renderProfileData(profileData[index])}
            </div>
          ))}
        </Hidden>
        {/* Mobile Version */}

        <WhiteOutlinedButton title="Our clients" destination="/client" />
      </div>
    </div>
  );
}

export default Reviews;
