import { Box, Typography } from '@mui/material';

function CKPClients() {
  return (
    <div
      className="grid grid-cols-12 pt-32 md:pt-96 pb-24 md:pb-48 items-center "
      style={{ color: '#1C2687' }}
    >
      <div className="col-start-2 md:col-start-2 lg:col-start-3 col-span-10 md:col-span-6 lg:col-span-5 mr-0 md:mr-24 mb-24 md:mb-0">
        <div className="flex flex-col">
          <Typography className="!font-customBlack !text-[23px] md:!text-[50px] !font-900 !leading-[24px] md:!leading-[48px]">
            CKP Clients
          </Typography>
          <Typography className="!font-customBlack !text-[16px] md:!text-[35px] !font-900 !mt-2 md:!mt-4 !leading-[20px] md:!leading-[40px] text-[#F5054F]">
            Our community of bold businesses
          </Typography>
          <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            Our clients have all made the cloud based ai accounting systems, and we're proud to have
            been a part of their transformation. Our innovative technology and tailored solutions
            have helped them to improve accuracy, efficiency, and gain valuable financial insights.
          </Typography>
        </div>
      </div>
      <Box className="col-start-2 md-col-start-7 lg:col-start-8 col-span-10 md:col-span-4 lg:col-span-4 mx-auto">
        <img
          className="rounded-full w-[12rem] md:w-full"
          src="assets/images/pages/client/ckp-clients.png"
          alt="ckp-clients"
        />
      </Box>
    </div>
  );
}

export default CKPClients;
