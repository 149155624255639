import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function WhiteOutlinedButton(props) {
  const { title, destination, popUp, state } = props;

  if (popUp) {
    return (
      <Button
        className="!font-customBlack whitespace-nowrap !normal-case !px-10 md:!px-20 !text-[12px] md:!text-[24px] !font-700 !rounded-[8px] !border-1 md:!border-2 !border-white !text-white"
        variant="outlined"
        onClick={() => popUp(state)}
      >
        {title}
      </Button>
    );
  }

  return (
    <Button
      className="!font-customBlack whitespace-nowrap !normal-case !px-10 md:!px-20 !text-[12px] md:!text-[24px] !font-700 !rounded-[8px] !border-1 md:!border-2 !border-white !text-white"
      variant="outlined"
      component={Link}
      to={destination}
    >
      {title}
    </Button>
  );
}

export default WhiteOutlinedButton;
