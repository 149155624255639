import { Dialog, DialogContent, Hidden, Typography } from '@mui/material';
import { useState } from 'react';
import BgRedButton from '../../../shared-components/BgRedButton';

const data = [
  {
    srcTitle: 'mastermind',
    title: 'The Mastermind, Mr. Oddball',
    name: 'Dr Lim Yong Teng',
    description: `Dr. Lim Yong Teng, famously referred to as "Mr. Oddball Entrepreneur," is renowned for his exceptional ability to positively impact both startup and struggling companies with his unique MASTERMIND approach. He has also been instrumental in helping well-performing companies achieve growth, expansion, and maximize their return on investment. Throughout his career, 
Dr. Lim has contributed significantly to the corporate and business world with his copyrighted works and innovative business strategies, which have been widely recognized and acclaimed by those who have had the opportunity to interact with him.`,
    popUpState: 0,
  },
  {
    srcTitle: 'managing-partner',
    title: 'Managing Partner',
    name: `Jeremy Chia`,
    description: `In 2011, Jeremy Chia commenced his professional journey as an auditor with Mazars. During his tenure, he participated in audits for various public listed companies, including those in the Property Development, Shipping, and FMCG industries. He later moved to Schlumberger as a Reporting Tax Accountant for Denmark and Norway Entities. This role allowed him to gain experience in shared service processes.`,
    popUpState: 1,
  },
  {
    srcTitle: 'partner',
    title: 'Partner',
    name: `Edward Ka`,
    description: `Early in his career, Edward worked as an Accountant and in related functions in various companies, including Amcorp Properties Bhd, SG Global Support Services Sdn Bhd and IOI Corporation Bhd. He played a crucial role in these organizations before venturing out on his own to pioneer and develop cloud accounting and business process outsourcing services in Malaysia.`,
    popUpState: 2,
  },
];

const popUpData = [
  {
    about: [
      `UNIVERSAL BUSINESS GROWTH PRINCIPLES© - BLITZKRIEG BUSINESS STRATEGY© - CIMA© (most important to include in Marketing Strategy) - YONGICOACHING© (training personnel to be trained in THETA BRAINWAVE MASTERMIND CLASS) - AI AccTracer©. Addendum: Encouraging CKP to develop and AI Digitalized Accounting and Financial system which will make business clients' life easier!`,
      `Presently Doc is showing how Accounting and Finance should face the NEW NORMAL post Covid-19 in a most abnormal world. We are accountants and financiers. We consider ourselves PROFESSIONAL ACCOUNTANTS. To Doc it is not enough. If serving businesses, we should upgrade ourselves to be BUSINESS PROFESSIONAL ACCOUNTANTS. Even this fall short of Doc's expectation. To Doc CKP as well as all Accountancy and Financial professionals should develop into MASTERMINDS behind BUSINESS PROFESSIONAL ACCOUNTACY AND FINANCE so that businesses can thrive. In fact all Professional Accountants and Financiers should also be MASTERMIND BUSINESS TURNKEY EXPERTS if they are to truly upgrade their services to business clients.`,
      `is also the Founder of Yongiway (The Simple and Easy Way) which then flows into many other areas of WEALTH, HEALTH, AND LIFESTYLE perimeters. That's why he is regarded as DOYEN GRANDMASTER of many fields. Some of which are listed below for perusal.`,
    ],
    qualifications: `3 Ph.Ds, 18 degrees, and numerous Trade Certificates. Founder, Yongiway (The Simple and Easy Way Doyen Grandmaster and Founder of Yongiway Business Feng Shui, Yongiway Business Tarot, Yongiway Palmistry, Super Psychic Business Mastery, and Yongiway Wealth, Health, and Lifestyle`,
    professional_membership: `Doc is an Independent Director and/or Consultant to a number of company`,

    other_acitivies: `Founder - Yongiway Business Mastery Founder - Yongiway Wellness & Power Founder - Awakening the Powerful You (Signature workshop) ' Presently writing book(s) on Doc's many skills and experiences based on Universal Business Principles and how to LIVE LIFE TO THE FULLEST.`,
  },
  {
    about: [
      `As the co-founding partner of CKP, he has served many SME from different industries over the years and has worked closely with clients to assist them not only in compliance however also in growing their business. His strong business acumen, strategic and forward thinking has always added value for clients in running their business.`,
    ],
    qualifications: `Chartered Accountant of Malaysia (C.A. (M)) Fellow, Associations of Chartered Certified Accountant, London (FCCA) ASEAN Certified Public Accountant (ASEAN CPA) Bachelor Degree in Applied Accounting from Oxford Brookes University Professional Membership:`,

    professional_membership: `Registered Public Accountant, Malaysia Practicing Member, Malaysia Institute of Accountants Member of Malaysia Financial Planning Council Founding Member of Malaysia Associations of Administration and Bookkeeping Associate Estate Planning Practitioner from Estate Planning Practitioners Ltd (Singapore) and The Society of Will Writers & Estate Planning Practitioners (UK)`,

    other_acitivies: `Past President of BNI Chapter Malaysia - TitanLLEST.`,
  },
  {
    about: [
      `Additionally, he specialises in indirect tax advisory, business process outsourcing and corporate finance wherein he manages a portfolio of corporate clients in a broad range of industries which encompasses manufacturing, electrical and electronics; healthcare, construction and property development; plantations, amongst others. He also has experience in business advisory engagements such as merger and acquisition, business valuation, enterprise restructuring and internal control review.`,
    ],
    qualifications: `Chartered Accountant of Malaysia (C.A. (M)) Fellow, Associations of Chartered Certified Accountant, London (FCCA) ASEAN Certified Public Accountant (ASEAN CPA) Bachelor of Commerce (HONS) Accounting from Universiti Tunku Abdul Rahman`,

    professional_membership: `Registered Public Accountant, Malaysia Practicing Member, Malaysia Institute of Accountants Member of Malaysia Financial Planning Council.`,
  },
];

function FounderCard() {
  const [open, setOpen] = useState(false);
  const [popUpState, setPopUpState] = useState(0);

  const handleOpen = (state) => {
    setOpen(true);
    setPopUpState(state);
  };
  const handleClose = () => setOpen(false);

  const renderData = (props, index) => {
    return (
      <div
        key={index}
        className="flex flex-col md:flex-row items-center justify-between mt-40 md:mt-80"
      >
        <Hidden mdUp>
          <img
            className="w-[15rem]"
            src={`assets/images/pages/team/${props.srcTitle}.png`}
            alt={props.srcTitle}
          />
        </Hidden>
        <div className="flex flex-col mt-10 md:mt-0 w-full md:w-6/12">
          <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !leading-[30px] md:!leading-[40px]">
            {props.title}
          </Typography>
          <Typography
            className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !mt-0 md:!mt-3 !leading-[20px] md:!leading-[40px]"
            style={{ color: '#F5054F' }}
          >
            {props.name}
          </Typography>
          <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            {props.description}
          </Typography>
          <div className="mt-20 md:mt-40">
            <BgRedButton popUp={handleOpen} state={props.popUpState} title="Know more" rightArrow />
          </div>
        </div>
        <Hidden mdDown>
          <div className="w-4/12">
            <img src={`assets/images/pages/team/${props.srcTitle}.png`} alt={props.srcTitle} />
          </div>
        </Hidden>
      </div>
    );
  };

  const renderPopUpText = (text, index) => {
    return (
      <Typography
        key={index}
        className="!text-[12px] md:!text-[15px] !font-400 !leading-[14px] md:!leading-[24px] text-white"
      >
        {text}
      </Typography>
    );
  };

  return (
    <>
      {data.map((item, index) => {
        return renderData(item, index);
      })}

      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              borderRadius: '30px',
            },
          },
        }}
      >
        <DialogContent className="flex flex-col !px-20 md:!px-40 !py-10 md:!py-20 bg-[#1C2687] space-y-2">
          {renderPopUpText('About')}
          {popUpData[popUpState].about.map((item, index) => {
            return renderPopUpText(item, index);
          })}
          {renderPopUpText('Qualifications:')}
          {renderPopUpText(popUpData[popUpState].qualifications)}
          {renderPopUpText(
            popUpState === 1 ? 'University Professional Membership:' : 'Professional Membership:'
          )}
          {renderPopUpText(popUpData[popUpState].professional_membership)}
          {popUpData[popUpState].other_acitivies && renderPopUpText('Other Activities:')}
          {renderPopUpText(popUpData[popUpState].other_acitivies)}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default FounderCard;
