import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet-async';

import { Typography } from '@mui/material';
import BgRedButton from '../../shared-components/BgRedButton';

function Blog() {
  return (
    <Box className="flex flex-col">
      <div
        className="grid grid-cols-12 pt-[15rem] md:pt-[35rem] h-[20rem] md:h-[38rem] bg-[#F5054F]"
        style={{ color: '#1C2687' }}
      >
        <div className="col-start-3 z-20">
          <Typography className="!font-customBlack !text-[80px] md:!text-[160px] !font-900 !leading-[24px] md:!leading-[40px] origin-top-left -rotate-90 opacity-70 text-white whitespace-nowrap">
            Blog 1
          </Typography>
        </div>
        <div className="flex col-start-4 col-span-6  -mt-48 sm:-mt-[7.6rem] md:-mt-[12.8rem] lg:-mt-[17.6rem]  xl:-mt-[21.6rem] z-10">
          <div className="flex flex-col sm:flex-row justify-between items-start w-full sm:gap-20 md:gap-80">
            <div className="flex flex-col items-center sm:-ml-[10rem] text-center mb-20 sm:mb-0">
              <img
                className="w-10/12 md:w-full"
                src="assets/images/pages/blog/blog-1.png"
                alt="blog-1"
              />
              <Typography className="!font-customBlack !text-[16px] md:!text-[35px] !font-900 !mt-10 md:!mt-20 !leading-[24px] md:!leading-[48px]">
                BLOG 1:
              </Typography>
              <Typography
                variant="h1"
                className="!text-[12px] md:!text-[24px] !font-500 !mt-2 md:!mt-4 !leading-[12px] md:!leading-[24px] w-10/12"
              >
                How to Open Sdn Bhd Company In Malaysia
              </Typography>
              <div className="mt-20 md:mt-40">
                <BgRedButton destination="/blog/content" title="Know more" rightArrow />
              </div>
            </div>
            <div className="flex flex-col items-center sm:-mr-[10rem] text-center">
              <img
                className="w-10/12 md:w-full"
                src="assets/images/pages/blog/blog-2.png"
                alt="blog-2"
              />
              <Typography className="!font-customBlack !text-[16px] md:!text-[35px] !font-900 !mt-10 md:!mt-20 !leading-[24px] md:!leading-[48px]">
                THE STAR:
              </Typography>
              <Typography className="!text-[12px] md:!text-[24px] !font-500 !mt-2 md:!mt-4 !leading-[12px] md:!leading-[24px] w-10/12">
                Accounting firm CKP bring SMES to the cloud
              </Typography>
              <div className="mt-20 md:mt-40">
                <BgRedButton
                  destination="https://www.thestar.com.my/business/business-news/2022/09/26/sabahan-accounting-duo-ckp-empower-team-and-clients-by-staying-ahead-of-the-tech-curve"
                  title="Know more"
                  rightArrow
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-start-11 z-20">
          <Typography className="!font-customBlack !text-[80px] md:!text-[160px] !font-900 !leading-[24px] md:!leading-[40px] origin-top-left -rotate-90 opacity-70 text-white whitespace-nowrap">
            Blog 2
          </Typography>
        </div>
      </div>
      <div className="h-[40rem] bg-[white]" />
      <Helmet>
        <title>How To Open Sdn Bhd Company In Malaysia | Chia, Ka & Partners</title>
        <meta
          name="description"
          content="Ever wondered what the steps are to open a sdn bhd company in Malaysia? Read more to find out or get in touch with our team of professionals to find out more!"
        />
      </Helmet>
    </Box>
  );
}

export default Blog;
