import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

function BgRedButton(props) {
  const { title, destination, popUp, state, rightArrow } = props;
  if (popUp) {
    return (
      <Button
        className="!font-customBlack whitespace-nowrap !normal-case px-10 md:!px-20 !text-[12px] md:!text-[24px] !font-700 !rounded-[8px] !bg-[#F4064F]"
        variant="contained"
        onClick={() => popUp(state)}
      >
        <div className="flex items-center">
          {title} {rightArrow && <ArrowRightIcon fontSize="large" />}
        </div>
      </Button>
    );
  }
  return (
    <Button
      className="!font-customBlack whitespace-nowrap !normal-case px-10 md:!px-20 !text-[12px] md:!text-[24px] !font-700 !rounded-[8px] !bg-[#F4064F]"
      variant="contained"
      component={Link}
      to={destination}
    >
      <div className="flex items-center">
        {title} {rightArrow && <ArrowRightIcon fontSize="large" />}
      </div>
    </Button>
  );
}

export default BgRedButton;
