import { Typography } from '@mui/material';

function XeroPartner() {
  return (
    <div
      className="mb-48 md:mb-96 w-8/12 items-center justify-center flex flex-col"
      style={{ color: '#1C2687' }}
    >
      <Typography
        variant="h1"
        className="!text-[20px] md:!text-[40px] !font-900 text-center !font-customBlack"
      >
        Xero Partner Malaysia
      </Typography>
      <Typography className="!text-[12px] md:!text-[23px] !font-400 text-center !mt-10 md:!mt-20 !leading-[14px] md:!leading-[28px]">
        As the proud recipient of the Xero Malaysia Partner of the Year 2022 award and a certified
        Xero Gold Champion Partner, our expertise in integrating Xero accounting software into
        businesses is unmatched. Our services encompass seamless integration, live tutorial videos,
        continuous technical support, and improved workflows to reduce manual tasks. As a{' '}
        <b>Xero partner in Malaysia</b>, we specialize in automating your business processes, and
        enhancing overall efficiency. The advantages of Xero cloud accounting, including currency
        conversion, accessibility from anywhere, and scalability without additional costs, make life
        easier for business owners. Choose us as your <i>Xero partner in Malaysia</i> for a
        digitalized and transformed accounting system, reducing manual workload and ensuring
        regionalized accounting knowledge. With Xero, your business thrives with efficiency,
        accessibility, and security, backed by a reputable cloud-based accounting software trusted
        globally and locally, collaborating with major banks in Malaysia.
      </Typography>
    </div>
  );
}

export default XeroPartner;
