import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet-async';

import { Typography } from '@mui/material';
import GetInTouch from '../../shared-components/GetInTouch';
import CKPClients from './components/CKPClients';
import Carousel from './components/Carousel';
import VideoCard from './components/VideoCard';
import VideoCard2 from './components/VideoCard2';
import VideoCard3 from './components/VideoCard3';
import BgRedButton from '../../shared-components/BgRedButton';
import XeroPartner from './components/XeroPartner';

function Client() {
  return (
    <Box className="flex flex-col">
      <img
        className="w-full"
        src="assets/images/pages/client/client-header.png"
        alt="client-header"
      />
      <CKPClients />
      <Carousel />
      <div className="py-32 md:py-72">
        <VideoCard />
        <VideoCard2 />
        <VideoCard3 />
      </div>

      <div
        className="flex flex-col items-center justify-center text-center pt-16 md:pt-32 pb-24 md:pb-48 px-10 md:px-20"
        style={{ color: '#1C2687' }}
      >
        <XeroPartner />

        <img
          className="w-[9rem] md:w-[17rem]"
          src="assets/images/pages/client/isolation-mode.png"
          alt="isolation-mode"
        />
        <Box className="rounded-[20px] bg-[#D8D6D8] py-2 md:py-4 w-3/12 mt-16 md:mt-32 mb-24 md:mb-48" />
        <Typography className="!font-customBlack !text-[25px] md:!text-[50px] !font-900 !leading-[28px] md:!leading-[55px]">
          Join our community of digital businesses and join the Ai accounting revolution
        </Typography>
        <div className="mt-36 md:mt-72">
          <BgRedButton title="Let's get started" destination="/contact-us" />
        </div>
        <Typography className="!font-customBlack !text-[25px] md:!text-[50px] !font-900 !mt-[7rem] md:!mt-[15rem] !leading-[24px] md:!leading-[48px] ">
          Do you have any questions?
        </Typography>
      </div>
      <GetInTouch />
      <Helmet>
        <title>Xero Partner Malaysia | Accounting Firm - Chia, Ka & Partners</title>
        <meta
          name="description"
          content="We are Xero partner in Malaysia in professional cloud accounting system. With our expertise and dedication, we provide value to your company in accounting services."
        />
      </Helmet>
    </Box>
  );
}

export default Client;
