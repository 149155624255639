import { Dialog, DialogContent, Hidden, Typography } from '@mui/material';
import { useState } from 'react';
import WhiteOutlinedButton from '../../../shared-components/WhiteOutlinedButton';
import PayrollManagement from './pop-ups/PayrollManagement';
import CompanyIncorporation from './pop-ups/CompanyIncorporation';
import CompanySecretaryRetainer from './pop-ups/CompanySecretaryRetainer';

const data = [
  {
    srcTitle: 'payroll-management',
    title: 'Payroll Management',
    subtitle: ' Simplify the payroll process',
    description: `CKP's employee data management system takes the headache out of managing attendance and making EPF and Socso payments. Our system automates this important, but repetitive task, allowing you to focus on growing your business. Consider us for seamless <b>payroll outsourcing in Malaysia.</b>`,
    popUpState: 0,
  },
  {
    srcTitle: 'company-incorporation',
    title: 'Company Incorporation',
    subtitle: `Build bold businesses`,
    description: `Starting a business can be overwhelming, with confusing legal terms and government regulations to navigate. We're here to help from the beginning, handling all the legal paperwork and providing financial advice. Our company incorporation services in Malaysia take care of the frustrating procedures so you can focus on achieving your business goals. Let us empower you to bring your ideas to life.`,
    popUpState: 1,
  },
  {
    srcTitle: 'company-secretary-retainer',
    title: 'Company Secretary Retainer',
    subtitle: `Automatic Compliance`,
    description: `Seamlessly integrate your Company’s secretarial services with our AI-integrated accounting model, we take care of all the necessary compliance requirements for your business, allowing you to concentrate on growing your business. We guarantee the accuracy and timeliness of all essential filings and reports, thereby minimizing the risk of penalties and non-compliance.`,
    popUpState: 2,
  },
];

function Section2() {
  const [open, setOpen] = useState(false);
  const [popUpState, setPopUpState] = useState(0);

  const handleOpen = (state) => {
    setOpen(true);
    setPopUpState(state);
  };
  const handleClose = () => setOpen(false);

  const renderData = (props, half) => {
    return (
      <div
        className={`flex flex-col mt-10 md:mt-0 ${half ? 'w-full md:w-6/12' : 'w-full md:w-9/12'}`}
      >
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !leading-[30px] md:!leading-[40px]">
          {props.title}
        </Typography>
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !leading-[20px] md:!leading-[40px] text-white">
          {props.subtitle}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: props.description }}
          className="!text-[12px] md:!text-[23px] !font-400 !mt-5 md:!mt-10 !leading-[14px] md:!leading-[28px]"
        />
        <div className="mt-12 md:mt-24">
          <WhiteOutlinedButton popUp={handleOpen} state={props.popUpState} title="Find out more" />
        </div>
      </div>
    );
  };

  const renderImage = (props, position, isNotRoundBorder) => {
    return (
      <div
        className={`flex justify-center ${
          position === 'right' ? 'ml-0 md:ml-48' : 'mr-0 md:mr-48'
        }`}
      >
        <img
          className={`${isNotRoundBorder ? '' : 'rounded-full'} w-6/12 md:w-full`}
          src={`assets/images/pages/services/${props.srcTitle}.png`}
          alt={props.srcTitle}
        />
      </div>
    );
  };

  return (
    <div className="grid grid-cols-12 py-36 md:py-72 bg-[#F5054F]" style={{ color: '#1C2687' }}>
      <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8 space-y-20 md:space-y-[15rem]">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Desktop Version */}
          <Hidden mdDown>
            {renderData(data[0])}
            {renderImage(data[0], 'right')}
          </Hidden>
          {/* Desktop Version */}

          {/* Mobile Version */}
          <Hidden mdUp>
            {renderImage(data[0], 'left')}
            {renderData(data[0])}
          </Hidden>
          {/* Mobile Version */}
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          {renderImage(data[1], 'left')}
          {renderData(data[1])}
        </div>
        <div className="flex flex-col md:flex-row items-center justify-between">
          {renderImage(data[2], 'left', true)}
          {renderData(data[2], true)}
        </div>
      </div>
      <div className="col-span-12 !-mb-[4.3rem] md:!-mb-[7.9rem] ">
        <div className="flex flex-row items-end justify-end">
          <img src="assets/images/pages/services/human-boulder.png" alt="human-boulder" />
        </div>
      </div>

      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent className="bg-[#F5054F] !overflow-x-hidden">
          {popUpState === 0 && <PayrollManagement />}
          {popUpState === 1 && <CompanyIncorporation />}
          {popUpState === 2 && <CompanySecretaryRetainer />}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Section2;
