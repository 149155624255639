import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import ServicesContent from '../components/ServicesContent';

const data = {
  section1: {
    title: 'Company Valuation Service',
    description: [
      `Should you wish to raise capital, sell your company, merge, or just have a better grasp of its financial situation, our business valuation service gives you the information and assurance you need to take calculated risks and establish a successful business. CKP never fails to bring you the premier service related to business valuation Malaysia thanks largely to our incredible team of analysts who will cater to your needs. If you are open-minded and love technology, then CKP should be your go-to valuation reference. Moreover, we are more than capable of entertaining companies from all over the Klang Valley region.`,
    ],
  },
  section2: [
    {
      icon: (
        <ElectricBoltOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />
      ),
      title: 'Advanced Technology',
      description:
        'Unlike CKP, not many providers of company valuation Malaysia have the aptness to make use of artificial intelligence (AI) in generating a reliable valuation report.',
    },
    {
      icon: (
        <AccountBalanceWalletOutlinedIcon
          className="!text-16 md:!text-32"
          style={{ color: '#F5054F' }}
        />
      ),
      title: 'Affiliated with Xero',
      description:
        'As the 2022 Xero Malaysia Partner of the Year, we are proud to have been upgraded as their Gold Champion Partner as the best source for business valuation Malaysia.',
    },
    {
      icon: (
        <EmojiEmotionsOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />
      ),
      title: 'Thorough Deliberation',
      description:
        'As part of our business valuation service, we collaborate with our clients on significant problems before making crucial choices by providing a trustworthy resource for relevant data.',
    },
    {
      icon: <ShareOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />,
      title: 'Tailored Sharing',
      description:
        'By answering some simple questionnaires, you will receive comprehensive data suitable for your business through our special <b>company valuation service</b>.',
    },
  ],
  section3: {
    description: [
      'In case you would like to seek help for matters that have nothing to do with <i>company valuation service</i>, we also have you covered. CKP additionally provides a great deal of solutions to meet your ongoing accounting necessities. Our reputation for expertise in cloud accounting and payroll services is well-established because our staff is well-equipped to support your company in handling the accounts payable (AP) and receivable (AR) functions. We continue to have many businesses as clients because of our excellent business advisory service.',
      'Apart from facilitating company valuation Malaysia, CKP also has the perfect skills to serve as your virtual Chief Financial Officer (CFO), allowing business formation and valuation to be completed at the same time. Such activities will put your business unit in significantly better shape. You can rely on the idea that all of your tax and human resource (HR) needs will be met by our skilled accountants.',
    ],
  },
  meta: {
    title: 'Company Valuation Service Malaysia - Chia, Ka & Partners',
    description:
      'Trust our precise company valuation services in Malaysia for strategic insights and informed choices. Optimize your business decisions with us today!',
  },
};

function CompanyValuation() {
  return <ServicesContent props={data} />;
}

export default CompanyValuation;
