import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    display: 'flex',
    overflowX: 'hidden',
    padding: '10px',
    gap: '5px',
  },
  card: {
    flex: '0 0 12.2%', // Adjust the default width of each card here
    /* Adjust the width of each card for mobile devices here */
    // Change the width of the card for mobile devices
    [theme.breakpoints.down('lg')]: {
      flex: '0 0 16.2%',
    },
    [theme.breakpoints.down('md')]: {
      flex: '0 0 25%',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 33.3%',
    },
  },
}));

function Carousel({ data }) {
  const classes = useStyles();
  const [scrollPosition, setScrollPosition] = useState(0);

  const [itemsPerPage, setItemsPerPage] = useState(8);

  useEffect(() => {
    const updateItemsPerPage = () => {
      // Set the number of items per page based on the screen width
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1280) {
        setItemsPerPage(8);
      } else if (screenWidth >= 960) {
        setItemsPerPage(6);
      } else if (screenWidth >= 600) {
        setItemsPerPage(4);
      } else {
        setItemsPerPage(3);
      }
    };

    // Call the update function once initially and add a resize event listener
    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
    };
  }, []);

  const handleScroll = (direction) => () => {
    const container = document.getElementById('carousel-container');
    const cardWidth = container?.firstChild?.offsetWidth;

    if (container && cardWidth) {
      const increment =
        direction === 'right' ? (cardWidth + 5) * itemsPerPage : (-cardWidth - 5) * itemsPerPage;
      setScrollPosition((prev) => prev + increment);
      container.scrollBy({ left: increment, behavior: 'smooth' });
    }
  };

  return (
    <div className="grid grid-cols-12 py-24 md:py-48 ">
      <div className="flex flex-row items-center justify-center col-start-1 md:col-start-2 col-span-12 md:col-span-10 gap-10 md:gap-20">
        <Button onClick={handleScroll('left')} className="col-span-1 !rounded-full">
          <img
            className="w-16 md:w-24"
            src="assets/images/pages/client/left-icon.png"
            alt="left-icon"
          />
        </Button>

        <div
          id="carousel-container"
          className={clsx(classes.carouselContainer, 'col-span-6')}
          style={{ scrollBehavior: 'smooth', scrollLeft: scrollPosition }}
        >
          {Array.from({ length: 16 }, (_, index) => (
            <div key={index} className={clsx(classes.card, 'flex flex-col items-center')}>
              <img
                className=""
                src={`assets/images/pages/client/clients/client-${index + 1}.png`}
                alt={`client-${index + 1}`}
              />
            </div>
          ))}
        </div>

        <Button onClick={handleScroll('right')} className="col-span-1 !rounded-full">
          <img
            className="w-16 md:w-24"
            src="assets/images/pages/client/right-icon.png"
            alt="right-icon"
          />
        </Button>
      </div>
    </div>
  );
}

export default Carousel;
