import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet-async';

import GetInTouch from '../../shared-components/GetInTouch';
import TheFounders from './components/TheFounders';
import TheTeam from './components/TheTeam';
import OurCulture from './components/OurCulture';
import JoinUs from './components/JoinUs';
import ForgetWhatYouKnow from './components/ForgetWhatYouKnow';

function Team() {
  return (
    <Box className="flex flex-col">
      <img className="w-full" src="assets/images/pages/team/team-header.png" alt="team-header" />
      <TheFounders />
      <TheTeam />
      <OurCulture />
      <JoinUs />
      <ForgetWhatYouKnow />
      <GetInTouch title="Drop Your Resume" />
      <Helmet>
        <title>Accounting Services Kuala Lumpur | Chia, Ka & Partners</title>
        <meta
          name="description"
          content="Chia, Ka & Partners provides professional accounting services in Kuala Lumpur with more than 20 years of experience in helping your business grow."
        />
      </Helmet>
    </Box>
  );
}

export default Team;
