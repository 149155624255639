import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Typography, Box } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    display: 'flex',
    overflowX: 'hidden',
    padding: '10px',
    gap: '10px',
  },
  card: {
    flex: '0 0 32%', // Adjust the default width of each card here
    /* Adjust the width of each card for mobile devices here */
    // Change the width of the card for mobile devices
    [theme.breakpoints.down('lg')]: {
      flex: '0 0 32%',
    },
    [theme.breakpoints.down('md')]: {
      flex: '0 0 50%',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 100%',
    },
  },
}));

function Carousel({ data }) {
  const classes = useStyles();
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (direction) => () => {
    const container = document.getElementById('carousel-container');
    const cardWidth = container?.firstChild?.offsetWidth;

    if (container && cardWidth) {
      const increment = direction === 'right' ? cardWidth + 10 : -cardWidth - 10;
      setScrollPosition((prev) => prev + increment);
      container.scrollBy({ left: increment, behavior: 'smooth' });
    }
  };

  return (
    <div className="grid grid-cols-12 items-start -ml-16 md:!-mx-48 py-24 md:py-48">
      <Button onClick={handleScroll('left')} className="!mt-32 col-span-1 !rounded-full">
        <img
          className="w-16 md:w-24"
          src="assets/images/pages/landing/left-icon.png"
          alt="left-icon"
        />
      </Button>

      <div
        id="carousel-container"
        className={clsx(classes.carouselContainer, 'col-start-2 col-span-10 ml-20 md:ml-0')}
        style={{ scrollBehavior: 'smooth', scrollLeft: scrollPosition }}
      >
        {data.map((item, index) => (
          <div key={index} className={clsx(classes.card, 'flex flex-col items-center')}>
            <Box className="bg-white rounded-full w-48 md:w-96 h-48 md:h-96 flex p-[0.9rem] md:p-[1.8rem] flex-col justify-center">
              <img className="" src={item?.src} alt="logo" />
            </Box>
            <Typography className="flex items-center justify-center text-white !font-900 !leading-[14px] md:!leading-[28px] !text-[12px] md:!text-[23px] !my-9 md:!my-16 md:h-48 md:w-10/12 !font-customBlack">
              {item.title}
            </Typography>
            <Typography className="text-white !font-400 !leading-[11px] md:!leading-[30px] !text-[10px] md:!text-[20px] md:w-10/12">
              {item.description}
            </Typography>
          </div>
        ))}
      </div>

      <Button onClick={handleScroll('right')} className="!mt-32 col-span-1 !rounded-full">
        <img
          className="w-16 md:w-24"
          src="assets/images/pages/landing/right-icon.png"
          alt="right-icon"
        />
      </Button>
    </div>
  );
}

export default Carousel;
