import { Typography } from '@mui/material';

function PrivacyPolicy() {
  return (
    <div
      className="flex flex-col justify-center space-y-32 px-48 md:px-92 py-24 md:py-48"
      style={{ color: '#1C2687' }}
    >
      <Typography
        variant="h1"
        className="pb-24 md:pb-48 !text-[20px] md:!text-[40px] !font-900 text-center !font-customBlack"
      >
        Privacy Policy
      </Typography>
      This Privacy Policy document contains types of information that are collected and recorded by
      Chia, Ka & Partners PLT and how we use it. If you have additional questions or require more
      information about our Privacy Policy, do not fhesitate to contact us. This Privacy Policy
      applies only to our online activities and is valid for visitors to our website with regard to
      the information that they shared and/or collected in Chia, Ka & Partners PLT. This policy is
      not applicable to any information collected offline or via channels other than this website.
      The following terminology applies to these Terms and Conditions, Privacy Statement and
      Disclaimer Notice, and all Agreements: “Client,” “You,” and “Your” refers to you, the person
      who logs on to this website and is compliant with the Company’s terms and conditions. “The
      Company,” “Ourselves,” “We,” “Our,” and “Us” refers to our Company. “Party,” “Parties,” or
      “Us” refers to both the Client and ourselves. All terms refer to the offer, acceptance, and
      consideration of payment necessary to undertake the process of our assistance to the Client in
      the most appropriate manner for the express purpose of meeting the Client’s needs in respect
      of the provision of the Company’s stated services, in accordance with and subject to,
      prevailing law of Netherlands. Any use of the above terminology or other words in the
      singular, plural, capitalization, and they or they are taken as interchangeable and,
      therefore, as referring to the same.
      <h2>CONSENT</h2>
      By using our website, you hereby consent to our Privacy Policy and agree to its terms.
      <h2>INFORMATION WE COLLECT</h2>
      The personal information that you are asked to provide, and the reasons why you are asked to
      provide it, will be made clear to you at the point we ask you to provide your personal
      information. If you contact us directly, we may receive additional information about you such
      as your name, email address, phone number, the contents of the message and/or attachments you
      may send us, and any other information you may choose to provide. When you register for an
      Account, we may ask for your contact information, including items such as name, company name,
      address, email address, and telephone number.
      <h2>HOW WE USE YOUR INFORMATION</h2>
      We use the information we collect in various ways, including to:
      <ol>
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>
          Communicate with you, either directly or through one of our partners, including for
          customer service, to provide you with updates and other information relating to the
          website, and for marketing and promotional purposes
        </li>
        <li>Send you emails</li>
        <li>Find and prevent fraud</li>{' '}
      </ol>
      <h2>LOG FILES</h2>
      We follow a standard procedure of using log files. These files log visitors when they visit
      websites. All hosting companies do this and are a part of hosting services’ analytics. The
      information collected by log files includes Internet Protocol (IP) addresses, browser type,
      Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the
      number of clicks. These are not linked to any information that is personally identifiable. The
      purpose of the information is for analyzing trends, administering the site, tracking users’
      movement on the website, and gathering demographic information.
      <h2>COOKIES AND WEB BEACONS</h2>
      Like any other website, The Company uses ‘cookies’. These cookies are used to store
      information including visitors’ preferences, and the pages on the website that the visitor
      accessed or visited. The information is used to optimize the users’ experience by customizing
      our web page content based on visitors' browser type and/or other information. For more
      general information on cookies, please read “What Are Cookies”.
      <h2>ADVERTISING PARTNERS PRIVACY POLICIES</h2>
      You may consult this list to find the Privacy Policy for each of the advertising partners of
      Chia, Ka & Partners PLT. Third-party ad servers or ad networks use technologies like cookies,
      JavaScript, or Web Beacons that are used in their respective advertisements and links that
      appear on The Company, which are sent directly to users’ browsers. They automatically receive
      your IP address when this occurs. These technologies are used to measure the effectiveness of
      their advertising campaigns and/or to personalize the advertising content that you see on
      websites that you visit. Note that The Company has no access to or control over these cookies
      that are used by third-party advertisers.
      <h2>THIRD-PARTY PRIVACY POLICIES</h2>
      The Company Privacy Policy does not apply to other advertisers or websites. Thus, we are
      advising you to consult the respective Privacy Policies of these third-party ad servers for
      more detailed information. It may include their practices and instructions about how to opt
      out of certain options. You can choose to disable cookies through your individual browser
      options. To know more detailed information about cookie management with specific web browsers,
      it can be found on the browsers’ respective websites
      <h2>CCPA PRIVACY RIGHTS</h2>
      (Do Not Sell My Personal Information) Under the CCPA, among other rights, consumers have the
      right to:
      <ol>
        <li>
          Request that a business that collects a consumer’s personal data disclose the categories
          and specific pieces of personal data that a business has collected about consumers.
        </li>{' '}
        <li>
          Request that a business deletes any personal data about the consumer that a business has
          collected.
        </li>
        <li>
          Request that a business that sells a consumer’s personal data, not sell the consumer’s
          personal data.
        </li>{' '}
      </ol>
      If you make a request, we have one month to respond to you. If you would like to exercise any
      of these rights, please contact us.
      <h2>GDPR DATA PROTECTION RIGHTS</h2>
      We would like to make sure you are fully aware of all of your data protection rights. Every
      user is entitled to the following:
      <ol>
        <li>
          The right to access – You have the right to request copies of your personal data. We may
          charge you a small fee for this service.
        </li>
        <li>
          The right to rectification – You have the right to request that we correct any information
          you believe is inaccurate. You also have the right to request that we complete the
          information you believe is incomplete.
        </li>
        <li>
          The right to erasure – You have the right to request that we erase your personal data,
          under certain conditions.
        </li>
        <li>
          The right to restrict processing – You have the right to request that we restrict the
          processing of your personal data, under certain conditions.
        </li>

        <li>
          The right to object to processing – You have the right to object to our processing of your
          personal data, under certain conditions.
        </li>
        <li>
          The right to data portability – You have the right to request that we transfer the data
          that we have collected to another organization, or directly to you, under certain
          conditions.
        </li>
      </ol>
      If you make a request, we have one month to respond to you. If you would like to exercise any
      of these rights, please contact us.
      <h2>CHILDREN’S INFORMATION</h2>
      Another part of our priority is adding protection for children while using the internet. We
      encourage parents and guardians to observe, participate in, and/or monitor and guide their
      online activity. The Company does not knowingly collect any Personal Identifiable Information
      from children under the age of 13. If you think that your child provided this kind of
      information on our website, we strongly encourage you to contact us immediately and we will do
      our best efforts to promptly remove such information from our records.
    </div>
  );
}

export default PrivacyPolicy;
