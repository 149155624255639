import { Box, Hidden, Typography } from '@mui/material';
import BgRedButton from '../../../shared-components/BgRedButton';

const imgData = [
  {
    srcTitle: 'how-it-works-1',
  },
  {
    srcTitle: 'how-it-works-2',
  },
  {
    srcTitle: 'how-it-works-3',
  },
];

const titleData = [
  {
    steps: '1.',
    title: 'Install Xero',
  },
  { steps: '2.', title: 'Migrate to digital' },
  { steps: '3.', title: 'Never worry about accounting ever again' },
];

const textData = [
  {
    text: 'When you sign on with us, you will be given a subscription to Xero. Xero is a cloud-based Ai Accounting system that will automate all your accounting processes.',
    text1: `Need to set a recurring invoice? Want to automatically make orders when stocks or materials are low? We'll provide all the training you need to get started and get the most out of Xero.`,
  },
  {
    text: 'Say goodbye to paperwork once and for all. Simply scan or photograph all your existing documents and upload them to us. We will organise and verify, making sure everything is in its proper place.',
    text1: `Xero takes over from here by analysing each document and compiling all the information and presenting it in a way that gives you true insight into your company's financial status.`,
  },
  {
    text: 'Once Xero is set up and running all your accounting processes become automatic.',
    text1: `As your business grows and your needs change, Xero can grow along with you, and CKP will be there to support entrepreneurial dreams and build bold.`,
  },
];

function HowItWorks() {
  const renderImageData = (props, index) => {
    return (
      <div key={index} className="flex flex-row justify-center">
        <img src={`assets/images/pages/landing/${props.srcTitle}.png`} alt={props.srcTitle} />
      </div>
    );
  };

  const renderTitleData = (props, index) => {
    return (
      <div key={index} className="flex flex-col items-center">
        <Typography className="!text-[20px] md:!text-[40px] !font-900 !leading-[17px] md:!leading-[34px] !font-customBlack">
          {props.steps}
        </Typography>
        <Typography className="!text-[12px] md:!text-[25px] !font-900 !leading-[17px] md:!leading-[34px]">
          {props.title}
        </Typography>
      </div>
    );
  };

  const renderTextData = (props, index) => {
    return (
      <Box key={index} className="space-y-10 md:space-y-20">
        <Typography className="!text-[10px] md:!text-[20px] !font-400 !leading-[11px] md:!leading-[22px]">
          {props.text}
        </Typography>
        <Typography className="!text-[10px] md:!text-[20px] !font-400 !leading-[11px] md:!leading-[22px]">
          {props.text1}
        </Typography>
      </Box>
    );
  };

  return (
    <div
      className="grid grid-cols-12 items-center text-center py-32 md:py-60"
      style={{ color: '#1C2687' }}
    >
      <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8">
        <Typography className="!text-[29px] md:!text-[58px] !font-900 !font-customBlack">
          How it works
        </Typography>
        <h1 className="!text-[12px] md:!text-[23px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          Experience a seamless transition to CKP's Ai accounting method in just 3 simple steps and
          bid farewell to all the hassles of the accounting process with the leading
          <i> accounting firm in KL Sentral</i>.
        </h1>

        {/* Desktop Version */}
        <Hidden mdDown>
          <div className="grid grid-cols-3 justify-center mt-72 gap-x-40 gap-y-20">
            {imgData.map((item, index) => {
              return renderImageData(item, index);
            })}
            {titleData.map((item, index) => {
              return renderTitleData(item, index);
            })}
            {textData.map((item, index) => {
              return renderTextData(item, index);
            })}
          </div>
        </Hidden>
        {/* Desktop Version */}

        {/* Mobile Version */}
        <Hidden mdUp>
          {Array.from({ length: 3 }, (_, index) => (
            <div className="flex flex-col gap-y-20 items-center justify-center mt-28" key={index}>
              <img
                src={`assets/images/pages/landing/${imgData[index].srcTitle}.png`}
                alt={imgData[index].srcTitle}
                className="w-48 h-48"
              />
              <div key={index} className="flex flex-col items-center">
                <Typography className="!text-[20px] md:!text-[40px] !font-900 !leading-[17px] md:!leading-[34px] !mb-10">
                  {titleData[index].steps} {titleData[index].title}
                </Typography>
                {renderTextData(textData[index])}
              </div>
            </div>
          ))}
        </Hidden>
        {/* Mobile Version */}
        <div className="mt-28 md:mt-60">
          <BgRedButton title="Click to get started" destination="/ai-accounting" />
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
