import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

// app
import emailjs from '@emailjs/browser';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import AiAccounting from './app/main/AiAccounting';
import Blog from './app/main/Blog';
import BlogContent from './app/main/Blog/components/BlogContent';
import Client from './app/main/Client';
import ContactUs from './app/main/ContactUs';
import Landing from './app/main/Landing';
import Pricing from './app/main/Pricing';
import PrivacyPolicy from './app/main/PrivacyPolicy';
import Services from './app/main/Services';
import AccountingAndBookkeeping from './app/main/Services/pages/AccountingAndBookkeeping';
import BusinessAdvisoryServices from './app/main/Services/pages/BusinessAdvisoryServices';
import CompanyIncorporationServices from './app/main/Services/pages/CompanyIncorporationServices';
import CompanyValuation from './app/main/Services/pages/CompanyValuation';
import SecretarialFirm from './app/main/Services/pages/SecretarialFirm';
import ServicesHome from './app/main/Services/pages/ServicesHome';
import Team from './app/main/Team';
import TermsAndConditions from './app/main/TermsAndConditions';
import Footer from './app/shared-components/Footer';
import Navbar from './app/shared-components/Navbar/Navbar';

// Create your Own theme:
const theme = createTheme({
  palette: {
    primary: {
      main: '#1C2687',
    },
  },
  typography: {
    fontFamily: 'Gotham, sans-serif',
  },
});

function App() {
  useEffect(() => {
    emailjs.init({
      publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
    });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Router>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{
              containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
            }}
          >
            <Navbar />
            <Routes>
              <Route path="*" element={<Navigate to="/home" />} />
              <Route path="/home" element={<Landing />} />
              <Route path="/ai-accounting" element={<AiAccounting />} />
              <Route path="/services" element={<Services />}>
                <Route index element={<ServicesHome />} />
                <Route path="accounting-and-bookkeeping" element={<AccountingAndBookkeeping />} />
                <Route path="company-incorporation" element={<CompanyIncorporationServices />} />
                <Route path="secretarial-services" element={<SecretarialFirm />} />
                <Route path="business-advisory" element={<BusinessAdvisoryServices />} />
                <Route path="company-valuation" element={<CompanyValuation />} />
              </Route>

              <Route path="/team" element={<Team />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/client" element={<Client />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/content" element={<BlogContent />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            </Routes>
            <Footer />
          </SnackbarProvider>
        </Router>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
