/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Hidden, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import postContactUs from '../../../services/email';

function ContactUs(props) {
  const [email, setEmail] = useState('');

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const onSubmit = async () => {
    try {
      await postContactUs({ email }, 'Footer Mailbox');
      setEmail('');
      return enqueueSnackbar('Email Sent', { variant: 'success' });
    } catch (err) {
      console.error(err);
      return enqueueSnackbar('Something Went Wrong', { variant: 'error' });
    }
  };

  return (
    <div className="flex flex-col text-white">
      <Typography className="!font-customBlack !font-900 !text-[16px] md:!text-[22px] !mb-10 md:!mb-20 lg:!mb-28">
        Contact Us
      </Typography>
      <Typography className="!font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]">
        Address: 16-03A Level 16, Menara MBMR 1, Jalan Syed Putra 58000 Malaysia.
      </Typography>
      <Typography className="!font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px] !mt-14 md:!mt-28">
        Phone Office: <a href="tel:+603-9212-7856">+603-9212 7856</a>
      </Typography>
      <Typography className="!font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]">
        WhatsApp:{' '}
        <a href="https://wa.me/60129853980" target="_blank" rel="noopener noreferrer">
          +6012-985 3980
        </a>
      </Typography>
      <Typography className="!font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]">
        Email: <a href="mailto:enquiry@ckpartners.com.my">enquiry@ckpartners.com.my</a>
      </Typography>

      {/* Desktop Version */}
      <Hidden mdDown>
        <div className="flex flex-row items-end full !mt-14 md:!mt-28">
          <TextField
            fullWidth
            className="bg-white"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleChange}
          />
          <Box className="bg-[#F4064F] py-11 px-10" onClick={onSubmit}>
            <img
              className="hover:cursor-pointer"
              src="assets/images/logo/send-icon.png"
              alt="logo"
            />
          </Box>
        </div>
      </Hidden>
      {/* Desktop Version */}
    </div>
  );
}

export default ContactUs;
