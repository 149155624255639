import {
  Hidden,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Dialog,
  DialogContent,
} from '@mui/material';
import { useState } from 'react';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';

const data = [
  {
    srcTitle: 'taxation-services',
    title: 'Taxation Services',
    subtitle: 'Pay no more than you should',
    description: `We offer comprehensive taxation services, including GST registration, tax calculations, and submission. We develop tailored solutions to maximize tax benefits and minimize liabilities, ensuring compliance with regulations and helping clients achieve financial goals.`,
  },
  {
    srcTitle: 'hr-services',
    title: 'HR Services',
    subtitle: `Human Potential`,
    description: `With labour being one of the most significant expenses for most businesses, making informed HR decisions is critical. Our comprehensive HR solutions, including accurate record-keeping, and digitalized processes that maximize the potential of your human resources.`,
  },
  {
    srcTitle: 'virtual-cfo',
    title: 'Virtual CFO',
    subtitle: `Navigate financial complexities with confidence`,
    description: `Experience expert financial guidance without the need for a full-time Chief Financial Officer. Our virtual CFOs are here to empower your organization with invaluable insights and strategic direction, optimizing your financial performance every step of the way. Whether it's financial planning, analysis, or cash flow management, our dedicated team will work closely with you, ensuring that you receive personalized attention and expert advice tailored to your unique needs.`,
  },
  {
    srcTitle: 'business-valuation',
    title: 'Business valuation',
    subtitle: `Measuring your true value`,
    description: `Whether you're looking to sell, merge, secure funding, or simply gain a deeper understanding of your business's financial position, our Business Valuation service equips you with the knowledge and confidence to make informed decisions and build bold.`,
  },
];

function Section3() {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

  const [anchorEl, setAnchorEl] = useState(null);

  const [open, setOpen] = useState(false);
  const [popUpState, setPopUpState] = useState(0);

  const handleOpen = (state) => {
    setOpen(true);
    setPopUpState(state);
  };
  const handleClose = () => setOpen(false);

  // // to set the email popover width
  // let popoverWidth = '60%';
  // if (isSmallScreen) {
  //   popoverWidth = '100%';
  // }
  // if (isMediumScreen) {
  //   popoverWidth = '60%';
  // }
  // if (isXLargeScreen) {
  //   popoverWidth = '40%';
  // }
  // if (isLargeScreen) {
  //   popoverWidth = '30%';
  // }

  const renderData = (props, withButton) => {
    return (
      <div className="flex flex-col mt-10 md:mt-0 w-full md:w-9/12">
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !leading-[30px] md:!leading-[45px]">
          {props.title}
        </Typography>
        <Typography
          className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !leading-[20px] md:!leading-[40px]"
          style={{ color: '#F5054F' }}
        >
          {props.subtitle}
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-5 md:!mt-10 !leading-[14px] md:!leading-[28px]">
          {props.description}
        </Typography>
        {withButton && (
          <div className="mt-24">
            <Button
              aria-describedby="emailInput"
              className="!normal-case !px-10 md:!px-20 !py-3 md:!py-6 !text-[12px] md:!text-[20px] !leading-[28px] !font-700 !border-1 md:!border-2 !rounded-full"
              variant={open ? 'contained' : 'outlined'}
              onClick={handleOpen}
              style={{
                color: `${open ? '' : '#F5054F'}`,
                borderColor: '#F5054F',
                backgroundColor: `${open ? '#F5054F' : ''}`,
              }}
            >
              {/* PopOver Email Input */}
              <div className="flex flex-row items-center">
                {!anchorEl && <ArrowDropDownOutlinedIcon fontSize="large" />}
                {anchorEl && <ArrowDropUpOutlinedIcon fontSize="large" />}
                Free Valuation Report
              </div>
            </Button>
            {/* <Popover
              id="emailInput"
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              slotProps={{
                paper: {
                  style: {
                    // width: popoverWidth,
                    borderRadius: '20px',
                    marginTop: '5px',
                  },
                },
              }}
            >
              <iframe
                title="ckp-parnters"
                src="https://my.valutico.com/questionnaire/ck-partners"
                width="760px"
                height="600px"
              />
            </Popover> */}
            {/* PopOver Email Input */}
          </div>
        )}
      </div>
    );
  };

  const renderImage = (props, position) => {
    return (
      <div
        className={`flex justify-center ${
          position === 'right' ? 'ml-0 md:ml-48' : 'mr-0 md:mr-48'
        }`}
      >
        <img
          className="rounded-md"
          src={`assets/images/pages/services/${props.srcTitle}.png`}
          alt={props.srcTitle}
        />
      </div>
    );
  };

  return (
    <div className="grid grid-cols-12 py-36 md:py-72" style={{ color: '#1C2687' }}>
      <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8 space-y-[4rem] md:space-y-[8rem]">
        <div className="flex flex-col md:flex-row items-center justify-center">
          {renderImage(data[0], 'left')}
          {renderData(data[0])}
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center">
          {/* Desktop Version */}
          <Hidden mdDown>
            {renderData(data[1])}
            {renderImage(data[1], 'right')}
          </Hidden>
          {/* Desktop Version */}

          {/* Mobile Version */}
          <Hidden mdUp>
            {renderImage(data[1], 'right')}
            {renderData(data[1])}
          </Hidden>
          {/* Mobile Version */}
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center">
          {renderImage(data[2], 'left')}
          {renderData(data[2])}
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center">
          {/* Desktop Version */}
          <Hidden mdDown>
            {renderData(data[3], true)}
            {renderImage(data[3], 'right')}
          </Hidden>
          {/* Desktop Version */}

          {/* Mobile Version */}
          <Hidden mdUp>
            {renderImage(data[3], 'right')}
            {renderData(data[3], true)}
          </Hidden>
          {/* Mobile Version */}
        </div>
        <Typography className="!font-customBlack !text-[25px] md:!text-[50px] !font-900 !mt-[6rem] md:!mt-[12rem] text-center !-mb-24 md:!-mb-48">
          Let's grow your business together.
        </Typography>
      </div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              maxWidth: '760px',
            },
          },
        }}
      >
        <DialogContent className="flex justify-center !p-0">
          <iframe
            title="ckp-parnters"
            src="https://my.valutico.com/questionnaire/ck-partners"
            width="760px"
            height="600px"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Section3;
