import { Outlet } from 'react-router-dom';

function Services() {
  return (
    <div>
      <img
        className="w-full"
        src="assets/images/pages/services/services-header.png"
        alt="services-header"
      />
      <Outlet />
    </div>
  );
}

export default Services;
