import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet-async';
import Section1 from '../components/Section1';
import Section2 from '../components/Section2';
import Section3 from '../components/Section3';
import GetInTouch from '../../../shared-components/GetInTouch';

function ServicesHome() {
  return (
    <Box className="flex flex-col">
      <Section1 />
      <Section2 />
      <Section3 />
      <GetInTouch />

      <Helmet>
        <title>Payroll Outsourcing Malaysia & Accounting | Chia, Ka & Partners</title>
        <meta
          name="description"
          content="Chia, Ka & Partners provides payroll outsourcing services in Malaysia. We help your business manage all payroll, leaves, salaries, submissions, etc. Contact us!"
        />
      </Helmet>
    </Box>
  );
}

export default ServicesHome;
