import { Hidden, Typography } from '@mui/material';

const data = [
  {
    srcTitle: 'what-we-do',
    title: 'What We',
    redTitle: 'Do?',
    description: `As a certified Xero partner in Malaysia, we are privileged to digitalise and transform your business accounting system and processes: 
• Attach source documents to just about anything in Xero
Malaysia, including invoices and bills.
• Traditional accounting paperwork will save you more than 90% of your time.
• Cost-effective for new and small businesses.`,
  },
  {
    srcTitle: 'why-choose-us',
    title: 'Why',
    redTitle: 'Choose Us?',
    description: `In Malaysia, we are an authorised dealer of Xero Accounting Software. Implementing an accounting system in a business without regionalised accounting knowledge is a well-known challenge. We will assist you on your journey to business digitalisation if you choose us as your Xero partner Malaysia . There are numerous advantages to using Cloud Accounting Software, the most important of which is that it reduces human manual workload by allowing auto communication between them. For example, after integrating your POS system with Cloud Accounting Software like Xero, you no longer need to do manual data entry.`,
  },
  {
    srcTitle: 'why-use-xero',
    title: 'Why',
    redTitle: 'use Xero?',
    description: `Xero Malaysia is a cloud-based accounting software that offers a one-stop accounting solution at the tip of your finger. From bookkeeping to sales invoice creation, purchase orders, business financial analysis, and many other features improve your company's performance. Using Xero in Malaysia will save you more than 90% of your time from conventional accounting paperwork. Most importantly, using Xero in Malaysia is affordable for small and medium-sized businesses. Since you can access your business data from anywhere and at any time using a laptop and a mobile app. As a result, the lack of efficiency with the Xero accounting system will no longer be an excuse. Xero is trusted by small businesses, accountants, and bookkeepers both locally and globally. Xero has seen over two million+ subscribers worldwide and collaboration with at least seven banks in Malaysia. If P&C data is not secured, the bank will not allow it to be shared with a third party.`,
  },
];

function LastSection() {
  const renderData = (props, withButton) => {
    return (
      <div className="flex flex-col mt-10 md:mt-0 w-full md:w-9/12">
        <Typography className="!text-[16px] md:!text-[35px] !font-700 !leading-[14px] md:!leading-[28px]">
          {props.title}
          <span style={{ color: '#F5054F' }}>&nbsp;{props.redTitle}</span>
        </Typography>

        <Typography className="!text-[12px] md:!text-[25px] !font-400 !mt-5 md:!mt-10 !leading-[14px] md:!leading-[28px]">
          {props.description}
        </Typography>
      </div>
    );
  };

  const renderImage = (props, position, isRoundBorder) => {
    return (
      <div
        className={`flex justify-center ${
          position === 'right' ? 'ml-0 md:ml-48' : 'mr-0 md:mr-48'
        } ${isRoundBorder ? 'bg-[#F5054F] rounded-full p-10 md:p-20' : ''}`}
      >
        {isRoundBorder && (
          <img
            className="w-48 h-48 md:w-[12rem] md:h-[12rem]"
            src={`assets/images/pages/ai-accounting/${props.srcTitle}.png`}
            alt={props.srcTitle}
          />
        )}
        {!isRoundBorder && (
          <img
            src={`assets/images/pages/ai-accounting/${props.srcTitle}.png`}
            alt={props.srcTitle}
          />
        )}
      </div>
    );
  };

  return (
    <div className="grid grid-cols-12 py-36 md:py-72" style={{ color: '#1C2687' }}>
      <div className="col-start-2 lg:col-start-2 col-span-10 lg:col-span-10 space-y-[4rem] md:space-y-[8rem]">
        <div className="flex flex-col md:flex-row items-center">
          {/* Desktop Version */}
          <Hidden mdDown>
            {renderData(data[0])}
            {renderImage(data[0], 'right')}
          </Hidden>
          {/* Desktop Version */}
          {/* Mobile Version */}
          <Hidden mdUp>
            {renderImage(data[0], 'left')}
            {renderData(data[0])}
          </Hidden>
          {/* Mobile Version */}
        </div>
        <div className="flex flex-col md:flex-row items-center text-end">
          {renderImage(data[1], 'left')}
          {renderData(data[1])}
        </div>
        <div className="flex flex-col md:flex-row items-center">
          {/* Desktop Version */}
          <Hidden mdDown>
            {renderData(data[2])}
            {renderImage(data[2], 'right', true)}
          </Hidden>
          {/* Desktop Version */}
          {/* Mobile Version */}
          <Hidden mdUp>
            {renderImage(data[2], 'left', true)}
            {renderData(data[2])}
          </Hidden>
          {/* Mobile Version */}
        </div>
      </div>
    </div>
  );
}

export default LastSection;
