import { Typography, Box, Divider, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';

const ImageWithFixedText = styled('div')(({ theme }) => ({
  position: 'relative',
  maxWidth: '100%',
  height: 'auto',

  '& img': {
    width: '100%',
    height: 'auto',
    display: 'block',
  },

  '.fixed-text': {
    position: 'absolute',
    top: '70%',
    left: '63%',
    transform: 'translate(-50%, -50%)',
  },
}));

const data = [
  {
    title1: 'Micro',
    title2: 'SME',
    title3: 'Package',
    title4: null,
    price: 'RM169',
    description: 'Perfect for start ups and Solo Entrepreneurs',
    blueBg: false,
  },
  {
    title1: 'Accounting',
    title2: 'Services',
    title3: 'Package',
    title4: null,
    price: 'RM899',
    description: `Made for SME's and companies with Big Dreams`,
    blueBg: true,
  },
  {
    title1: 'Payroll',
    title2: 'Services',
    title3: 'Package',
    title4: null,
    price: 'RM250',
    description: 'Never deal with payroll again',
    blueBg: false,
  },
  {
    title1: 'AP',
    title2: 'Management',
    title3: 'Services',
    title4: 'Package',
    price: 'RM299',
    description: 'Ensure efficient and effective use of your resources.',
    blueBg: true,
  },
];

function Pricing() {
  const renderBorderText = (props, index) => {
    // adjust the margin for last card here due to different height (setting with props.title4)
    return (
      <div
        key={index}
        className={`relative mx-auto mt-10 md:mt-0 ${
          props?.title4
            ? 'w-96 md:w-[18rem] lg:w-[20rem] h-72 md:h-[13rem] lg:h-[14rem] !-mt-2 md:!-mt-28'
            : 'w-96 md:w-[16rem] lg:w-[17rem] h-60 md:h-[11rem] lg:h-[11rem]'
        }`}
      >
        <div className="absolute inset-0 rounded-[8px] border-3 border-[#FF1754] transform rotate-[5.939deg]" />
        <div
          className={`absolute inset-0 rounded-[8px] ${
            props.blueBg ? 'bg-[#1C2687]' : 'bg-[#F5054F]'
          } transform -rotate-[7.425deg]`}
        />
        <div className="absolute inset-0 flex flex-col justify-center items-start text-white mt-8 md:mt-10">
          <Typography className="!font-customBlack !text-[23px] md:!text-[45px]  !font-500 !leading-[24px] md:!leading-[48px]">
            {props.title1}
          </Typography>
          <Typography className="!font-customBlack !text-[23px] md:!text-[45px] !font-500 !leading-[24px] md:!leading-[48px]">
            {props.title2}
          </Typography>
          <Typography className="!font-customBlack !text-[23px] md:!text-[45px] !font-500 !leading-[24px] md:!leading-[48px]">
            {props.title3}
          </Typography>
          <Typography className="!font-customBlack !text-[23px] md:!text-[45px] !font-500 !leading-[24px] md:!leading-[48px]">
            {props?.title4}
          </Typography>
        </div>
        <div
          className={`flex flex-col text-left ${
            props?.title4
              ? 'mt-[9rem] md:mt-[17rem] lg:mt-[19.5rem]'
              : 'mt-[8rem] md:mt-[14rem] lg:mt-[17rem]'
          }`}
        >
          <Typography className="!font-customBlack !text-[12px] md:!text-[22px] !font-700 !leading-[12px] md:!leading-[24px]">
            From <span style={{ color: '#F4064F' }}>{props.price}</span> per month
          </Typography>
          <Typography className="!text-[10px] md:!text-[20px] !font-400 !mt-2 !leading-[14px] md:!leading-[22px]">
            {props.description}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <div
      className="grid grid-cols-12 text-center pt-10 md:pt-0 pb-36 md:pb-72"
      style={{ color: '#1C2687' }}
    >
      <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8">
        <div className="grid grid-rows-1 md:grid-rows-4 grid-cols-12 gap-4">
          <div className="md:row-start-2 col-start-5 md:col-start-1 col-span-5 md:row-span-3 relative">
            <img src="assets/images/pages/landing/pricing.png" alt="pricing" />
          </div>

          <div className="md:row-start-3 md:row-span-2 col-start-1 md:col-start-6 col-span-12 md:col-span-6">
            <Typography className="!text-[29px] md:!text-[58px] !font-900 !font-customBlack">
              Pricing
            </Typography>
            <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
              Whether you are a micro SME or a multinational, the benefits of using CKP is the same.
              Efficient, accurate, and compliant accounting systems that act autonomously in the
              background saving you time and money.
            </Typography>
          </div>
        </div>
      </div>
      <div className="col-start-2 col-span-10">
        {/* Desktop Version */}
        <Hidden lgDown>
          <div className="grid grid-cols-4 mt-48 md:mt-96 gap-10">
            {data.map((item, index) => {
              return renderBorderText(item, index);
            })}
          </div>
        </Hidden>
        {/* Desktop Version */}
        {/* Tablet - Laptop version */}
        <Hidden smDown lgUp>
          <div className="grid grid-cols-2 mt-60 md:mt-96 gap-10">
            {renderBorderText(data[0])}
            {renderBorderText(data[1])}
          </div>
          <div className="grid grid-cols-2 mt-60 md:mt-[13rem] gap-10">
            {renderBorderText(data[2])}
            {renderBorderText(data[3])}
          </div>
        </Hidden>
        {/* Tablet - Laptop version */}
        {/* Mobile Version */}
        <Hidden smUp>
          <div className="grid grid-cols-1 mt-28 gap-10">{renderBorderText(data[0])}</div>
          <div className="grid grid-cols-1 mt-60 gap-10">{renderBorderText(data[1])}</div>
          <div className="grid grid-cols-1 mt-60 gap-10">{renderBorderText(data[2])}</div>
          <div className="grid grid-cols-1 mt-60 gap-10">{renderBorderText(data[3])}</div>
        </Hidden>
        {/* Mobile Version */}
        <div className="flex flex-col items-center justify-center mt-[8rem] md:mt-[15rem]">
          <Divider className="w-3/12 py-1 md:py-2 !rounded-[20px] !bg-[#D9D9D9]" />
          <Box className="!bg-[#F4064F] w-full md:w-10/12 px-24 md:px-48 py-16 md:py-32 text-center rounded-[20px] md:rounded-[60px] lg:rounded-full mt-[4rem] md:mt-[8rem] text-white">
            <Typography className="!font-900 !text[-15px] md:!text-[30px] !leading-[19px] md:!leading-[38px]">
              We love numbers and we love technology.
            </Typography>
            <Typography className="!font-700 !text[-15px] md:!text-[30px] !leading-[19px] md:!leading-[38px]">
              Using technology, we find the story behind the numbers and empower entrepreneurs with
              data they can use as a tool for business growth.
            </Typography>
          </Box>
        </div>
        <Typography className="!font-customBlack !text-[25px] md:!text-[50px] !font-900 !mt-[5rem] md:!mt-[18rem] text-center !-mb-24 md:!-mb-48">
          Let's grow your business together.
        </Typography>
      </div>
    </div>
  );
}

export default Pricing;
