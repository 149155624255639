import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import Link from '@mui/material/Link';
import ContactUsForm from './components/ContactUsForm';

function ContactUs() {
  return (
    <>
      <Box className="grid grid-cols-12 pb-28 md:pb-60 w-full bg-[#F4064F]">
        <div className="col-start-2 col-span-5">
          <div className="flex flex-col">
            <Typography className="!font-customBlack text-white !text-[50px] md:!text-[100px] !font-[900] !tracking-tight !leading-none">
              Get
            </Typography>
            <Typography className="!font-customBlack text-white !text-[50px] md:!text-[100px] !font-[900] !tracking-tight !leading-none">
              In Touch
            </Typography>
          </div>
        </div>
        <div className="col-start-7 col-span-6 col-end-12">
          <div className="flex flex-col items-end justify-end">
            <div className="flex flex-col mt-72 space-y-6 md:space-y-12">
              <img
                className="w-28 md:w-60 self-end mr-20 md:mr-40"
                src="assets/images/pages/contact-us/Isolation_Mode.png"
                alt="logo"
              />
              <Typography
                color="primary"
                className="!font-customBlack whitespace-nowrap !text-[50px] md:!text-[100px] !font-[900] !tracking-tight !leading-none"
              >
                with us
              </Typography>
            </div>
          </div>
        </div>
      </Box>

      <Box
        className="grid grid-cols-12 mt-[7rem] md:mt-[14rem] py-[3rem] md:py-[6rem]"
        sx={{
          backgroundImage: "url('assets/images/pages/contact-us/Vector.png')",
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
        }}
      >
        <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8">
          <div className="grid grid-cols-1 md:grid-cols-12">
            <div className="col-start-1 col-span-12 md:col-span-5">
              <div className="flex flex-col items-center justify-center">
                <img
                  className="pb-28 md:pb-56"
                  src="assets/images/pages/contact-us/Objects.png"
                  alt="logo"
                />
                <div className="flex flex-row w-full items-center md:items-start md:w-3/5">
                  <LocationOnIcon
                    style={{ color: '#F5054F' }}
                    className="!text-[1.8rem] md:!text-[2.3rem] mr-8 md:mr-16"
                  />
                  <div className="flex flex-col">
                    <Typography className="!text-[20px] !font-700 !leading-[35px] mb-3 md:!mb-6">
                      LOCATION ADDRESS
                    </Typography>
                    <Typography className="!font-700 !text-[14px] !leading-[20px]">
                      Chia, Ka & Partners PLT (CKP)
                    </Typography>
                    <Typography className="!font-400 !text-[14px] !leading-[20px]">
                      16-03A Level 16 Menara MBMR 1, Jalan Syed Putra 58000 Malaysia
                    </Typography>
                  </div>
                </div>
                <div className="flex flex-row w-full items-center md:items-start md:w-3/5 mt-32">
                  <PhoneIcon
                    style={{ color: '#F5054F' }}
                    fontSize="large"
                    className="!text-[1.8rem] md:!text-[2.3rem] mr-8 md:mr-16"
                  />
                  <div className="flex flex-col">
                    <Typography className="!text-[20px] !font-700 !leading-[35px] mb-3 md:!mb-6">
                      CONTACT NUMBER
                    </Typography>
                    <Typography className="!font-400 !text-[14px] !leading-[20px]">
                      Office:&nbsp;
                      <Link href="tel:+60392127856" underline="always" color="inherit">
                        +603-9212 7856
                      </Link>
                    </Typography>
                    <Typography className="!font-400 !text-[14px] !leading-[20px]">
                      WhatsApp:&nbsp;
                      <Link
                        href="https://wa.me/60129853980"
                        target="_blank"
                        underline="always"
                        color="inherit"
                      >
                        +6012-985 3980
                      </Link>
                    </Typography>
                  </div>
                </div>
                <div className="flex flex-row w-full items-center md:items-start md:w-3/5 mt-32">
                  <MailIcon
                    style={{ color: '#F5054F' }}
                    fontSize="large"
                    className="!text-[1.8rem] md:!text-[2.3rem] mr-8 md:mr-16"
                  />
                  <div className="flex flex-col">
                    <Typography className="!text-[20px] !font-700 !leading-[35px] mb-3 md:!mb-6">
                      EMAIL ADDRESS
                    </Typography>
                    <Typography className="!font-400 !text-[14px] !leading-[20px]">
                      <Link
                        href="mailto:enquiry@ckpartners.com.my"
                        underline="always"
                        color="inherit"
                      >
                        enquiry@ckpartners.com.my
                      </Link>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-start-1 md:col-start-6 md:col-span-7 mt-32 md:mt-0">
              <ContactUsForm />
            </div>
          </div>
        </div>
      </Box>
    </>
  );
}

export default ContactUs;
