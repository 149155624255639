import { Box, Button, Typography } from '@mui/material';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

function PricingCard({ props }) {
  const {
    borderColor,
    price,
    term,
    plan,
    description,
    extraDescription,
    bonus,
    buttonTitle,
    marginOff,
  } = props;

  return (
    <Box
      className={clsx(
        'rounded-[20px] border-[5px] md:border-[10px] flex flex-col py-16 md:py-36 px-14 md:px-28 text-center text-black bg-white',
        borderColor === 'blue' ? 'border-[#1C2687]' : 'border-[#F5054F]',
        !marginOff && borderColor === 'red' ? 'lg:-mb-60 lg:mt-32' : ''
      )}
    >
      <div className="flex flex-col items-center rounded-[18px]">
        <Typography className="!text-[15px] md:!text-[30px] !font-700 !leading-[16px] md:!leading-[33px]">
          {price}
        </Typography>
        <Typography className="!text-[10px] md:!text-[20px] !font-400 !mt-2 md:!mt-4 !leading-[16px] md:!leading-[33px]">
          {term}
        </Typography>

        {plan?.map((item, index) => {
          return (
            <Typography
              key={index}
              className="!text-[12px] md:!text-[25px] !font-700 !leading-[16px] md:!leading-[33px] pt-10"
            >
              {item}
            </Typography>
          );
        })}
        {extraDescription && (
          <div className="pt-16 flex flex-col justify-center items-center">
            <Box className="rounded-[20px] bg-[#F5054F] w-full py-1" />
            <Typography
              className="py-10 !font-customBlack !text-[12px] md:!text-[16px] !font-700 !leading-[16px] md:!leading-[33px]"
              style={{ color: '#1C2687' }}
            >
              {extraDescription}
            </Typography>
            <Box className="rounded-[20px] bg-[#F5054F] w-full py-1" />
          </div>
        )}
      </div>
      <div className="flex flex-col mt-20 md:mt-40 sm:text-start">
        {description?.map((item, index) => {
          return (
            <Typography
              key={index}
              className="!text-[8px] md:!text-[12px] !font-400 !leading-[12px] md:!leading-[18px]"
            >
              {item}
            </Typography>
          );
        })}
      </div>

      <div className="flex flex-col items-center justify-center mt-10 md:mt-20">
        <ul style={{ listStyleType: 'disc' }}>
          {bonus?.map((item, index) => {
            return (
              <li key={index} className="flex flex-row items-center">
                <span
                  style={{ marginRight: '8px' }} // Optional spacing between bullet and text
                >
                  •
                </span>
                <Typography
                  className="!text-[8px] md:!text-[12px] !font-700 !leading-[10px] md:!leading-[16px] !tracking-wider	"
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              </li>
            );
          })}
        </ul>
      </div>

      <div className="flex flex-row justify-center pt-20 md:pt-40 mt-auto">
        <Button
          className="whitespace-nowrap !normal-case px-10 md:!px-20 !text-[8px] md:!text-[14px] !font-700 !rounded-[6px] !bg-[#F4064F]"
          variant="contained"
          component={Link}
          to="mailto:enquiry@ckpartners.com.my"
        >
          <div className="flex items-center">{buttonTitle || 'Enquire Now'}</div>
        </Button>
      </div>
    </Box>
  );
}

export default PricingCard;
