import { Box, Typography } from '@mui/material';
import PricingCard from './PricingCard';
import pricingData from '../dataStore';

function CompanySecretarial() {
  return (
    <div
      className="grid grid-cols-12 pt-32 md:pt-96 items-center pb-24 md:pb-48"
      style={{ color: '#1C2687' }}
    >
      <div className="col-start-2 col-span-10 md:col-span-4 md:col-start-2">
        <Box className="col-start-2 md:col-start-2 col-span-10 md:col-span-4 mb-24 md:mb-0">
          <img
            className="rounded-2xl w-[15rem] md:w-full object-cover my-10 mx-auto"
            src="assets/images/pages/pricing/accountant-woman-2.jpeg"
            alt="company-secretarial"
          />
        </Box>

        <div className="flex flex-col">
          <Typography className="!font-customBlack !text-[23px] md:!text-[40px] !font-900 !leading-[24px] md:!leading-[48px]">
            Company Secretarial
          </Typography>
          <Typography className="!font-customBlack !text-[16px] md:!text-[25px] !font-900 !mt-2 md:!mt-4 !leading-[20px] md:!leading-[40px] text-[#F5054F]">
            Streamline your business operations
          </Typography>
          <Typography className="!text-[12px] md:!text-[20px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            Tackle the intricate landscape of corporate governance effortlessly with our Company
            Secretarial Service. As your business evolves, so do the legal obligations and
            compliance requirements.
          </Typography>
          <Typography className="!text-[12px] md:!text-[20px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            Our dedicated team ensures your company stays compliant with all statutory obligations,
            from annual filings to board resolutions. Rest assured, with our meticulous attention to
            detail, your company's administrative tasks are in capable hands, allowing you to focus
            on strategic growth and development.
          </Typography>
        </div>
      </div>

      <div className="col-start-3 md:col-start-7 col-span-8 md:col-span-10 mt-[6rem] md:mt-0 md:mr-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-10 md:gap-20">
          {pricingData?.company_secretarial_package?.map((item, index) => {
            return <PricingCard key={index} props={item} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default CompanySecretarial;
