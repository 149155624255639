import { Box, Typography } from '@mui/material';

function OurCulture() {
  return (
    <div className="grid grid-cols-12 py-32 md:py-96 items-center" style={{ color: '#1C2687' }}>
      <div className="col-start-2 md:col-start-2 col-span-10 md:col-span-5 mb-24 md:mb-48 md:mr-20">
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-900 !leading-[30px] md:!leading-[40px]">
          Our Culture
        </Typography>
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-900 !mt-0 md:!mt-3 !leading-[20px] md:!leading-[40px] text-[#F5054F]">
          Bold accountants in a bold environment
        </Typography>
        <div className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
          There's a certain perception when it comes to accountants. Dull colors, hunched over
          spreadsheets, isolated in an office cubicle. That's no fun! We are excited about what we
          do.{' '}
          <h1 className="inline">
            We are young, vibrant people providing professional{' '}
            <i>accounting services in Kuala Lumpur</i>. We work in a place that's comfortable and
            bright
          </h1>
          , where you can't{' '}
          <span className=" text-[#F5054F] !font-700">build bold strategies&nbsp;</span> alone. You
          need to communicate and bounce ideas off each other with people who like to do the same.
          This is at the very heart of our
        </div>
        <Typography className="!text-[15px] md:!text-[30px] !font-900 !leading-[16px] md:!leading-[32px] text-[#F5054F]">
          build bold culture.
        </Typography>
      </div>
      <Box className="col-start-3 md:col-start-7 col-span-8 md:col-span-5 md:ml-auto md:mb-auto">
        <div className="relative block">
          <img
            className="absolute md:bottom-[6.6rem] lg:bottom-[9rem] xl:-left-10 !z-0"
            src="assets/images/pages/team/vector.png"
            alt="vector"
          />

          <img
            className="relative"
            src="assets/images/pages/team/our-culture.png"
            alt="our-culture"
          />
        </div>
      </Box>
    </div>
  );
}

export default OurCulture;
