import { Box, Typography } from '@mui/material';
import PricingCard from './PricingCard';
import pricingData from '../dataStore';

function SdnBhdIncorporation() {
  return (
    <div
      className="grid grid-cols-12 pt-32 md:pt-96 items-center pb-24 md:pb-48"
      style={{ color: '#1C2687' }}
    >
      <div className="col-start-2 md:col-start-1 xl:col-start-2 col-span-10 md:col-span-6 ml-0 md:ml-48">
        <div className="flex flex-col">
          <Typography className="!font-customBlack !text-[23px] md:!text-[50px] !font-900 !leading-[24px] md:!leading-[48px]">
            Incorporation of Sdn Bhd
          </Typography>
          <Typography className="!font-customBlack !text-[16px] md:!text-[30px] !font-900 !mt-2 md:!mt-4 !leading-[20px] md:!leading-[40px] text-[#F5054F]">
            Streamline your business operations
          </Typography>
          <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            Whether you're a startup or an established entity, forming a Sdn Bhd can provide
            numerous benefits including limited liability protection and enhanced credibility.
          </Typography>
          <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            Let us handle the complexities of company registration and compliance so you can focus
            on growing your business. Say goodbye to bureaucratic headaches and hello to a
            simplified business structure with our expert incorporation service.
          </Typography>
        </div>
      </div>
      <Box className="col-start-2 md:col-start-7 xl:col-start-8 col-span-10 md:col-span-6 mt-24 md:mt-0 mx-auto">
        <img
          className="rounded-lg w-[14rem] md:w-[30rem]"
          src="assets/images/pages/pricing/account-woman.png"
          alt="ap-management"
        />
      </Box>

      <div className="col-start-3 md:col-start-2 col-span-8 md:col-span-10 mt-[6rem] md:mt-[15rem]">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 md:gap-20">
          {pricingData?.sdn_bhd_package?.map((item, index) => {
            return <PricingCard key={index} props={item} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default SdnBhdIncorporation;
