import { Button, Dialog, DialogContent, Divider, Hidden, Typography } from '@mui/material';
import { useState } from 'react';

const data = [
  {
    srcTitle: 'accounting-bookkeeping',
    title: 'Accounting & Bookkeeping',
    subtitle: 'Understand your finances without a degree in finance',
    description: `At CKP, we use Ai to automate your accounting processes, freeing up our time to develop financial strategies that help your business grow. With
real-time insights and customized financial plans, we'll help you optimize operations, minimize costs, and maximize profits. Let us handle the numbers so you can handle your business.`,
  },
  {
    srcTitle: 'accounts-outsourcing',
    title: 'Accounts Outsourcing',
    subtitle: `Don't get bogged down by numbers`,
    description: `Outsource your accounting and cut down on time and money. We take care of everything from data entry to financial reporting, ensuring all your records are accurate and up-to-date. With real-time insights, you'll have access to information on your accounts anytime, anywhere, enabling you to make informed decisions and achieve your financial goals.`,
  },
];

const popUpData = [
  {
    title: 'Professional Chartered Accountant',
    description:
      'CKP is headquartered in Kuala Lumpur, Malaysia, and is supported by a dedicated team of chartered accountants who are incredibly competent, experienced, and professional. With our industry connections and expertise, we work closely with each client as their virtual Finance department, liaising with other experts such as auditors and tax experts on their behalf. In addition to providing outsource accounting Malaysia services, we at CKP take pride in being a reliable source of information on general business, finance, and cash flow issues. When necessary, we consult with our clients on some of the most critical issues before making important decisions. Do you require the services of an outsourced accounting company to manage your financial affairs and ensure statutory compliance? Reach us today to speak with a consultant about how our solutions can help your business.',
  },
  {
    title: 'Reputed Outsourced Accountants',
    description: `Are you paying unnecessary penalties regularly, unable to keep your balance sheet precise, or having difficulty training and retaining employees in your company? Then, it may be time to delegate these energy-sucking tasks to us, your trusted outsourced accounting company, so that you can concentrate on revenue-generating operations in your office. Accounting is about more than money. An excellent accountant from a reputed outsource accounting Malaysia company can analyse a set of numbers, assess the company's performance over the previous year and come up with an analysis that serves a purpose - to advise the company directors in making essential decisions, strategy planning, and profit. We help businesses of all sizes, from startup companies to corporate entities, and work with parent and subsidiary companies.`,
  },
];

function Section1() {
  const [open, setOpen] = useState(false);
  const [popUpState, setPopUpState] = useState(0);

  const handleOpen = (state) => {
    setOpen(true);
    setPopUpState(state);
  };
  const handleClose = () => setOpen(false);

  const renderData = (props) => {
    return (
      <>
        <img src={`assets/images/pages/services/${props.srcTitle}.png`} alt={props.srcTitle} />
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !leading-[20px] md:!leading-[45px] !mt-16 md:!mt-32">
          {props.title}
        </Typography>
        <Typography
          className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !leading-[20px] md:!leading-[40px]"
          style={{ color: '#F5054F' }}
        >
          {props.subtitle}
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-5 md:!mt-10 !leading-[14px] md:!leading-[28px]">
          {props.description}
        </Typography>
      </>
    );
  };

  return (
    <div className="grid grid-cols-12 py-36 md:py-72" style={{ color: '#1C2687' }}>
      <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8">
        <Typography
          variant="h1"
          className="!font-customBlack !text-[20px] md:!text-[40px] !font-900 text-center pb-24 md:pb-48"
        >
          Accounting & Payroll Outsourcing Malaysia
        </Typography>
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-5">
            {renderData(data[0])}
            <div className="mt-12 md:mt-24">
              <Button
                className="!font-customBlack !normal-case !text-[12px] md:!text-[23px] !leading-[14px] md:!leading-[28px] !font-700 !border-1 md:!border-2 !rounded-md"
                variant="outlined"
                size="large"
                onClick={() => handleOpen(0)}
                style={{ color: '#F5054F', borderColor: '#F5054F' }}
              >
                Find out more
              </Button>
            </div>
          </div>
          {/* Desktop Version */}
          <Hidden mdDown>
            <div className="col-start-7 mt-28">
              <img src="assets/images/pages/services/line.png" alt="line.png" />
            </div>
          </Hidden>
          {/* Desktop Version */}

          {/* Mobile Version */}
          <Hidden mdUp>
            <Divider
              className="col-span-12 !my-24 !-mx-96"
              style={{ borderColor: '#F5054F', borderStyle: 'dashed' }}
            />
          </Hidden>
          {/* Mobile Version */}

          <div className="col-span-12 md:col-span-5">
            {renderData(data[1])}
            <div className="mt-12 md:mt-24">
              <Button
                className="!font-customBlack !normal-case !text-[12px] md:!text-[23px] !leading-[14px] md:!leading-[28px] !font-700 !border-1 md:!border-2 !rounded-md"
                variant="outlined"
                size="large"
                onClick={() => handleOpen(1)}
                style={{ color: '#F5054F', borderColor: '#F5054F' }}
              >
                Find out more
              </Button>
            </div>
          </div>
        </div>
        <Dialog
          open={open}
          fullWidth
          maxWidth="md"
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent className="!p-20 md:!p-40">
            <Typography
              className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !leading-[20px] md:!leading-[45px]"
              style={{ color: '#F5054F' }}
            >
              {popUpData[popUpState].title}
            </Typography>
            <Typography
              className="!text-[12px] md:!text-[23px] !font-400 !mt-5 md:!mt-10 !leading-[14px] md:!leading-[28px]"
              style={{ color: '#1C2687' }}
            >
              {popUpData[popUpState].description}
            </Typography>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default Section1;
