/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import { useState } from 'react';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const linkData = [
  'home',
  'ai accounting',
  'services',
  'team',
  'pricing',
  'client',
  'blog',
  'contact',
];

const ServicesLink = [
  'accounting and bookkeeping',
  'company incorporation',
  'secretarial services',
  'business advisory',
  'company valuation',
];

function NavbarContentMobile(props) {
  const [expandIcon, setExpandIcon] = useState(false);
  const navigate = useNavigate();
  const renderLink = (link, index) => {
    return link === 'services' ? (
      <div
        key={index}
        className="!text-white !font-400 w-full sm:!text-[12px] !leading-[16px] capitalize !mt-6"
      >
        <div className="grid grid-cols-5 w-full">
          <div className="col-start-2 col-span-3">
            <div
              className="flex flex-row justify-between items-center text-center"
              onClick={() => {
                setExpandIcon((prevExpandIcon) => {
                  if (prevExpandIcon) {
                    navigate('/services');
                    props.handleClose();
                  }

                  return !prevExpandIcon;
                });
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setExpandIcon((prevExpandIcon) => {
                    return !prevExpandIcon;
                  });
                }
              }}
              role="button"
              tabIndex={0}
            >
              {expandIcon ? (
                <KeyboardArrowDownOutlined className="mr-10" />
              ) : (
                <KeyboardArrowRightOutlinedIcon className="mr-10" />
              )}
              {link}
              {expandIcon ? (
                <KeyboardArrowDownOutlined className="ml-10" />
              ) : (
                <KeyboardArrowLeftOutlinedIcon className="ml-10" />
              )}
            </div>
            {expandIcon &&
              ServicesLink?.map((item, ind) => {
                return (
                  <Link
                    key={ind}
                    href={`/services/${item.replace(/ /g, '-')}`}
                    underline="none"
                    className="flex flex-row justify-center !text-[12px] !leading-[16px] !mt-6 !text-white text-center"
                  >
                    {item}
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    ) : (
      <Link
        key={index}
        href={link === 'contact' ? 'contact-us' : `/${link.replace(/ /g, '-')}`}
        underline="none"
        onClick={props.handleClose}
        className="!text-white !font-400 w-full sm:!text-[12px] !leading-[16px] capitalize !mt-6"
      >
        <div className="grid grid-cols-5 w-full">
          <div className="col-start-2 col-span-3">
            {ServicesLink.includes(link) && expandIcon ? (
              <div className="flex flex-row justify-center text-6">{link}</div>
            ) : (
              <div className="flex flex-row justify-between items-center text-center">
                <KeyboardArrowRightOutlinedIcon className="mr-10" />
                {link}
                <KeyboardArrowLeftOutlinedIcon className="ml-10" />
              </div>
            )}
          </div>
        </div>
      </Link>
    );
  };

  return (
    <Box className="flex flex-col items-center">
      <img className="border-b-1" src="assets/images/logo/ckp-logo.png" alt="logo" />
      <Typography className="flex items-center !font-900 !text-[18px] pt-8 pb-20 !-mt-32 text-[#1C2687]">
        <AppsOutlinedIcon className="mr-10" />
        APPLICATIONS
      </Typography>

      {linkData.map((item, index) => {
        return renderLink(item, index);
      })}
    </Box>
  );
}

export default NavbarContentMobile;
