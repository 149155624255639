import Box from '@mui/material/Box';
import { Divider, Hidden, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

function ServicesContent({ props }) {
  const renderStepsTitle = (data) => {
    return (
      <div className="mt-16 md:mt-32">
        {data.icon}
        <Typography className="!font-customBlack !text-[12px] md:!text-[23px] !mt-10 md:!mt-20 !leading-[14px] md:!leading-[28px]">
          {data?.title}
        </Typography>
      </div>
    );
  };

  const renderStepsDescription = (data) => {
    return (
      <Typography
        dangerouslySetInnerHTML={{ __html: data?.description }}
        className="!text-[10px] md:!text-[20px] !mt-4"
      />
    );
  };

  return (
    <Box className="flex flex-col w-full">
      <div className="grid grid-cols-12">
        <div
          className="my-48 md:my-96 col-start-2 lg:col-start-3 col-span-10 lg:col-span-8"
          style={{ color: '#1C2687' }}
        >
          <Typography
            variant="h1"
            className="!text-[20px] md:!text-[40px] !font-900 text-center !font-customBlack !mb-24 md:!mb-48"
          >
            {props.section1?.title}
          </Typography>
          {props.section1?.description?.map((data, index) => {
            return (
              <Typography
                key={index}
                dangerouslySetInnerHTML={{ __html: data }}
                className="!text-[12px] md:!text-[23px] !font-400 text-center !mt-10 md:!mt-20 !leading-[14px] md:!leading-[28px]"
              />
            );
          })}
        </div>
      </div>
      <Divider className="py-1 !rounded-[20px] !bg-[#D9D9D9]" />
      <div
        className="grid grid-cols-2 md:grid-cols-4 gap-x-20 lg:gap-x-96 px-[2rem] md:px-[6rem] lg:px-[12rem] py-40 lg:py-60"
        style={{ color: '#1C2687' }}
      >
        <div className="col-span-2 md:col-span-4" style={{ color: '#1C2687' }}>
          <Typography
            variant="h1"
            className="!text-[20px] md:!text-[40px] !font-900 text-center !font-customBlack"
          >
            Why Choose Us
          </Typography>
        </div>
        {/* Desktop Version */}
        <Hidden mdDown>
          {props.section2?.map((data, index) => {
            return <div key={index}>{renderStepsTitle(data)}</div>;
          })}
          {props.section2?.map((data, index) => {
            return <div key={index}>{renderStepsDescription(data)}</div>;
          })}
        </Hidden>
        {/* Desktop Version */}

        {/* Mobile Version */}
        <Hidden mdUp>
          {props.section2?.map((data, index) => {
            return (
              <div key={index}>
                {renderStepsTitle(data)}
                {renderStepsDescription(data)}
              </div>
            );
          })}
        </Hidden>
      </div>
      <Divider className="py-1 !rounded-[20px] !bg-[#D9D9D9]" />
      <div className="grid grid-cols-12">
        <div
          className="my-32 md:my-60 col-start-2 lg:col-start-3 col-span-10 lg:col-span-8"
          style={{ color: '#1C2687' }}
        >
          <Typography
            variant="h1"
            className="!text-[20px] md:!text-[40px] !font-900 text-center !font-customBlack !mb-24 md:!mb-48"
          >
            Other Services We Provide
          </Typography>
          {props.section3?.description?.map((data, index) => {
            return (
              <Typography
                key={index}
                dangerouslySetInnerHTML={{ __html: data }}
                className="!text-[12px] md:!text-[23px] !font-400 text-center !mt-16 md:!mt-32 !leading-[14px] md:!leading-[28px]"
              />
            );
          })}
        </div>
      </div>
      <Helmet>
        <title>{props.meta?.title}</title>
        <meta name="description" content={props.meta?.description} />
      </Helmet>
    </Box>
  );
}

export default ServicesContent;
