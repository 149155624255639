const pricingData = {
  sme_package: [
    {
      borderColor: 'red',
      price: 'RM 209',
      term: '/MONTH',
      plan: ['STANDARD', 'PLAN'],
      description: [
        '✓ Xero Software - Standard plan with unlimited users.',
        '✓ Hubdoc - Scan your document and let AI do the rest!',
        '✓ Support Group Chat.',
        '✓ Yearly review - Max 1 hour',
        '✓ Tax submission - With less than 150 transactions (Just to top up RM500)',
      ],
      bonus: null,
    },
    {
      borderColor: 'blue',
      price: 'RM 199',
      term: '/MONTH',
      plan: ['QUARTERLY', 'PAYMENT'],
      description: [
        '✓ Xero Software - Standard plan with unlimited users.',
        '✓ Hubdoc - Scan your document and let AI do the rest!',
        '✓ Support Group Chat.',
        '✓ Yearly review - Max 1 hour',
        '✓ Tax submission - With less than 150 transactions (Just to top up RM500)',
      ],
      bonus: [`<span style="color: #F5054F;">15%</span> Special Discount for first 12 months`],
    },
    {
      borderColor: 'red',
      price: 'RM 169',
      term: '/MONTH',
      plan: ['YEARLY', 'PAYMENT'],
      description: [
        '✓ Xero Software - Standard plan with unlimited users.',
        '✓ Hubdoc - Scan your document and let AI do the rest!',
        '✓ Support Group Chat.',
        '✓ Yearly review - Max 1 hour',
        '✓ Tax submission - With less than 150 transactions (Just to top up RM500)',
        '✓ Free setup and Training.',
      ],
      bonus: [`<span style="color: #F5054F;">25%</span> Special Discount for first 12 months`],
    },
  ],
  full_account_package: [
    {
      borderColor: 'blue',
      price: 'RM 899',
      term: '/MONTH',
      plan: ['STARTUP'],
      description: [
        '✓ Xero software - standard plan with unlimited users.',
        '✓ Full set financial report according to your timeline.',
        '✓ One to One monthly advisory - Max 1 hour (Financial analysis, diagnosis of your company financial health)',
      ],
      extraDescription: 'Less than 20 transactions /month',
      bonus: null,
    },
    {
      borderColor: 'red',
      price: 'RM 1299',
      term: '/MONTH',
      plan: ['STARTER'],
      description: [
        '✓ Xero software - standard plan with unlimited users.',
        '✓ Full set financial report according to your timeline.',
        '✓ One to One monthly advisory - Max 1 hour (Financial analysis, diagnosis of your company financial health)',
      ],
      extraDescription: 'Less than 200 transactions /month',
      bonus: null,
    },
    {
      borderColor: 'blue',
      price: 'RM 1999',
      term: '/MONTH',
      plan: ['GROW'],
      description: [
        '✓ Xero software - standard plan with unlimited users.',
        '✓ Full set financial report according to your timeline.',
        '✓ One to One monthly advisory - Max 1 hour (Financial analysis, diagnosis of your company financial health)',
      ],
      extraDescription: 'Less than 400 transactions /month',

      bonus: null,
    },
    {
      borderColor: 'red',
      price: 'TBD',
      term: '/MONTH',
      plan: ['EXPONENTIAL'],
      description: [
        '✓ Xero software - standard plan with unlimited users.',
        '✓ Full set financial report according to your timeline.',
        '✓ One to One monthly advisory - Max 1 hour (Financial analysis, diagnosis of your company financial health)',
      ],
      extraDescription: 'More than 400 transactions /month',
      bonus: null,
      buttonTitle: 'Get Quotes',
    },
  ],
  payroll_package: [
    {
      borderColor: 'blue',
      price: 'RM 250',
      term: '/FREE 3',
      plan: ['STANDARD', 'PAYROLL', 'PROCESSING'],
      description: [
        '✓ Payroll Processing.',
        '✓ Statutory contribution.',
        '✓ Cloud payroll software.',
        '✓ EA Form.',
        '✓ E-payslip.',
        '✓ Leave Management.',
        'Next 4th to 10th - RM60/pax',
        'Next 11th to 20th - RM50/pax',
        'Next 21st and onwards - RM40/pax',
      ],
      extraDescription: null,
      bonus: null,
    },
    {
      borderColor: 'red',
      price: 'RM 250',
      term: '/PER MONTH',
      plan: ['STANDARD', 'PAYROLL', 'PROCESSING'],
      description: [
        '✓ Payroll Processing.',
        '✓ Statutory contribution.',
        '✓ Cloud payroll software.',
        '✓ EA Form.',
        '✓ E-payslip.',
        '✓ Leave Management.',
      ],
      extraDescription: '(Waive if engaged our AP Management)',
      bonus: null,
      marginOff: true,
    },
    {
      borderColor: 'blue',
      price: 'RM 800',
      term: '/PER MONTH',
      plan: ['PAYROLL', 'PAYMENT', 'PROCESSING'],
      description: [
        '✓ Payroll Processing.',
        '✓ Statutory contribution.',
        '✓ Cloud payroll software.',
        '✓ EA Form.',
        '✓ E-payslip.',
        '✓ Leave Management.',
      ],
      extraDescription: null,

      bonus: null,
    },
  ],
  ap_management_package: [
    {
      borderColor: 'blue',
      price: 'RM 299',
      term: '/MONTH',
      plan: ['STARTER'],
      description: [
        '✓ Payment verification.',
        '✓ Upload of transactions to Bank.',
        '✓ Monitoring of payment deadline.',
      ],
      extraDescription: '(Less than 10 Transactions/mth)',
      bonus: ['Processing frequency: Once a month'],
    },
    {
      borderColor: 'red',
      price: 'RM 499',
      term: '/MONTH',
      plan: ['STARTER'],
      description: [
        '✓ Payment verification.',
        '✓ Upload of transactions to Bank.',
        '✓ Monitoring of payment deadline.',
      ],
      extraDescription: '(Less than 30 Transactions/mth)',
      bonus: ['Processing frequency: Twice a month'],
    },
    {
      borderColor: 'blue',
      price: 'RM 899',
      term: '/MONTH',
      plan: ['GROW'],
      description: [
        '✓ Payment verification.',
        '✓ Upload of transactions to Bank.',
        '✓ Monitoring of payment deadline.',
      ],
      extraDescription: '(Less than 60 Transactions/mth)',
      bonus: ['Processing frequency: Twice a month'],
    },
    {
      borderColor: 'red',
      price: 'RM 1399',
      term: '/MONTH',
      plan: ['EXCEL'],
      description: [
        '✓ Payment verification.',
        '✓ Upload of transactions to Bank.',
        '✓ Monitoring of payment deadline.',
      ],
      extraDescription: '(Less than 100 Transactions/mth)',
      bonus: ['Processing frequency: Weekly'],
    },
  ],
  sdn_bhd_package: [
    {
      borderColor: 'red',
      price: 'RM 2500',
      term: null,
      plan: ['STANDARD'],
      description: [
        '✓ Unlimited name search',
        '✓ Bank account opening Resolution (1 bank account)',
        '✓ Appointment of 2 same shareholders and directors',
        '✓ Free full set CTC Documents (1 set, 1 time only)',
        '✓ Advisory on company setup planning',
        '✓ Paid up Capital up to RM1,000',
      ],
      extraDescription: 'For local directors/shareholders',
      bonus: ['RM 250 for additional shareholder/director'],
    },
    {
      borderColor: 'blue',
      price: 'RM 4500',
      term: null,
      plan: ['ULTIMATE'],
      description: [
        '✓ Unlimited name search',
        '✓ Bank account opening Resolution (1 bank account)',
        '✓ Appointment of 2 same shareholders and directors',
        '✓ Free full set CTC Documents (1 set, 1 time only)',
        '✓ Advisory on company setup planning',
        '✓ Paid up Capital up to RM1,000',
        '✓ Fixing of Financial Year End',
        "✓ Shareholder's Agreement (exclude lawyer's stamping fee)",
        '✓ Adoption of Constitution of CA 2016 (included stamping & filing fee)',
      ],
      extraDescription: 'For local directors/shareholders',
      bonus: ['RM 250 for additional shareholder/director'],
    },
    {
      borderColor: 'red',
      price: 'RM 3500',
      term: null,
      plan: ['STANDARD'],
      description: [
        '✓ Unlimited name search',
        '✓ Bank account opening Resolution (1 bank account)',
        '✓ Appointment of 2 same shareholders and directors',
        '✓ Free full set CTC Documents (1 set, 1 time only)',
        '✓ Advisory on company setup planning',
        '✓ Paid up Capital up to RM1,000',
      ],
      extraDescription: 'Inclusion of foreigner directors/shareholders',
      bonus: ['RM 250 for additional shareholder/director'],
    },
    {
      borderColor: 'blue',
      price: 'RM 5500',
      term: null,
      plan: ['ULTIMATE'],
      description: [
        '✓ Unlimited name search',
        '✓ Bank account opening Resolution (1 bank account)',
        '✓ Appointment of 2 same shareholders and directors',
        '✓ Free full set CTC Documents (1 set, 1 time only)',
        '✓ Advisory on company setup planning',
        '✓ Paid up Capital up to RM1,000',
        '✓ Fixing of Financial Year End',
        "✓ Shareholder's Agreement (exclude lawyer's stamping fee)",
        '✓ Adoption of Constitution of CA 2016 (included stamping & filing fee)',
      ],
      extraDescription: 'Inclusion of foreigner directors/shareholders',
      bonus: ['RM 250 for additional shareholder/director'],
    },
  ],
  company_secretarial_package: [
    {
      borderColor: 'red',
      price: 'RM 100',
      term: null,
      plan: ['STANDARD'],
      description: [
        '✓ Assigned Relationship Manager',
        '✓ Appointment of Audit and Tax agent',
        '✓ Fixing of Financial Year End (Fixed once in lifetime)',
        '✓ Full set CTC company documents (1 set, 1 time only)',
      ],
      extraDescription: null,
      bonus: null,
    },
    {
      borderColor: 'blue',
      price: 'RM 180',
      term: null,
      plan: ['ULTIMATE'],
      description: [
        '✓ Assigned Relationship Manager',
        '✓ Appointment of Audit and Tax agent',
        '✓ Fixing of Financial Year End (Fixed once in lifetime)',
        '✓ Full set CTC company documents (1 set, 1 time only)',
        '✓ Filing of Annual Return',
        '✓ Filling of Financial Statement',
        '✓ Beneficial Ownership - 4 persons',
        '✓ Cloud storage',
      ],
      extraDescription: null,
      bonus: null,
    },
  ],
};

export default pricingData;
