import { Typography, Hidden } from '@mui/material';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import WhiteOutlinedButton from '../../../shared-components/WhiteOutlinedButton';
import GoldChampionPartner from './pop-ups/GoldChampionPartner';
import WhatWeDo from './pop-ups/WhatWeDo';
import LastSection from './pop-ups/LastSection';

const imgData = [
  {
    srcTitle: 'xero-1',
  },
  {
    srcTitle: 'xero-2',
  },
  {
    srcTitle: 'xero-3',
  },
];

const titleData = [
  {
    title: 'Xero Authorised Dealer',
  },
  {
    title: 'Xero Malaysia Partner of the Year 2021',
  },
  {
    title: 'Certified XERO Gold Partner',
  },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function XeroAccounting() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const renderTitleData = (props, index) => {
    return (
      <Typography
        key={index}
        className="!font-customBlack !text-[20px] md:!text-[40px] !leading-[22px] md:!leading-[44px] !font-900"
      >
        {props.title}
      </Typography>
    );
  };

  return (
    <div
      className="grid grid-cols-12 text-center mt-48 md:mt-96 py-16 md:py-36 bg-[#F4064F]"
      style={{ color: '#1C2687' }}
    >
      <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8">
        <Typography
          variant="h1"
          className="!font-customBlack !text-[20px] md:!text-[40px] !font-900 text-white"
        >
          Xero Accounting Software Malaysia
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-700 !mt-14 md:!mt-28 !leading-[15px] md:!leading-[30px]">
          Our search led us to Xero, a cloud-based ai accounting software that can be tailored to
          match the needs of any business, large or small. Xero is a highly secure online platform
          that has over 2 million subscribers worldwide and is approved to receive and process
          confidential banking data by at least 7 banks in Malaysia. It's an automated system that
          cuts more than 90% off the time it takes to complete accounting paperwork allowing us to
          get to do what we really enjoy. Explore the benefits of{' '}
          <b>Xero accounting software in Malaysia.</b>
        </Typography>
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-900 text-white !mt-24 md:!mt-52">
          Reading the story behind the numbers
        </Typography>

        {/* Desktop Version */}
        <Hidden mdDown>
          <div className="grid grid-cols-3 gap-20 mt-28 md:mt-52">
            {Array.from({ length: 3 }, (_, index) => (
              <div key={index} className="flex flex-col justify-center items-center">
                <img
                  src={`assets/images/pages/ai-accounting/${imgData[index].srcTitle}.png`}
                  alt={imgData[index].srcTitle}
                />
              </div>
            ))}

            {titleData.map((item, index) => {
              return renderTitleData(item, index);
            })}
          </div>
        </Hidden>
        {/* Desktop Version */}

        {/* Mobile Version */}
        <Hidden mdUp>
          <div className="grid grid-cols-1 gap-y-10 mt-14">
            {Array.from({ length: 3 }, (_, index) => (
              <div key={index} className="flex flex-col justify-between items-center">
                <img
                  src={`assets/images/pages/ai-accounting/${imgData[index].srcTitle}.png`}
                  alt={imgData[index].srcTitle}
                  className="w-48 mb-10"
                />
                {renderTitleData(titleData[index], index)}
              </div>
            ))}
          </div>
        </Hidden>
        {/* Mobile Version */}

        <div className="mt-28 md:mt-60">
          <WhiteOutlinedButton popUp={handleOpen} title="Find out more" />
        </div>

        <Dialog
          open={open}
          fullWidth
          maxWidth="xl"
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent className="!p-0">
            <GoldChampionPartner />
            <WhatWeDo />
            <LastSection />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default XeroAccounting;
