import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Hidden, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import postContactUs from '../../../services/email';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  email: yup.string().email('You must enter a valid email').required('Email is required.'),
  message: yup.string().required('Message is required.'),
  companyName: yup.string().required('Company name is required.'),
  jobTitle: yup.string().required('Job title is required.'),
  appointmentDate: yup.string().required('Appointment date is required.'),
});

const defaultValues = {
  name: '',
  email: '',
  message: '',
  companyName: '',
  jobTitle: '',
  appointmentDate: '',
};

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'None',
    },
  },
});

function ContactUsForm() {
  const [loading, setLoading] = useState(false);

  const { control, formState, handleSubmit, setError, setValue, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;
  const onSubmit = async (model) => {
    try {
      setLoading(true);
      await postContactUs(model, 'Contact Us Form');
      reset();
      return enqueueSnackbar('Email Sent', { variant: 'success' });
    } catch (err) {
      console.error(err);
      return enqueueSnackbar('Something Went Wrong', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col">
      <Box className="p-16 md:p-32 bg-[#F4064F] rounded-[2rem]">
        <form
          name="contactUsForm"
          noValidate
          className="flex flex-col justify-center w-full whitespace-nowrap"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 md:gap-20 md:w-11/12">
            <div className="flex flex-col space-y-2 md:space-y-4">
              <Typography className="text-white !text-[12px] md:!text-[20px] !font-[700] !leading-[35px]">
                NAME
              </Typography>

              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <CssTextField
                    {...field}
                    className="!rounded-[13px] md:!rounded-[25px] bg-white !px-5 !py-2"
                    type="name"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    required
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
            <div className="flex flex-col space-y-2 md:space-y-4">
              <Typography className="text-white !text-[12px] md:!text-[20px] !font-[700] !leading-[35px]">
                EMAIL ADDRESS
              </Typography>

              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <CssTextField
                    {...field}
                    className="!rounded-[13px] md:!rounded-[25px] bg-white !px-5 !py-2"
                    type="email"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    required
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
            <div className="flex flex-col space-y-2 md:space-y-4">
              <Typography className="text-white !text-[12px] md:!text-[20px] !font-[700] !leading-[35px]">
                COMPANY NAME
              </Typography>

              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <CssTextField
                    {...field}
                    className="!rounded-[13px] md:!rounded-[25px] bg-white !px-5 !py-2"
                    type="companyName"
                    error={!!errors.companyName}
                    helperText={errors?.companyName?.message}
                    required
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
            <div className="flex flex-col space-y-2 md:space-y-4">
              <Typography className="text-white !text-[12px] md:!text-[20px] !font-[700] !leading-[35px]">
                JOB TITLE
              </Typography>

              <Controller
                name="jobTitle"
                control={control}
                render={({ field }) => (
                  <CssTextField
                    {...field}
                    className="!rounded-[13px] md:!rounded-[25px] bg-white !px-5 !py-2"
                    type="jobTitle"
                    error={!!errors.jobTitle}
                    helperText={errors?.jobTitle?.message}
                    required
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
            <div className="flex flex-col sm:col-span-2 space-y-2 md:space-y-4">
              <Typography className="text-white !text-[12px] md:!text-[20px] !font-[700] !leading-[35px] ">
                APPOINTMENT DATE
              </Typography>

              <Controller
                name="appointmentDate"
                control={control}
                render={({ field }) => (
                  <CssTextField
                    {...field}
                    className="!rounded-[13px] md:!rounded-[25px] bg-white !px-5 !py-2"
                    type="appointmentDate"
                    error={!!errors.appointmentDate}
                    helperText={errors?.appointmentDate?.message}
                    required
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-10 md:mt-20">
            <div className="flex flex-col col-span-2 space-y-2 md:space-y-4">
              <Typography className="text-white !text-[12px] md:!text-[20px] !font-[700] !leading-[35px]">
                MESSAGE
              </Typography>
              {/* Desktop Version */}
              <Hidden mdDown>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <CssTextField
                      {...field}
                      className="!rounded-[19px] md:!rounded-[38px] bg-white !px-5 !py-2"
                      type="message"
                      error={!!errors.message}
                      helperText={errors?.message?.message}
                      required
                      variant="outlined"
                      size="small"
                      multiline
                      minRows={10}
                      maxRows={10}
                    />
                  )}
                />
              </Hidden>
              {/* Desktop Version */}

              {/* Mobile Version */}
              <Hidden mdUp>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <CssTextField
                      {...field}
                      className="!rounded-[19px] md:!rounded-[38px] bg-white !px-5 !py-2"
                      type="message"
                      error={!!errors.message}
                      helperText={errors?.message?.message}
                      required
                      variant="outlined"
                      size="small"
                      multiline
                      minRows={5}
                      maxRows={5}
                    />
                  )}
                />
              </Hidden>
              {/* Mobile Version */}
            </div>
          </div>
        </form>
      </Box>
      <div className="flex flex-row justify-end pt-36 md:pt-72">
        <Button
          className="whitespace-nowrap !normal-case px-10 md:!px-20 !text-[12px] md:!text-[24px] !font-700 !rounded-[8px] !bg-[#F4064F]"
          variant="contained"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
        >
          <div className="flex items-center">Send message</div>
        </Button>
      </div>
    </div>
  );
}

export default ContactUsForm;
