import { Dialog, DialogContent, Typography } from '@mui/material';
import { useState } from 'react';
import WhiteOutlinedButton from '../../../shared-components/WhiteOutlinedButton';
import PopUps from './pop-ups';

function JoinUs() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div
      className="flex flex-col items-center justify-center text-center py-36 md:py-72 px-10 md:px-20 bg-[#F5054F]"
      style={{ color: '#1C2687' }}
    >
      <div className="flex flex-row items-center">
        <Typography className="!font-customBlack !text-[25px] md:!text-[50px] !font-900 !leading-[24px] md:!leading-[40px]">
          Join us
        </Typography>
        <img
          className="ml-5 md:ml-20 w-16 md:w-32"
          src="assets/images/pages/team/down-icon.png"
          alt="down-icon"
        />
      </div>
      <Typography className="!font-customBlack !text-[25px] md:!text-[50px] !font-900 !mt-10 md:!mt-20 !leading-[28px] md:!leading-[40px] text-white">
        Careers at CKP
      </Typography>
      <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px] w-10/12 md:w-9/12 lg:w-5/12">
        Are you a young and vibrant accountant with a
        <span className="text-white !font-700">&nbsp;build bold mentality&nbsp;</span>and a passion
        for numbers? We'd love to connect with you!
      </Typography>
      <div className="mt-20 md:mt-40">
        <WhiteOutlinedButton title="Find out more" popUp={handleOpen} />
      </div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent className="!p-0">
          <PopUps />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default JoinUs;
