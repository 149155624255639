import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Hidden, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import postContactUs from '../services/email';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  email: yup.string().email('You must enter a valid email').required('Email is required.'),
  message: yup.string().required('Message is required.'),
});

const defaultValues = {
  name: '',
  email: '',
  message: '',
};

function GetInTouch(props) {
  const [loading, setLoading] = useState(false);
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmit = async (model) => {
    try {
      setLoading(true);
      await postContactUs(model, 'Get In Touch Form');
      return enqueueSnackbar('Email Sent', { variant: 'success' });
    } catch (err) {
      console.error(err);
      return enqueueSnackbar('Something Went Wrong', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="grid grid-cols-12 xl:grid-cols-8 h-[20rem] md:h-[38rem] bg-[#F5054F]">
        <div
          className="col-start-2 xl:col-start-3 col-span-10 xl:col-span-4 flex flex-col mt-36 md:mt-72"
          style={{ color: '#1C2687' }}
        >
          <Box className="p-16 md:p-32 rounded-[30px] shadow-md bg-white">
            <Typography className="!font-customBlack !text-[22px] md:!text-[45px] !font-900 text-center">
              {props.title ? props.title : 'Get In Touch'}
            </Typography>
            <Typography
              className="!font-customBlack !text-[15px] md:!text-[30px] !font-900 text-center !mt-2 md:!mt-5"
              style={{ color: '#F5054F' }}
            >
              Speak to us and let's explore how we can help you.
            </Typography>
            <div className="flex flex-row justify-between">
              <form
                name="GetInTouchForm"
                noValidate
                className="flex flex-col justify-center w-full whitespace-nowrap"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-48 gap-y-20 md:gap-y-40 mt-20 md:mt-40">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="name"
                        label="Name"
                        placeholder="Name"
                        required
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                        variant="outlined"
                      />
                    )}
                  />

                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="email"
                        label="Email Address"
                        required
                        placeholder="Email Address"
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        variant="outlined"
                      />
                    )}
                  />
                  {/* Desktop Version */}
                  <Hidden mdDown>
                    <Controller
                      name="message"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="message"
                          className="md:col-span-2"
                          label="Message"
                          required
                          placeholder="Message"
                          error={!!errors.message}
                          helperText={errors?.message?.message}
                          variant="outlined"
                          multiline
                          minRows={10}
                          maxRows={10}
                        />
                      )}
                    />
                  </Hidden>
                  {/* Desktop Version */}

                  {/* Mobile Version */}
                  <Hidden mdUp>
                    <Controller
                      name="message"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="message"
                          className="md:col-span-2"
                          label="Message"
                          required
                          placeholder="Message"
                          error={!!errors.message}
                          helperText={errors?.message?.message}
                          variant="outlined"
                          multiline
                          minRows={5}
                          maxRows={5}
                        />
                      )}
                    />
                  </Hidden>
                  {/* Mobile Version */}
                </div>
                <div className="flex flex-row justify-center mt-14 md:mt-28">
                  <Button
                    className="!font-customBlack whitespace-nowrap !normal-case px-10 md:!px-20 !text-[12px] md:!text-[24px] !font-700 !rounded-[8px] !bg-[#F4064F]"
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    <div className="flex items-center">Submit &gt;</div>
                  </Button>
                </div>
              </form>
            </div>
          </Box>
        </div>
      </div>
      <div className="h-[17rem] sm:h-[25rem]" />
    </>
  );
}

export default GetInTouch;
