import { styled } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { Hidden } from '@mui/material';
import NavbarContent from './NavbarContent';
import NavbarContentMobile from './NavbarContentMobile';

const navbarWidth = 280;

const StyledNavbarMobile = styled(SwipeableDrawer)(({ theme, position }) => ({
  '& > .MuiDrawer-paper': {
    backgroundColor: '#F4064F',
    minWidth: navbarWidth,
    width: navbarWidth,
    maxWidth: navbarWidth,
    maxHeight: '100%',
    transition: theme.transitions.create(['width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

function Navbar(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Hidden mdDown>
        <div id="navbar" className="bg-[#F4064F] px-32 pt-20 xl:pt-48 !-mb-2 mb-">
          <NavbarContent />
        </div>
      </Hidden>
      <Hidden mdUp>
        <Box className="!-mb-2" sx={{ flexGrow: 1 }}>
          <AppBar className="!bg-[#F5054F]" position="static">
            <Toolbar>
              <IconButton
                onClick={handleOpen}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ ml: 1 }}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </Box>
        <StyledNavbarMobile
          classes={{
            paper: 'flex-col flex-auto h-screen',
          }}
          variant="temporary"
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          disableSwipeToOpen
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <NavbarContentMobile handleClose={handleClose} />
        </StyledNavbarMobile>
      </Hidden>
    </>
  );
}

export default Navbar;
