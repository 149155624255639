import { Typography } from '@mui/material';

function WhatIsAiAccounting() {
  return (
    <div className="grid grid-cols-12 py-36 md:py-72 text-center" style={{ color: '#1C2687' }}>
      <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8">
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-700">
          What is <span style={{ color: '#F5054F' }}>AI Accounting?</span>
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-14 md:!mt-28 !leading-[24px] md:!leading-[28px]">
          AI accounting automates tedious tasks, streamlines processes, and provides real-time
          insights and analytics that can be accessed by any computer or mobile device. We'll be
          with you at every step on your journey to business digitalisation, by giving you training
          on how to take full advantage of this powerful tool effectively and providing advice and
          expertise, on how to grow your business, based on the insights generated by the numbers.
        </Typography>
      </div>
    </div>
  );
}

export default WhatIsAiAccounting;
