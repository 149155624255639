import { Box, Typography } from '@mui/material';

function GoldChampionPartner() {
  return (
    <div className="grid grid-cols-12 py-36 md:py-72 text-center" style={{ color: '#1C2687' }}>
      <div className="col-start-2 md:col-start-2 col-span-10 md:col-span-6">
        <Typography className="!text-[23px] md:!text-[45px] !font-700 !leading-[20px] md:!leading-[40px]">
          Xero Gold Champion Partner
        </Typography>
        <Box className="py-6 rounded-[20px] border-[#F5054F] border-2 md:border-4 mt-10">
          <Typography
            className="!text-[16px] md:!text-[35px] !font-700 !leading-[14px] md:!leading-[28px]"
            style={{ color: '#F5054F' }}
          >
            Xero Accounting Software Malaysia
          </Typography>
        </Box>
        <Typography className="!text-[12px] md:!text-[25px] !font-400 text-start !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          As we have been awarded Xero Malaysia Partner of the Year 2022, you can trust our
          expertise and specialisation in the integration of Xero accounting software into your
          business.
        </Typography>
      </div>
      <div className="col-span-10 md:col-span-5 mt-10 md:mt-0">
        <img
          className="h-full"
          src="assets/images/pages/ai-accounting/gold-champion-partner.png"
          alt="gold-champion-partner"
        />
      </div>
    </div>
  );
}

export default GoldChampionPartner;
