import Box from '@mui/material/Box';
import { Divider, Hidden, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import GetInTouch from '../../shared-components/GetInTouch';
import XeroAccounting from './components/XeroAccounting';
import WhatIsAiAccounting from './components/WhatIsAiAccounting';
import Benefits from './components/Benefits';
import AccountingWithCKP from './components/AccountingWithCKP';

function AiAccounting() {
  return (
    <Box className="flex flex-col">
      <img
        className="w-full"
        src="assets/images/pages/ai-accounting/ai-header.png"
        alt="ai-header"
      />
      <div className="grid grid-cols-12">
        <div
          className="mt-48 md:mt-96 col-start-2 lg:col-start-3 col-span-10 lg:col-span-8"
          style={{ color: '#1C2687' }}
        >
          <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-900 text-center">
            Forget what you know about accounting
          </Typography>
          <Typography className="!text-[10px] md:!text-[25px] !font-400 text-center !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
            We began a career in accounting because we loved numbers. The numbers can provide
            valuable insight into a company's health and give a clear direction to business growth.
            However, we could never tap into the full potential of what the numbers can tell us
            because we are constantly bogged down with tedious accounting processes and dreary
            environments. Why does accounting have to be like this?
          </Typography>
          <div className="flex flex-row items-center justify-center w-full whitespace-nowrap space-x-14 md:space-x-28 mt-32 md:mt-60">
            <Typography className="!font-customBlack !font-900 !text-[20px] md:!text-[40px] text-center">
              There is a better way
            </Typography>

            {/* Desktop Version */}
            <Hidden mdDown>
              <img src="assets/images/pages/ai-accounting/bulb-icon.png" alt="bulb-icon" />
            </Hidden>
            {/* Desktop Version */}

            {/* Mobile Version */}
            <Hidden mdUp>
              <img
                className="w-28"
                src="assets/images/pages/ai-accounting/bulb-icon.png"
                alt="bulb-icon"
              />
            </Hidden>
            {/* Mobile Version */}
          </div>
        </div>
      </div>
      <XeroAccounting />
      <WhatIsAiAccounting />
      <Divider className="py-1 !rounded-[20px] !bg-[#D9D9D9]" />
      <Benefits />
      <Divider className="py-1 !rounded-[20px] !bg-[#D9D9D9]" />
      <AccountingWithCKP />
      <GetInTouch />
      <Helmet>
        <title>Xero Accounting Software Malaysia | Chia, Ka & Partners</title>
        <meta
          name="description"
          content="We provide Xero accounting software services in Malaysia as a gold partner of Xero. We strongly believe our team will deliver value to your business with Xero."
        />
      </Helmet>
    </Box>
  );
}

export default AiAccounting;
