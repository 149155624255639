import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ServicesContent from '../components/ServicesContent';

const data = {
  section1: {
    title: 'Accounting and Bookkeeping Services',
    description: [
      `Delivering exceptional <b>accounting and bookkeeping services</b> has always been CKP’s core
identity since the early days of our establishment. No other provider of bookkeeping services Malaysia can compete with our top-notch efficiency when it comes to the handling of your auditing or taxation matters. We continuously put our best effort into making sure your cash flow is in good condition by relying on our network of industry professionals and vast knowledge. On top of that, rest assured that we can help you establish statutory compliance concerning regulatory and employment laws for your business, guided by our amazing Chartered Accountants.`,
    ],
  },
  section2: [
    {
      icon: <CloudDoneOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />,
      title: 'Cloud-based and Digitalised',
      description:
        '<i>Our accounting and bookkeeping services</i> are highly automated, considering we utilise artificial intelligence (AI), which can be beneficial as a time- effective strategy for businesses with limited schedules.',
    },
    {
      icon: (
        <AccountBalanceWalletOutlinedIcon
          className="!text-16 md:!text-32"
          style={{ color: '#F5054F' }}
        />
      ),
      title: 'Collaboration with Xero',
      description:
        'Since 2019, we have been an official Xero partner, earning certifications as advisors and migration experts. We are Xero Malaysia Partner of the Year in 2022!',
    },
    {
      icon: (
        <SupportAgentOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />
      ),
      title: 'Detailed Consultations',
      description:
        'Prior to making crucial decisions, we discuss some of the most significant matters with our clients whenever required by providing a trustworthy resource for relevant data.',
    },
    {
      icon: (
        <PersonOutlineOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />
      ),
      title: 'Personalised Experience',
      description:
        'We engage closely with clients individually as their digital financial division. Our tailored financial strategies and real-time data help them increase profits, reduce expenses, and streamline operations.',
    },
  ],
  section3: {
    description: [
      'We offer a comprehensive suite of services beyond company incorporation services Malaysia, including expert accounting and bookkeeping services. Our skilled professionals meticulously manage financial records, ensuring accuracy and compliance with regulatory standards. Our business advisory services provide strategic insights to optimize operations and foster growth. We specialize in company valuation, employing robust methodologies to determine fair and competitive market values. This aids clients in making informed decisions about mergers, acquisitions, or financial planning.',
      'Additionally, our secretarial services streamline administrative tasks, ensuring compliance with statutory requirements and facilitating smooth business operations. We take pride in delivering holistic solutions, combining legal expertise with financial acumen to empower businesses for success. With a client-centric approach, we strive to be your trusted partner in navigating the complexities of both legal and financial landscapes. So, if you are in need of a third-party vendor to ensure your business meets all the legal requirements and keeps your book in check, get in touch with us today!',
    ],
  },
  meta: {
    title: 'Accounting And Bookkeeping Services | Chia, Ka & Partners',
    description:
      'Chia, Ka & Partners provides accounting and bookkeeping services with automated systems and a real-time cloud accounting system. Contact us for more information!',
  },
};

function AccountingAndBookkeeping() {
  return <ServicesContent props={data} />;
}

export default AccountingAndBookkeeping;
