import { Typography } from '@mui/material';

function CompanyIncorporation() {
  return (
    <div
      className="grid grid-cols-12 py-16 md:py-32 space-x-0 md:space-x-60 space-y-20 md:space-y-0"
      style={{ color: '#1C2687' }}
    >
      <div className="col-start-2 md:col-start-2 col-span-10 md:col-span-5">
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !leading-[14px] md:!leading-[28px]">
          Let's make that business idea come to life! Government regulations, dealing with SSM,
          confusing legal terms... We want to be there for you right from the start! Whether it's
          handling all the legal paperwork or reassuring you with financial advice, there's nothing
          we love more than empowering new entrepreneurs to see their business dreams come to life.
          Let us help you with the frustrating procedures when you engage our company incorporation
          services Malaysia so that you can fully focus on planning strategy, achieving goals, and
          growing ideas.
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          Malaysia welcomes you with open arms.
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          As one of Asia's most thriving economic hubs, it is no surprise that Malaysia ranks top 12
          in the World Bank's list of countries with the most ease of doing business. Realise your
          business dreams in a country of affordable business costs, developed infrastructure, and
          encouraging government policies. Your company can find its home in Malaysia with our
          company incorporation services Malaysia.
        </Typography>
      </div>
      <div className="col-start-2 md:col-start-7 col-span-10 md:col-span-5 rounded-[20px]">
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !leading-[20px] md:!leading-[40px]">
          Establish & Incorporate Your Business In Malaysia
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-500 !mt-7 md:!mt-14 !leading-[14px] md:!leading-[28px] text-white">
          Establishing and incorporating a business in Malaysia is not without its challenges, and
          we are here to help you manoeuvre the path to success. Our complete suite of company
          incorporation service and forming are ready to assist you with your business setup,
          ensuring that your Malaysian venture gets off to the best possible start. Our company
          incorporation experts have in-depth knowledge and understanding of the environment to
          steer you through every aspect of the process so that your company can achieve the success
          it deserves in Malaysia. Furthermore, our company incorporation service specialists ensure
          that your experience integrating a company in Malaysia is smooth and seamless while
          confirming that your business fully complies with all legal and regulatory requirements.
          This lets you focus entirely on managing your business with peace of mind. Don't wait
          anymore, get in touch with us today and let us assist you!
        </Typography>
      </div>
    </div>
  );
}

export default CompanyIncorporation;
