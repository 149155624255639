import { Typography } from '@mui/material';
import BgRedButton from '../../../shared-components/BgRedButton';

function ForgetWhatYouKnow() {
  return (
    <div
      className="flex flex-col items-center justify-center text-center pt-36 md:pt-72 pb-16 md:pb-32 px-10 md:px-20"
      style={{ color: '#1C2687' }}
    >
      <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-900 !leading-[24px] md:!leading-[55px]">
        Forget what you know about accounting
      </Typography>
      <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-900 !leading-[24px] md:!leading-[55px]">
        There is a better way
      </Typography>
      <div className="mt-16 md:mt-32">
        <BgRedButton title="Let's get started" destination="/contact-us" />
      </div>
      <Typography className="!font-customBlack !text-[25px] md:!text-[50px] !font-900 !mt-32 md:!mt-60 !leading-[28px] md:!leading-[40px]">
        Join us now!
      </Typography>
    </div>
  );
}

export default ForgetWhatYouKnow;
