import { Typography } from '@mui/material';

function TheTeam() {
  return (
    <div
      className="grid grid-cols-12 py-48 md:py-96 bg-[#F5054F] items-center relative z-10"
      style={{ color: '#1C2687' }}
    >
      <div className="col-start-2 md:col-start-2 col-span-10 md:col-span-5 mb-24 md:mb-0">
        <img src="assets/images/pages/team/the-team.png" alt="the-team" />
      </div>
      <div className="col-start-2 md:col-start-7 col-span-10 md:col-span-5 ml-0 md:ml-8">
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-900 !leading-[30px] md:!leading-[40px]">
          The Team
        </Typography>
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-900 !mt-0 md:!mt-3 !leading-[20px] md:!leading-[40px] text-white">
          People who love numbers
        </Typography>
        <div className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
          Behind every up-to-date transaction are qualified accountants that love numbers and are
          armed with a passion to identify trends. They then develop solutions and
          <h1 className="inline">
            strategies to help our clients{' '}
            <b className="!text-[12px] md:!text-[23px] !font-700 !leading-[16px] md:!leading-[32px] text-white">
              build bold{' '}
            </b>
          </h1>
          with top-notch
          <b> accounting services in Kuala Lumpur.</b>
        </div>

        <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
          All our team members are armed with qualified certifications, and membership associations
          and are aligned with our culture of developing new skills, embracing new technology, and
          developing new strategies that to help businesses succeed.
        </Typography>
      </div>
    </div>
  );
}

export default TheTeam;
