import { Box, Typography } from '@mui/material';

function VideoCard2() {
  return (
    <div className="grid grid-cols-12 pt-32 md:pt-96 pb-24 md:pb-48 items-center ">
      <div className="col-start-2 md:col-start-3 col-span-10 md:col-span-8">
        {/* Use flex classes directly on the parent container */}
        <div className="flex flex-col-reverse sm:flex-row">
          {/* Set the iframe width to 65% using 'w-65' class */}
          <Box
            className="flex flex-col bg-white rounded-[20px] flex-1 text-white p-3 md:p-5 relative z-10" // Use 'flex-1' class to make the Box take remaining width
            style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }} // Add negative margin to remove the space between the video and the box
          >
            <Box
              className="flex flex-col flex-1 justify-center items-center bg-[#F5054F] rounded-[20px] p-20 xl:p-80" // Use 'flex-1' class to make the Box take remaining width
            >
              <Typography className="!font-customBlack !text-[15px] md:!text-[30px] !font-900 !leading-[12px] md:!leading-[33px] uppercase">
                Elektrik Shing Hing Sdn Bhd
              </Typography>

              <Typography className="!text-[10px] md:!text-[14px] !font-700 !mt-20 md:!mt-40 !leading-[10px] md:!leading-[20px]">
                “Prior to engaging Chia, Ka, and Partners (CKP), our accounting systems were pretty
                manual. Since engaging them, we can now obtain real-time information, which helps to
                forecast our future plans.”
              </Typography>
            </Box>
          </Box>
          <div className="sm:w-[65%] border-[20px] bg-[#D9D9D9] rounded-l-[20px] md:rounded-l-0 rounded-r-[20px] -ml-5 relative  mb-10 sm:mb-0">
            <iframe
              src="https://player.vimeo.com/video/850790215?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              className="w-full h-full relative" // Set a fixed height for the video, adjust as needed
              allowFullScreen
              title="CKP Testimonial Video - Elektrik Shing Hing Sdn Bhd"
            />
            <img
              className="absolute inset-0 top-[5rem] md:top-[20rem] lg:top-[15rem] xl:top-[18rem] left-[10rem] sm:left-[20rem] lg:left-[30rem] xl:left-[45rem] -z-1 w-[20rem] md:w-[40rem]"
              src="assets/images/pages/client/video-icon.png"
              alt="video-icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoCard2;
