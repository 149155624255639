import { Typography } from '@mui/material';

function CompanySecretaryRetainer() {
  return (
    <div
      className="grid grid-cols-12 py-16 md:py-32 space-x-0 md:space-x-60 space-y-20 md:space-y-0"
      style={{ color: '#1C2687' }}
    >
      <div className="col-start-2 col-span-10">
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !leading-[14px] md:!leading-[28px]">
          Cost-effective There's no need to hire a full-time company secretary when you can
          outsource those duties to our secretary firm in KL. Get your secretarial tasks taken care
          of by equally qualified professionals, while saving costs.
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          Assurance from accredited advisors Rest in the knowledge that you have the support of our
          certified secretaries. We also place importance in playing an advisory role in secretarial
          matters, so you can turn our secretary firm in KL for the trusted expertise you are
          looking for.
        </Typography>
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !mt-28 md:!mt-52 text-white text-center">
          Reducing The Burden On Your Business
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          Satisfying regulatory, legal, and entity governance obligations is becoming more critical
          and time-consuming. Compliance is a top priority for all businesses, but it can tax your
          resources. Our corporate secretarial teams from our secretarial firm in KL can handle all
          your compliance milestones, permitting your team to concentrate on their key
          responsibilities. Most businesses work hard to adhere to their legal and regulatory
          frameworks. However, as in-house teams try to keep up with new developments in other
          jurisdictions, an evolving set of rules and regulations can strain them. At CKP, we try to
          reduce your burdens, so you can focus on what you do best!
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          Companies that can meet their statutory compliance requirements benefit from improved
          performance and can better control processes and procedures in their business. In
          addition, secretarial assistance from our secretarial firm in KL can be critical in
          lowering risks and limiting exposure to the costs of noncompliance, such as fines,
          prosecution, or director disqualification.
        </Typography>
      </div>
    </div>
  );
}

export default CompanySecretaryRetainer;
