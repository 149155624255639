import { Box, Typography } from '@mui/material';
import PricingCard from './PricingCard';
import pricingData from '../dataStore';

function APManagementPackage() {
  return (
    <div
      className="grid grid-cols-12 pt-32 md:pt-96 items-center pb-24 md:pb-48"
      style={{ color: '#1C2687' }}
    >
      <Box className="col-start-2 md:col-start-2 col-span-10 md:col-span-4 mb-24 md:mb-0 mx-auto">
        <img
          className="rounded-full w-[12rem] md:w-full"
          src="assets/images/pages/pricing/ap-management.png"
          alt="ap-management"
        />
      </Box>
      <div className="col-start-2 md:col-start-6 col-span-10 md:col-span-6 ml-0 md:ml-24">
        <div className="flex flex-col">
          <Typography className="!font-customBlack !text-[23px] md:!text-[50px] !font-900 !leading-[24px] md:!leading-[48px]">
            AP Management Package
          </Typography>
          <Typography className="!font-customBlack !text-[16px] md:!text-[35px] !font-900 !mt-2 md:!mt-4 !leading-[20px] md:!leading-[40px] text-[#F5054F]">
            Accounts payable on autopilot
          </Typography>
          <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            You're an SME dealing with a high volume of invoices, with multiple departments and
            suppliers to manage, and the process is becoming increasingly complex and
            time-consuming.
          </Typography>
          <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            Say goodbye to the time-consuming and stressful task of managing your accounts payable.
            From ensuring compliance to accurately paying your suppliers on time.
          </Typography>
        </div>
      </div>

      <div className="col-start-3 md:col-start-2 col-span-8 md:col-span-10 mt-[6rem] md:mt-[15rem]">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 md:gap-20">
          {pricingData?.ap_management_package?.map((item, index) => {
            return <PricingCard key={index} props={item} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default APManagementPackage;
