import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import ServicesContent from '../components/ServicesContent';

const data = {
  section1: {
    title: 'Business Advisory Services Malaysia',
    description: [
      `Chia, Ka & Partners is a prominent firm offering comprehensive <b>business advisory services Malaysia</b>. Renowned for its expertise in business consulting Malaysia, the firm provides tailored solutions to enhance operational efficiency and strategic decision-making. With a dedicated team of professionals, Chia, Ka &amp; Partners specializes in delivering top-notch business consulting services Malaysia, catering to a diverse range of industries.`,
      `Their commitment to excellence is evident in their proactive approach to addressing client needs, making them a trusted partner for those seeking reliable business advisory Malaysia solutions. Through a combination of industry insights and innovative strategies, Chia, Ka & Partners goes beyond conventional business consulting services Malaysia or business advisory services Malaysia, providing clients with a competitive edge in the dynamic business landscape. Whether navigating market challenges or pursuing growth opportunities, the firm’s client-centric approach makes it a go-to choice for businesses seeking impactful business consulting Malaysia or business advisory Malaysia assistance.`,
    ],
  },
  section2: [
    {
      icon: <HandshakeOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />,
      title: 'Certified Professionals',
      description:
        'Our Business Advisory Services feature a team of certified professionals ensuring expert guidance, compliance, and reliable insights for strategic decision-making, fostering your business success.',
    },
    {
      icon: <SchoolOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />,
      title: 'Experience and Knowledge',
      description:
        'Leverage decades of industry experience and in-depth knowledge. Our advisory team navigates complexities, offering tailored solutions that drive growth and maximize your business potential.',
    },
    {
      icon: <CloudDoneOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />,
      title: 'Cloud-Based Solutions',
      description:
        'Embrace the future with our cloud-based Business Advisory Services. Seamlessly access real-time data, enhance collaboration, and make informed decisions from anywhere, optimizing efficiency and adaptability.',
    },
    {
      icon: (
        <PersonOutlineOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />
      ),
      title: 'Personalized Experience',
      description:
        'Enjoy a tailored advisory experience. Our experts understand your unique challenges, crafting personalized strategies that align with your goals, ensuring a partnership focused on your business’s individual needs and aspirations.',
    },
  ],
  section3: {
    description: [
      'Chia, Ka & Partners is more than just an accounting firm’s comprehensive business solutions provider offering a range of services beyond legal counsel. Our expertise extends to accounting and bookkeeping services, ensuring that clients’ financial records are accurate, organized, and compliant with regulations. As a matter of fact, we go beyond traditional legal support, also specializing in company incorporation services. This includes guiding clients through the complexities of establishing and structuring their businesses, ensuring a smooth as well as legally sound incorporation process. Our commitment to holistic business support sets us apart, providing clients with a one-stop solution for legal, financial, and administrative needs. With a team of professionals well-versed in both legal and financial matters, we deliver integrated services that empower businesses to thrive in a competitive landscape.',
    ],
  },
  meta: {
    title: 'Business Advisory Services Malaysia - Chia, Ka & Partners',
    description:
      'Chia, Ka & Partners, delviers professional business advisory services in Malaysia. Unlock success through strategic guidance and tailored solutions with us.',
  },
};

function BusinessAdvisoryServices() {
  return <ServicesContent props={data} />;
}

export default BusinessAdvisoryServices;
