import { Typography, Box } from '@mui/material';

function AccountingWithCKP() {
  return (
    <div className="grid grid-cols-12 py-16 md:py-32 text-center" style={{ color: '#1C2687' }}>
      <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8">
        <div className="flex flex-row items-center justify-center space-x-10 md:space-x-20">
          <Box className="rounded-full p-3 md:p-5 bg-[#F5054F]" />
          <Box className="rounded-full p-3 md:p-5 bg-[#F5054F]" />
          <Box className="rounded-full p-3 md:p-5 bg-[#F5054F]" />
        </div>
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-900 !mt-36 md:!mt-72">
          Accounting with CKP
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[14px] md:!leading-[28px]">
          With CKP you have the assurance that with AI accounting your financial processes are
          optimized and managed efficiently. This allows our accountants to focus on more strategic
          activities, such as financial planning, analysis, and regular advice on how to
        </Typography>
        <Typography
          className="!text-[20px] md:!text-[40px] !font-900 !mt-5 md:!mt-10"
          style={{ color: '#F5054F' }}
        >
          build bold
        </Typography>
        <Typography className="!font-customBlack !text-[25px] md:!text-[50px] !font-900 !mt-20 md:!mt-40">
          Let's grow your business together.
        </Typography>
      </div>
    </div>
  );
}

export default AccountingWithCKP;
