import { Typography } from '@mui/material';
import Carousel from './Carousel';
import WhiteOutlinedButton from '../../../shared-components/WhiteOutlinedButton';

const data = [
  {
    src: 'assets/images/pages/landing/services-1.png',
    title: 'Cloud Based Ai Accounting & Bookkeeping',
    description:
      'No paper, no printing. Keep track of every transaction with automated systems and real-time reports, anytime, anywhere.',
  },
  {
    src: 'assets/images/pages/landing/services-2.png',
    title: 'Financial Process Optimization',
    description:
      'No tedious tasks like managing debtors or audit queries. We streamline your books and then give you the story behind the numbers so you have the information you need to build bold.',
  },
  {
    src: 'assets/images/pages/landing/services-3.png',
    title: 'Easy Automated Payroll',
    description: `No time wasted on dreary tasks like checking attendance and making EPF and Socso Payments. Get an accurate, compliant, employee data management system that doesn't pull you away from your business every month.`,
  },
  {
    src: 'assets/images/pages/landing/services-4.png',
    title: 'Start a Company',
    description: `Don't get bogged down with all the gruntwork of incorporating a company. We'll do that. You concentrate on building your entrepreneurial dreams.`,
  },
  {
    src: 'assets/images/pages/landing/services-5.png',
    title: 'Company Secretarial Services',
    description:
      'Stay compliant effortlessly with our AI-integrated accounting model that adapts to ever-changing regulations.',
  },
  {
    src: 'assets/images/pages/landing/services-6.png',
    title: 'Business Valuation',
    description: `Looking to sell, merge or secure funding? Gain true understading of your business's financial position`,
  },
  {
    src: 'assets/images/pages/landing/services-7.png',
    title: 'Virtual CFO',
    description:
      'Experience expert financial guidance and gain invaluable insights, strategic direction, and optimise your financial performance without a full-time CFO',
  },
  // Add more cards as needed
];

function OurServices() {
  return (
    <div className="grid grid-cols-12 bg-[#F4064F] mt-48 md:mt-96 py-16 md:py-36">
      <div
        className="flex flex-col items-center justify-center col-start-2 lg:col-start-3 col-span-10 lg:col-span-8 text-center"
        style={{ color: '#1C2687' }}
      >
        <Typography className="!text-[20px] md:!text-[40px] !font-900 text-center !font-customBlack">
          Our services
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          It's not the services we provide, it's the way we provide them. With all the repetitive
          tasks happening automatically we can concentrate on developing strategies to help you
          <span className="font-900">&nbsp;build bold</span>
        </Typography>

        <Carousel data={data} />

        <WhiteOutlinedButton title="Find out more" destination="/services" />
      </div>
    </div>
  );
}

export default OurServices;
