import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ServicesContent from '../components/ServicesContent';

const data = {
  section1: {
    title: 'Company Incorporation Services Malaysia',
    description: [
      `Chia, Ka & Partners excels in providing comprehensive <b>company incorporation services Malaysia</b>, catering to diverse business needs. With a sterling reputation, we streamline the intricate process of establishing a company, ensuring compliance with Malaysian legal requirements. Our seasoned professionals offer expert guidance on company incorporation, navigating through documentation, and liaising with regulatory bodies. We are synonymous with reliability in the realm of company incorporation service. Whether it’s registering a new entity or expanding an existing one, our adept team ensures a seamless experience. Trust us for unparalleled expertise in company incorporation service, fostering business success in the dynamic Malaysian market.`,
    ],
  },
  section2: [
    {
      icon: (
        <ThumbUpAltOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />
      ),
      title: 'Digital Excellence',
      description:
        'Embrace seamless company incorporation with our cutting-edge digital solutions, ensuring efficiency, security, and a hassle-free experience for your business journey.',
    },
    {
      icon: (
        <PersonOutlineOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />
      ),
      title: 'Your Personal Accountant',
      description:
        'Enjoy bespoke financial guidance from a dedicated accountant, committed to understanding your unique needs and steering your business towards lasting success.',
    },
    {
      icon: (
        <SupportAgentOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />
      ),
      title: 'Financial Wellness Experts',
      description:
        'Elevate your business with our tailored financial health consultations, empowering you with insights and strategies for sustainable growth and long-term prosperity.',
    },
    {
      icon: <CloudDoneOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />,
      title: 'Cloud-Driven Advantage',
      description:
        'Harness the power of the cloud for secure, accessible, and Company Incorporation Services Malaysia company incorporation services collaborative business operations, ensuring your company thrives in today’s dynamic and competitive landscape.',
    },
  ],
  section3: {
    description: [
      'We offer a comprehensive suite of services beyond <i>company incorporation services Malaysia</i>, including expert accounting and bookkeeping services. Our skilled professionals meticulously manage financial records, ensuring accuracy and compliance with regulatory standards. Our business advisory services provide strategic insights to optimize operations and foster growth. We specialize in company valuation, employing robust methodologies to determine fair and competitive market values. This aids clients in making informed decisions about mergers, acquisitions, or financial planning.',
      'Additionally, our secretarial services streamline administrative tasks, ensuring compliance with statutory requirements and facilitating smooth business operations. We take pride in delivering holistic solutions, combining legal expertise with financial acumen to empower businesses for success. With a client-centric approach, we strive to be your trusted partner in navigating the complexities of both legal and financial landscapes. So, if you are in need of a third-party vendor to ensure your business meets all the legal requirements and keeps your book in check, get in touch with us today!',
    ],
  },
  meta: {
    title: 'Company Incorporation Service & Accounting | Chia, Ka & Partners',
    description:
      'Chia, Ka & Partners provides company incorporation service for your startups, including registration of SSM, legal paperwork, and other related procedures. Contact us!',
  },
};

function CompanyIncorporationServices() {
  return <ServicesContent props={data} />;
}

export default CompanyIncorporationServices;
