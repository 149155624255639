import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ServicesContent from '../components/ServicesContent';

const data = {
  section1: {
    title: 'You Trusted Secretarial Firm In KL',
    description: [
      `As we strive to be the best <b>secretarial firm in KL</b>, CKP never stops experimenting with new technologies to ease your day-to-day concerns. Just integrate your business’s secretarial services with our accounting framework, powered by artificial intelligence (AI), in a seamless manner. We will handle all of the regulatory obligations for your company, so you can focus on its expansion. Besides, it is our responsibility to reduce the possibility of fines and compliance violations on your behalf by ensuring the promptness and precision of all crucial files and reports. With CKP, you will always be in good hands.`,
    ],
  },
  section2: [
    {
      icon: (
        <ElectricBoltOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />
      ),
      title: 'Cutting-edge Applications',
      description:
        'Given that we use artificial intelligence (AI), automation defines our services. This can be advantageous for companies with low budgets, as it can be a cost-effective approach.',
    },
    {
      icon: <BusinessOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />,
      title: 'Trusted Xero Associate',
      description:
        'We have been chosen by Xero Malaysia as the secretary firm in KL with the coveted Partner of the Year distinction in 2022.',
    },
    {
      icon: <PageviewOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />,
      title: 'In-Depth Discourse',
      description:
        'When necessary, we engage with our clients on some of the most important issues before making critical decisions by offering a reliable source for pertinent information.',
    },
    {
      icon: (
        <SupportAgentOutlinedIcon className="!text-16 md:!text-32" style={{ color: '#F5054F' }} />
      ),
      title: 'Customised Assistance',
      description:
        'As the secretarial firm in KL with some of the best-certified secretaries, we believe it is important to offer you specific advice on secretarial concerns',
    },
  ],
  section3: {
    description: [
      'In addition to being a comprehensive <i>secretary firm in KL</i>, CKP has a lot to offer to satisfy your everyday accounting needs. We are renowned for our excellence in payroll services and cloud accounting. Our team is more than capable of helping your business manage the operations of accounts receivable (AR) and accounts payable (AP). Many enterprises still remain loyal to us for our outstanding business advisory service.',
      'If you are searching for other kinds of assistance instead, that should not be a problem at all for us. At CKP, we possess the ideal abilities to function as your virtual Chief Financial Officer (CFO), enabling the simultaneous completion of business incorporation and valuation. Your business unit will be in far superior condition through such initiatives. You may put your trust in our knowledgeable accountants to take care of all your tax and human resource (HR) demands.',
    ],
  },
  meta: {
    title: 'Secretarial Firm In KL | Accounting Firm - Chia, Ka & Partners',
    description:
      'With 20 years of experience as an accounting and secretarial firm in KL, we will handle your secretarial tasks so that your company can run smoothly and sustainably.',
  },
};

function SecretarialFirm() {
  return <ServicesContent props={data} />;
}

export default SecretarialFirm;
