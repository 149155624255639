import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import OurServices from './components/OurServices';
import Reviews from './components/Reviews';
import HowItWorks from './components/HowItWorks';
import Pricing from './components/Pricing';
import GetInTouch from '../../shared-components/GetInTouch';

function Landing() {
  return (
    <Box className="flex flex-col w-full">
      <div className="pt-20 lg:pt-92 relative w-full bg-[#F4064F]">
        <img
          src="assets/images/pages/landing/Home-header.jpg"
          alt="home-header"
          className="w-full h-auto"
        />

        <div className="absolute top-[6rem] sm:top-[10rem] md:top-[13rem] lg:top-[32rem] left-20 md:left-60 transform -translate-y-1/2 text-white p-6">
          <Typography
            variant="h1"
            className="!text-[20px] !font-customBlack sm:!text-[36px] md:!text-[48px] lg:!text-[96px] xl:!text-[10rem] !font-900"
          >
            Welcome to the
          </Typography>
          <Typography
            variant="h1"
            className="!text-[20px] !font-customBlack sm:!text-[36px] md:!text-[48px] lg:!text-[96px] xl:!text-[10rem] !font-900"
          >
            Ai accounting
          </Typography>
          <Typography
            variant="h1"
            className="!text-[20px] !font-customBlack sm:!text-[36px] md:!text-[48px] lg:!text-[96px] xl:!text-[10rem] !font-900"
          >
            revolution
          </Typography>
          <Button
            className="whitespace-nowrap !normal-case !px-5 md:!px-10 !text-[8px] sm:text-[16px] md:!text-[24px] !font-700 !rounded-[8px] !border-1 md:!border-2 !border-white !text-white !ml-2 xl:!ml-10 !mt-10"
            variant="outlined"
            component={Link}
            to="/ai-accounting"
          >
            Click to get started
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-12">
        <div
          className="mt-48 md:mt-96 col-start-2 lg:col-start-3 col-span-10 lg:col-span-8"
          style={{ color: '#1C2687' }}
        >
          <Typography className="!text-[20px] md:!text-[40px] !font-900 text-center !font-customBlack">
            We love numbers.
          </Typography>
          <Typography className="!text-[12px] md:!text-[23px] !font-400 text-center !mt-10 md:!mt-20 !leading-[14px] md:!leading-[28px]">
            As your trusted <b>accounting firm in KL sentral</b>, our revolutionary cloud-based ai
            accounting solutions automate tedious systems and processes that empower entrepreneurs
            to make informed decisions and
          </Typography>
          <Typography
            className="!font-700 !text-[12px] md:!text-[23px] text-center !leading-[14px] md:!leading-[28px] !mt-2 md:!mt-4"
            style={{ color: '#F5054F' }}
          >
            build bold business strategies.
          </Typography>
        </div>
      </div>

      <OurServices />
      <HowItWorks />
      <Reviews />
      <Pricing />
      <GetInTouch />
      <Helmet>
        <title>Accounting Firm In KL Sentral | Xero Partner - Chia, Ka & Partners</title>
        <meta
          name="description"
          content="Chia, Ka & Partners is an established accounting firm KL
Sentral with 20 years of experience. Our goal is to help businesses digitalise
their accounting processes."
        />
      </Helmet>
    </Box>
  );
}

export default Landing;
