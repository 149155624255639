import { Box, Typography } from '@mui/material';
import PricingCard from './PricingCard';
import pricingData from '../dataStore';

function PayrollPackage() {
  return (
    <div className="grid grid-cols-12 py-32 md:py-96 " style={{ color: '#1C2687' }}>
      <div className="col-start-2 md:col-start-3 col-span-10 md:col-span-6 mr-0 md:mr-24">
        <div className="flex flex-col mb-10">
          <Typography className="!font-customBlack !text-[23px] md:!text-[50px] !font-900 !leading-[24px] md:!leading-[48px]">
            Payroll Package
          </Typography>
          <Typography className="!font-customBlack !text-[16px] md:!text-[35px] !font-900 !mt-2 md:!mt-4 !leading-[20px] md:!leading-[40px] text-[#F5054F]">
            When headcount become headache
          </Typography>
          <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            You're an SME with a high staff count and multiple departments or divisions that are
            fast becoming a very complicated organizational structure.
          </Typography>
          <div className="!text-[12px] md:!text-[23px] !font-400 !mt-10 md:!mt-20 !leading-[16px] md:!leading-[32px]">
            Rest easy knowing that the most important part of any business - your team, is getting
            paid accurately and on <h1 className="inline">time</h1>, thanks to the services provided
            by your trusted outsourced accounting company, CKP.
          </div>
        </div>
      </div>
      <Box className="col-start-2 md:col-start-9 col-span-10 md:col-span-2 -mb-20 mx-auto">
        <img
          className="rounded-full w-[12rem] md:w-full"
          src="assets/images/pages/pricing/payroll-package.png"
          alt="payroll-package"
        />
      </Box>

      <div className="col-start-3 sm:col-start-2 xl:col-start-3 col-span-8 sm:col-span-10 xl:col-span-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 md:gap-20 lg:gap-40">
          {pricingData?.payroll_package?.map((item, index) => {
            return <PricingCard key={index} props={item} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default PayrollPackage;
