import { Typography } from '@mui/material';

function TermsAndConditions() {
  return (
    <div
      className="flex flex-col justify-center space-y-32 px-48 md:px-92 py-24 md:py-48"
      style={{ color: '#1C2687' }}
    >
      <Typography
        variant="h1"
        className="pb-24 md:pb-48 !text-[20px] md:!text-[40px] !font-900 text-center !font-customBlack"
      >
        Terms & Conditions
      </Typography>
      Welcome to Chia, Ka & Partners PLT. These terms and conditions outline the rules and
      regulations for using Chia, Ka & Partners PLT's Website. By accessing this website, we assume
      you accept these terms and conditions. Do not use our website if you do not agree to take all
      the terms and conditions stated on this page. The following terminology applies to these Terms
      and Conditions, Privacy Statement and Disclaimer Notice, and all Agreements: “Client,” “You,”
      and “Your” refers to you, the person who logs on to this website and is compliant with the
      Company’s terms and conditions. “The Company,” “Ourselves,” “We,” “Our,” and “Us” refers to
      our Company. “Party,” “Parties,” or “Us” refers to both the Client and ourselves. All terms
      refer to the offer, acceptance, and consideration of payment necessary to undertake the
      process of our assistance to the Client in the most appropriate manner for the express purpose
      of meeting the Client’s needs in respect of the provision of the Company’s stated services, in
      accordance with and subject to, prevailing law of Netherlands. Any use of the above
      terminology or other words in the singular, plural, capitalization, and they or they are taken
      as interchangeable and, therefore, as referring to the same.
      <h2>COOKIES</h2>
      We employ the use of cookies. By accessing our website, you agree to use cookies in agreement
      with The Company's Privacy Policy. Most interactive websites use cookies to let us retrieve
      the user’s details for each visit. Our website uses cookies to enable the functionality of
      certain areas to make it easier for people visiting our website. Some of our
      affiliate/advertising partners may also use cookies.
      <h2>LICENSE</h2>
      Unless otherwise stated, The Company and its licensors own the intellectual property rights
      for all material on The Company. All intellectual property rights are reserved. You may access
      this from The Company for your personal use, subject to restrictions set in these terms and
      conditions. You must not:{' '}
      <ol>
        <li>Republish material from The Company.</li>
        <li>Sell, rent, or sub-license material from The Company.</li>{' '}
        <li>Reproduce, duplicate, or copy material from The Company.</li>{' '}
        <li>Redistribute content from The Company.</li>
      </ol>
      This agreement shall begin on the date hereof. Parts of this website offer an opportunity for
      users to post and exchange opinions and information in certain areas of the website. The
      Company does not filter, edit, publish, or review Comments before their presence on the
      website. Comments do not reflect the views and opinions of The Company, its agents, and
      affiliates. Comments reflect the views and opinions of the person who posts their views and
      opinions. To the extent permitted by applicable laws, The Company shall not be liable for the
      Comments or any liability, damages, or expenses caused and suffered due to any use of and
      posting of and appearance of the Comments on this website. The Company reserves the right to
      monitor all Comments and remove any Comments that can be considered inappropriate, offensive,
      or cause a breach of these Terms and Conditions. You warrant and represent that: You are
      entitled to post the Comments on our website and have all necessary licenses and consents to
      do so;
      <ol>
        <li>
          The Comments do not invade any intellectual property right, including without limitation,
          copyright, patent, or trademark of any third party;
        </li>
        <li>
          The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise
          unlawful material, which is an invasion of privacy.
        </li>
        <li>
          The Comments will not be used to solicit or promote business, custom, or present
          commercial or unlawful activities.
        </li>
      </ol>
      You now grant us a non-exclusive license to use, reproduce, edit and authorize others to use,
      reproduce and edit any of your Comments in any form, format, or media.
      <h2>HYPERLINKING TO OUR CONTENT</h2>
      The following organizations may link to our Website without prior written approval:{' '}
      <ol>
        <li>Government agencies;</li> <li>Search engines;</li>
        <li>News organizations;</li>
        <li>
          Online directory distributors may link to our Website in the same manner as they hyperlink
          to the Websites of other listed businesses; and
        </li>
        <li>
          Systemwide Accredited Businesses except soliciting non-profit organizations, charity
          shopping malls, and charity fundraising groups, which may not hyperlink to our Web site.
          These organizations may link to our home page, to publications, or other Website
          information so long as the link: (a) is not in any way deceptive; (b) does not falsely
          imply sponsorship, endorsement, or approval of the linking party and its products and
          services; and (c) fits within the context of the linking party’s site.
        </li>
      </ol>
      We may consider and approve other link requests from the following types of organizations:
      <ol>
        <li>commonly-known consumer and business information sources;</li>{' '}
        <li>dot.com community sites;</li>
        <li>associations or other groups representing charities;</li>
        <li>online directory distributors;</li>
        <li>internet portals;</li>
        <li>accounting, law, and consulting firms; and</li>{' '}
        <li>educational institutions and trade associations.</li>
      </ol>
      We will approve link requests from these organizations if we decide that: (a) the link would
      not make us look unfavorably to ourselves or our accredited businesses; (b) the organization
      does not have any negative records with us; (c) the benefit to us from the visibility of the
      hyperlink compensates the absence of The Company; and (d) the link is in the context of
      general resource information. These organizations may link to our home page so long as the
      link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or
      approval of the linking party and its products or services; and (c) fits within the context of
      the linking party’s site. If you are one of the organizations listed in paragraph 2 above and
      are interested in linking to our website, you must inform us by sending an email to The
      Company. Please include your name, your organization name, and contact information, as well as
      the URL of your site, a list of any URLs from which you intend to link to our Website, and a
      list of the URLs on our site you would like to link. Wait 2-3 weeks for a response. Approved
      organizations may hyperlink to our Website as follows:{' '}
      <ol>
        <li>By use of our corporate name; or</li>
        <li>By use of the uniform resource locator being linked to; or</li>
        <li>
          Use any other description of our Website being linked to that makes sense within the
          context and format of content on the linking party’s site.
        </li>
      </ol>
      No use of The Company’s logo or other artwork will be allowed for linking absent a trademark
      license agreement.
      <h2>IFRAMES</h2>
      Without prior approval and written permission, you may not create frames around our Web Pages
      that alter our website's visual presentation or appearance.
      <h2>CONTENT LIABILITY</h2>
      We shall not be held responsible for any content on your Website. You agree to protect and
      defend us against all claims that are rising on your Website. No link(s) should appear on any
      Website that may be interpreted as libelous, obscene, or criminal or which infringes,
      otherwise violates, or advocates the infringement or other violation of any third-party
      rights.
      <p>
        <h2>YOUR PRIVACY</h2>
        Please read{' '}
        <a className="font-customBlack" href="/privacy-policy">
          Privacy Policy
        </a>
        .
      </p>
      <h2>RESERVATION OF RIGHTS</h2>
      We reserve the right to request that you remove all links or any particular link to our
      Website. You approve of immediately removing all links to our Website upon request. We also
      reserve the right to amend these terms and conditions and its linking policy at any time. By
      continuously linking to our Website, you agree to be bound to and follow these linking terms
      and conditions.
      <h2>REMOVAL OF LINKS FROM OUR WEBSITE</h2>
      If you find any link on our Website that is offensive for any reason, you are free to contact
      and inform us at any moment. We will consider requests to remove links, but we are not
      obligated to or so or to respond to you directly. We do not ensure that the information on
      this website is correct. We do not warrant its completeness or accuracy, nor do we promise to
      ensure that the website remains available or that the material on the website is kept up to
      date.
      <h2>DISCLAIMER</h2>
      To the maximum extent permitted by applicable law, we exclude all representations, warranties,
      and conditions relating to our website and the use of this website. Nothing in this disclaimer
      will:{' '}
      <ol>
        <li>limit or exclude our or your liability for death or personal injury;</li>
        <li>
          limit or exclude our or your liability for fraud or fraudulent misrepresentation;
        </li>{' '}
        <li>
          limit any of our or your liabilities in any way that is not permitted under applicable
          law; or
        </li>
        <li>
          exclude any of our or your liabilities that may not be excluded under applicable law.
        </li>
      </ol>
      The limitations and prohibitions of liability set in this Section and elsewhere in this
      disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising
      under the disclaimer, including liabilities arising in contract, in tort, and for breach of
      statutory duty. As long as the website and the information and services on the website are
      provided free of charge, we will not be liable for any loss or damage of any nature.
    </div>
  );
}

export default TermsAndConditions;
