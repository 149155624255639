import { Typography } from '@mui/material';

function PayrollManagement() {
  return (
    <div className="grid grid-cols-12 py-16 md:py-32" style={{ color: '#1C2687' }}>
      <div className="col-start-2 md:col-start-2 col-span-12 md:col-span-8 mb-24 md:mb-48">
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !leading-[14px] md:!leading-[28px]">
          What we do. Our outsource payroll services Malaysia include, but are not limited to:
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !font-400 !mt-14 md:!mt-28 !leading-[14px] md:!leading-[28px]">
          Payroll, promptly and professionally managed. Developed just for your convenience and
          accessibility, our outsource payroll services Malaysia helps you be consistent and
          accurate in managing the process. From reviewing e-leaves to generating payroll summaries,
          a digitalised payroll system makes things simpler than ever.
        </Typography>
      </div>
      <div className="col-start-2 md:col-start-2 col-span-12 md:col-span-4 mb-24 md:mb-0">
        <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !leading-[20px] md:!leading-[40px] text-white">
          Secured Payroll System
        </Typography>
        <Typography className="!text-[12px] md:!text-[23px] !mt-7 md:!mt-14 !font-500 !leading-[14px] md:!leading-[28px]">
          Our payroll outsourcing Malaysia professionals serve as an external point of contact for
          your payroll process, facilitating the segregation of duties and thus strengthening
          internal control. Capable of complying with the most recent advancements in payroll IT
          solutions without direct investment in new or upgraded hardware and software. Outsourcing
          allows you to reallocate your financial resources to other areas while remaining relevant
          and competitive as payroll technology breakthroughs. You no longer need to stress about
          monitoring the legislation changes and making necessary process changes. Our team of
          professional in-charge of payroll services in Kuala Lumpur will keep you up to date on the
          most recent legislative changes and ensure full compliance, removing the risks and
          penalties associated with noncompliance.
        </Typography>
      </div>
      <div className="col-start-3 md:col-start-7 col-span-12 md:col-span-6 bg-white !-mx-28 md:!-mx-40 md:!-mt-16 !mb-10 md:!mb-20 rounded-[20px]">
        <div className="flex flex-col pl-20 md:pl-40 pt-10 md:pt-20 pr-36 md:pr-72 py-10 md:py-0 ">
          <Typography className="!font-customBlack !text-[20px] md:!text-[40px] !font-700 !leading-[20px] md:!leading-[40px]">
            Finest Outsourced Payroll Professionals
          </Typography>
          <Typography className="!text-[12px] md:!text-[23px] !font-500 !mt-7 md:!mt-14 !leading-[14px] md:!leading-[28px]">
            The payroll team at CKP is fully equipped with the knowledge and experience to manage
            your payroll function. We have the people, guidelines, technology, experience, and
            knowledge to provide our clients with comprehensive, accurate, and timely payroll
            services. We have expanded our outsourced services to include payroll services in KL
            Sentral, Kuala Lumpur. Our payroll services Kuala Lumpur delivery model is customised to
            your organisation's structure and needs. For us, no payroll is too large or too small.
            We are always prepared and confident in our ability to step up and deliver quality
            payroll outsourcing Malaysia. We place strong importance on privacy and confidentiality.
            To protect your information at all times, we establish appropriate security measures.
            This critical process should be outsourced because the resources required for a complete
            payroll function may not justify the benefits.
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default PayrollManagement;
